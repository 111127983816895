<template>
  <aside class="input-calendar">
    <div class="input-calendar__container prevent">
      <nav class="calendar__nav">
        <button type="button" class="prev-month" @click="subtractMonth">
          <Sprite :key="'prev'" type="chevron" :width="10" :height="16" />
        </button>
        <h2 class="current-month">{{ month +' '+ year }}</h2>
        <button type="button" class="next-month" @click="addMonth">
          <Sprite :key="'next'" type="chevron" :width="10" :height="16"/>
        </button>
      </nav>
      <section class="input-calendar__body">
        <ol class="weekdays">
          <li
            v-for="(day, index) in days"
            :key="day+index"
            class="weekday"
          >
            {{ day }}
          </li>
        </ol>
        <ol class="dates">
          <li
            v-for="(blank, i) in firstDayOfMonth" 
            class="date date--before"
            :key="blank+'date'+i"
          >
            {{ blank }}
          </li>
          <li 
            @click="handleDate"
            class="date"
            v-for="(date, i) in daysInMonth"  
            :key="date+i"
            :data-time="year + dateContext.format('MM') + digitFormat(date)" 
            :class="setClass(year + dateContext.format('MM') + digitFormat(date))"
          >
            {{ date }}
          </li>
        </ol>
      </section>
    </div>
  </aside>
</template>

<script>
import Sprite from '@/components/elements/Sprite.vue';
import moment from 'moment';

export default {
  components: {
    Sprite
  },
  props: {
    value: {
      type: String,
      default: null
    },
    allowedDates: {
      type: Array,
      default: null
    }
  },
  computed: {
    year() {
      return this.dateContext.format('Y');
    },
    month() {
      return this.$t(`components.calendar.months.${this.dateContext.format('M')}`);
    },
    daysInMonth() {
      return this.dateContext.daysInMonth();
    },
    currentDate() {
      return this.dateContext.get('date');
    },
    firstDayOfMonth() {
      const firstDay = moment(this.dateContext).subtract((this.currentDate), 'days');
      return firstDay.weekday();
    },
    days() {
      return [
        this.$t('components.calendar.days_abbr.1'),
        this.$t('components.calendar.days_abbr.2'),
        this.$t('components.calendar.days_abbr.3'),
        this.$t('components.calendar.days_abbr.4'),
        this.$t('components.calendar.days_abbr.5'),
        this.$t('components.calendar.days_abbr.6'),
        this.$t('components.calendar.days_abbr.7')
      ];
    }
  },
  methods: {
    digitFormat(date) {
      return (`0${date}`).slice(-2);
    },
    dateFormat(date) {
      return moment(date).format("DD[.]MM[.]YYYY");
    },
    addMonth() {
      this.dateContext = moment(this.dateContext).add(1, 'month');
    },
    subtractMonth() {
      this.dateContext = moment(this.dateContext).subtract(1, 'month');
    },
    handleDate(evt) {
      const { target } = evt;
      const date = target.getAttribute('data-time');
      this.$emit('change', date);
    },
    setClass(date) {
      const className = [];
      if (this.value === date) className.push('selected-date');
      if (this.allowedDates && this.allowedDates.indexOf(date) < 0) className.push('disabled');
      return className.join(' ');
    }
  },
  data() {
    return {
      calendar: [],
      today: moment(),
      dateContext: moment(),
    };
  }
};
</script>

<style lang="scss">
  button.read-only,
  button.read-only * {
    cursor: default;
  }
</style>

<style lang="scss" scoped>
.input-calendar {
  display: flex;
  position: relative;
  z-index: 100;
  justify-content: flex-start;
  align-items: center;
  transition: opacity .3s $ease-out-quart;

  .h4{
    margin-bottom: 0;
  }

  &--visible {
    opacity: 1;
    pointer-events: auto;
  }

  &__container {
    background: $white;
    width: 400px;
    min-width: 330px;
    border-radius: 8px;
    border: 1px solid rgba($grey, .5);
    padding: 20px 30px;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;


    padding: 0 12px;
    margin-bottom: 16px;
  }
}

.current-month {
  font-family: $primary;
  font-style: italic;
  font-weight: $bold;
  font-size: rem(26px);
  line-height: 10%;
  text-transform: none;  
}

.prev-month {
  transform: rotate(90deg) translateX(3px);
  cursor: pointer;
  .svg {
    fill: $mediumgreen
  }
}
.next-month {
  transform: rotate(-90deg) translateX(-3px);
  cursor: pointer;
  .svg {
    fill: $mediumgreen
  }
}
.weekday {
  font-family: $secondary;
  font-weight: $bold;
  font-size: rem(12px);
  letter-spacing: 2px;
  text-transform: uppercase;

  color: $mediumblue;
}
.weekdays, .dates {
  display: flex;
  flex-wrap: wrap;
}
.weekday, .date {
  margin-bottom: 2px;
  padding: 6px;
  width: calc(100% / 7);
  position: relative;
  font-size: rem(12);

  display: flex;
  justify-content: center;
  align-items: center;

  &--before {
    opacity: 0;
    pointer-events: none;
  }
}

.date {
  position: relative;
  z-index: 0; 

  font-family: $secondary;
  font-weight: $bold;
  font-size: rem(16px);

  letter-spacing: 2px;
  text-transform: uppercase;
  transition: color .2s $ease-out-quad;
  cursor: pointer;

  user-select: none;

  &:hover {
    color: $mediumgreen;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  &.disabled {
    opacity: .3;
  }
}

.selected-date {
  color: $white;

  &::before  {
    width: 100%;
    background: $darkblue;
    border-radius: 20px;
    background: $mediumblue;
  }

  &:hover {
    color: $white;

    &::before  {
      background: $mediumgreen;
    }
  }
}

.year {
  display: block;
  font-size: rem(16px);
  font-weight: 500;
  line-height: 1.31;
  opacity: .7;
}

.today {
  font-size: rem(34px);
  font-weight: 500;
  line-height: 1.32;
}

.spacing {
  margin: 0 5.6px;
}
</style>