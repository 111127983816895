// import { useI18n } from 'vue-i18n';
import i18n from '../i18n';
import axios from 'axios';
import {
  getCompanies
} from '@/calls/companies';

const companies = {
  namespaced: true,
  state: {
    items: [],
    current: null,
    selected: null,
    requests: 0,
    visible: false
  },
  mutations: {
    updateProperty(state, { property, value }) {
      state[property] = value;
    }
  },
  actions: {
    async getCompanies({ commit, state, dispatch }) {
      commit('updateProperty', {
        property: 'requests', value: state.requests + 1
      });

      const request = await getCompanies();

      if (!request.hasError) {
        commit('updateProperty', { property: 'items', value: request });
      } else {
        const { t } = i18n.global;
        commit('updateProperty', { property: 'visible', value: false });
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.fleet.companies', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updateProperty', { property: 'requests', value: state.requests - 1 });
    },
    applyCompany({ state, commit }) {
      const { selected } = state;
      axios.defaults.headers['x-admin-company'] = selected.id;

      commit('updateProperty', { property: 'current', value: selected });
      commit('parkings/updateForm', { property: 'place', value: null }, { root: true });
      commit('fleet/updateFleet', selected, { root: true });
    }
  }
};

export default companies;
