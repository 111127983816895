import axios from 'axios';

const getBalanceReport = async () => {
  return await axios.get('/fleet/report/download-balance', {responseType: "blob"})
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

export {
  getBalanceReport
}