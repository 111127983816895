import { AWSKinesisProvider } from 'aws-amplify';

import { apiSnapIntegration } from './apiSnapIntegration';

// const getBooking = (id) => {
//   return apiSnapIntegration.get(`/fleet/bookings/${id}`)
//   .then((promise) => {
//     if(promise.status === 200) return promise.data
//   }).catch(error => {
//     console.log(error);
//   });
// };
const getBookings = (params) => {
  return apiSnapIntegration.get('/legacy/fleet/bookings', { params })
    .then((promise) => {
      if (promise.status === 200) return promise.data
    }).catch(error => {
      console.log(error);
    });
};

const newBooking = (item) => {
  return apiSnapIntegration.post(`/legacy/fleet/booking`, item)
    .then((promise) => {
      if (promise.status === 200) return promise.data
    }).catch(error => {
      return {
        hasError: true,
        error: error.response.data.detail
      }
    });
};

// const confirmBooking = ({ id, fleetId }) => {
//   return apiSnapIntegration.patch(`/fleet/bookings/${id}/confirm`, {
//     payAtParking: false
//   })
//   .then((promise) => {
//     if(promise.status === 200) return promise.data
//   }).catch(error => {
//     console.log(error);
//   });
// };

const cancelBooking = (id, fleetId) => {
  return apiSnapIntegration.patch(`/legacy/fleet/${id}/cancel`, null, {
    params: { FleetId: fleetId }
  })
    .then((promise) => {
      if (promise.status === 200) return promise.data
    }).catch(error => {
      return {
        hasError: true,
        error
      }
    });
};

// const acceptBooking = (id) => {
//   return apiSnapIntegration.patch(`/fleet/bookings/${id}/accept`)
//   .then((promise) => {
//     if(promise.status === 200) return promise.data
//   }).catch(error => {
//     return {
//       hasError: true,
//       error
//     }
//   });
// };

// const rejectBooking = (id) => {
//   return apiSnapIntegration.patch(`/fleet/bookings/${id}/reject`)
//   .then((promise) => {
//     if(promise.status === 200) return promise.data
//   }).catch(error => {
//     return {
//       hasError: true,
//       error
//     }
//   });
// };

const getQR = (id) => {
  return apiSnapIntegration.get(`/legacy/fleet/${id}/qrcode`)
    // return axios.patch(`/fleet/bookings/{id}/qrcode`)
    .then((promise) => {
      if (promise.status === 200) return promise.data
    }).catch(error => {
      return {
        hasError: true,
        error
      }
    });
}

// const send = async (id, params) => {
//   try {
//     const request = await axios.post(`/fleet/bookings/${id}/send`, { ...params });
//     return request;
//   } catch (error) {
//     return {
//       hasError: true,
//       error
//     }
//   }
// };

const getParkingInstruction = (parkingId) => {
  return apiSnapIntegration.get(`/legacy/fleet/${parkingId}/instruction`)
    .then((promise) => {
      if (promise.status === 200) return promise.data
    }).catch(error => {
      return {
        hasError: true,
        error
      }
    });
}

export {
  // getBooking,
  getBookings,
  newBooking,
  // confirmBooking,
  cancelBooking,
  // acceptBooking,
  // rejectBooking,
  getQR,
  // send,
  getParkingInstruction
}
