import moment from 'moment-timezone';

const FORMATS = {
  date: 'DD[.]MM[.]YYYY',
  hours: 'HH[:]mm a',
  hoursDigits: 'HH[:]mm',
  hoursAmPm: 'a',
};

const formatDate = function(date, tz = 'Europe/Brussels', dateFormat = 'DD[.]MM[.]YYYY', hourFormat = 'HH[:]mm a') {
  // Get date without offset
  const match = date.match(/(\+|\-)[0-9]{2}:[0-9]{2}$/g);
  const dateString = match.length > 0 ? date.replace(match[0], '') : date;
  const d = moment.tz(dateString, tz);

  return {
    date: d.format(dateFormat),
    hours: d.format(hourFormat),
    hoursDigits: d.format(FORMATS.hoursDigits),
    hoursAmPm: d.format(FORMATS.hoursAmPm),
  };
};

const urlEncode = function(params, prefix) {
  var items = [];

  for(var field in params) {
    var key  = prefix ? prefix + "[" + field + "]" : field;
    var type = typeof params[field];

    if (params[field] !== null) {
      switch(type) {
        case "object":
          if(params[field].constructor == Array) {
            params[field].each(function(val) {
              items.push(key + "[]=" + val);
            }, this);
          } else {
            items = items.concat(urlEncode(params[field], key));
          }
          break;
        case "function":
          break;
        default:
          items.push(key + "=" + params[field]);
          // items.push(key + "=" + escape(params[field]));
          break;
      }
    }
  }

  return items.join("&");
};

const measureDistance = (point1, point2) => {
  const R = 6371; // kmetres
  const φ1 = point1.lat * Math.PI/180; // φ, λ in radians
  const φ2 = point2.lat * Math.PI/180;
  const Δφ = (point2.lat-point1.lat) * Math.PI/180;
  const Δλ = (point2.lng-point1.lng) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c; // in metres
};

export {
  formatDate,
  urlEncode,
  measureDistance
};