import i18n from '../i18n';
import Form from '@/helpers/form';
import {
  getPlanner,
  getPlanners,
  createPlanner,
  updatePlanner,
  suspendPlanner,
  unsuspendPlanner,
  revokePlanner
} from '@/calls/planners';

import Constants from '../helpers/constants'

const planners = {
  namespaced: true,
  state: {
    itemId: null,
    item: {
      firstname: '',
      lastname: '',
      email: '',
      locale: ''
    },
    items: [],
    requests: 0,
    popups: {
      new: {
        visible: false,
        requests: 0,
        success: false,
        errors: {
          lastname: '',
          firstname: '',
          email: '',
          locale: ''
        }
      },
      edit: {
        visible: false,
        requests: 0,
        success: false,
        item: null,
        errors: {
          lastname: '',
          firstname: '',
          email: ''
        }
      },
      suspend: {
        visible: false,
        requests: 0,
        success: false,
      },
      unsuspend: {
        visible: false,
        requests: 0,
        success: false,
      },
      revoke: {
        visible: false,
        requests: 0,
        success: false,
      }
    }
  },
  mutations: {
    updateItemProperty(state, { property, value }) {
      state.item[property] = value;
    },
    updateItemId(state, id) {
      state.itemId = id;
    },
    updateItems(state, items) {
      state.items = items;
    },
    updatePopupProperty(state, { popup, property, value }) {
      state.popups[popup][property] = value;
    },
    updatePopupError(state, { popup, field, value }) {
      state.popups[popup].errors[field] = value;
    },
    resetItem(state) {
      state.item = {
        lastname: '',
        firstname: '',
        email: ''
      }
    },
    resetErrors(state, popup) {
      state.popups[popup].errors.firstname = null;
      state.popups[popup].errors.lastname = null;
      state.popups[popup].errors.email = null;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    async getPlanners({ commit }) {
      commit('increaseRequests');
      const request = await getPlanners();

      if (!request.hasError) {
        const planners = request.filter(p => !p.isOwner);
        commit('updateItems', planners);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.planners.list', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async getPlanner({ state, commit, dispatch }, popup) {
      if (popup) commit('updatePopupProperty', { popup, property: 'requests', value: state.popups[popup].requests + 1 });

      const request = await getPlanner(state.itemId);

      if (!request.hasError) {
        const { user } = request;
        commit('updateItemProperty', { property: 'firstname', value: user.firstname });
        commit('updateItemProperty', { property: 'lastname', value: user.lastname });
        commit('updateItemProperty', { property: 'email', value: user.email });
        if (popup === 'edit') commit('updatePopupProperty', { popup: 'edit', property: 'item', value: user });
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.planners.getting', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      if (popup) commit('updatePopupProperty', { popup, property: 'requests', value: state.popups[popup].requests - 1 });
    },
    async addPlanner({ state, commit, dispatch }) {
      commit('resetErrors', 'new');
      const { t } = i18n.global;

      // Validation
      const errors = Form.validation(state.item, ['firstname', 'lastname', 'email']);

      if (errors.length) {
        errors.map(e => {
          commit('updatePopupError', { popup: 'new', field: e.property, value: t(`notifications.errors.${e.type}`) });
        });
        return;
      };

      //  Check if already in use
      const { items } = state;
      const emails = items.map(item => item.user.email);

      if (emails.indexOf(state.item.email.trim()) >= 0) {
        dispatch('notifications/addNotifs', [{
          text: 'The user already exists',
          type: 'error'
        }], { root: true });

        return;
      }

      // Request
      commit('updatePopupProperty', { popup: 'new', property: 'requests', value: state.popups.new.requests + 1 });

      const data = {...state.item};
      data.locale = data.locale && data.locale.length ? data.locale : 'en';

      data.createdFromApp = Constants.SOURCE_APP; 
      
      const request = await createPlanner(data);

      if (!request.hasError) {
        commit('updatePopupProperty', { popup: 'new', property: 'success', value: true });
        dispatch('getPlanners');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.planners.creation', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopupProperty', { popup: 'new', property: 'requests',  value: state.popups.new.requests - 1 });
    },
    // Update
    async updatePlanner({ state, commit, dispatch }) {
      commit('resetErrors', 'edit');
      const { t } = i18n.global;
      
      // Validation
      const errors = Form.validation(state.item, ['firstname', 'lastname', 'email']);
      if (errors.length) {
        errors.map(e => {
          commit('updatePopupError', { popup: 'edit', field: e.property, value: t(`notifications.errors.${e.type}`) });
        });
        return;
      };

      // Request
      commit('updatePopupProperty', { popup: 'edit', property: 'requests', value: state.popups.edit.requests + 1 });

      const request = await updatePlanner(state.itemId, state.item);

      if (!request.hasError) {
        commit('updatePopupProperty', { popup: 'edit', property: 'success', value: true });
        dispatch('getPlanners');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.planners.update', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopupProperty', { popup: 'edit', property: 'requests', value: state.popups.edit.requests - 1 });
    },
    // Suspend
    async suspendPlanner({ state, commit, dispatch }) {
      commit('updatePopupProperty', { popup: 'suspend', property: 'requests', value: state.popups.suspend.requests + 1 });

      const { t } = i18n.global;
      const request = await suspendPlanner(state.itemId);

      if (!request.hasError) {
        commit('updatePopupProperty', { popup: 'suspend', property: 'success', value: true });
        dispatch('getPlanners');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.planners.suspend', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopupProperty', { popup: 'suspend', property: 'requests', value: state.popups.suspend.requests - 1 });
    },
    // Unsuspend
    async unsuspendPlanner({ state, commit, dispatch }) {
      commit('updatePopupProperty', { popup: 'unsuspend', property: 'requests', value: state.popups.unsuspend.requests + 1 });

      const { t } = i18n.global;
      const request = await unsuspendPlanner(state.itemId);

      if (!request.hasError) {
        commit('updatePopupProperty', { popup: 'unsuspend', property: 'success', value: true });
        dispatch('getPlanners');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.planners.unsuspend', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopupProperty', { popup: 'unsuspend', property: 'requests', value: state.popups.unsuspend.requests - 1 });
    },
    // Revoke
    async revokePlanner({ state, commit, dispatch }) {
      commit('updatePopupProperty', { popup: 'revoke', property: 'requests', value: state.popups.revoke.requests + 1 });

      const { t } = i18n.global;
      const request = await revokePlanner(state.itemId);

      if (!request.hasError) {
        commit('updatePopupProperty', { popup: 'revoke', property: 'success', value: true });
        dispatch('getPlanners');
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.planners.revoke', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopupProperty', { popup: 'revoke', property: 'requests', value: state.popups.revoke.requests - 1 });
    }
  }
};


export default planners;
