import { getTranslations } from '@/calls/settings';
import i18n from '../i18n';

const translations = {
  namespaced: true,
  state: {
    locale: null,
    keys: {}
  },
  mutations: {
    setKeys(state, translations) {
      state.keys = translations;
    },
    setLocale(state, locale) {
      state.locale = locale;
    }
  },
  actions: {
    async getTranslations({ state, commit }) {
      const { locale } = i18n.global;
      if (locale === state.locale) return;
      commit('setLocale', locale);
      const translations = await getTranslations(i18n.global.locale);
      commit('setKeys', translations);
    }
  }
};

export default translations;
