import axios from 'axios';

const getDrivers = async (search) => {
  return await axios.get('/fleet/drivers/list', {
    params: {
      search
    }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

const removeDriver = async (driver) => {
  return await axios.delete(`/fleet/drivers/cancel-invitation/${driver.id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const revokeDriver = async (driver) => {
  return await axios.delete(`/fleet/drivers/revoke/${driver.id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const updatePendingDriver = async (driver) => {
  return await axios.patch(`/fleet/drivers/update-pending/${driver.id}`, {
    firstname: driver.firstName,
    lastname: driver.lastName
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const updateActiveDriver = async (driverId, truckId) => {
  return await axios.patch(`/fleet/drivers/update-active/${driverId}`, { truckId })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const getTrucks = async() => {
  return await axios.get(`/fleet/trucks/select`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

// Driver creation
const addDriver = async (params) => {
  return await axios.post('/fleet/drivers/add', params)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error: error.response && error.response.data ? error.response.data : error
    }
  });
};

const createDriverNoAccount = async (params) => {
  return await axios.post('/fleet/drivers/add-noaccount', params)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error: error.response && error.response.data ? error.response.data : error
    }
  });
};

const checkDriverAccount = async (params) => {
  return await axios.get('/fleet/drivers/exists', { params })
  .then((promise) => {
    return promise;
  }).catch(error => {
    return {
      hasError: true,
      error: error.response && error.response.data ? error.response.data : error
    }
  });
};

const linkDriverAccount = async ({ driverId, updateFleet }) => {
  return await axios.post(`/fleet/drivers/${driverId}/link/${updateFleet}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error: error.response && error.response.data ? error.response.data : error
    }
  });
};


// const unlinkDriverAccount = async ({ truckId, driverId }) => {
//   return await axios.post(`/fleet/trucks/${truckId}/unlink`, driverId)
//   .then((promise) => {
//     if(promise.status === 200) return promise.data
//   }).catch(error => {
//     console.log('error :', error);
//     return {
//       hasError: true,
//       error: error.response && error.response.data ? error.response.data : error
//     }
//   });
// };

// const createDriverAccount = async ({ fleetId, truckId, phone, fullname }) => {
//   return await axios.post('/fleet/drivers/create', {
//     fleetId, truckId, phone, fullname
//   })
//   .then((promise) => {
//     if(promise.status === 200) return promise.data
//   }).catch(error => {
//     console.log('error :', error);
//     return {
//       hasError: true,
//       error: error.response && error.response.data ? error.response.data : error
//     }
//   });
// };



export {
  getDrivers,
  addDriver,
  removeDriver,
  getTrucks,
  revokeDriver,
  updateActiveDriver,
  updatePendingDriver,
  checkDriverAccount,
  linkDriverAccount,
  createDriverNoAccount
  // unlinkDriverAccount,
  // createDriverAccount,
};
