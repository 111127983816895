<template>
  <!-- Add new popup -->
  <Popup
    v-if="popup.visible"
    :classes="'popup--big popup-booked'"
  >
    <template v-slot:content>
      <h2 class="popup-title">{{ $t('bookings.popups.booked.title') }}</h2>
      <p
        v-if="popup.warning"
        class="popup-main-text warning"
        v-html="$t('bookings.popups.booked.warning')"
      />
      <p
        v-else
        class="popup-main-text"
        v-html="$t('bookings.popups.booked.text')"
      />
      <h3 class="popup-subtitle">{{ $t('bookings.popups.booked.subtitle') }}</h3>

      <form
        class="popup-form"
        novalidate
        @submit.prevent="onSubmit('phone')"
      >
        <Inputs
          :type="'text'"
          :value="form.phone"
          :error="errors.phone"
          :label="$t('bookings.popups.booked.inputs.phone.label')"
          :placeholder="$t('bookings.popups.booked.inputs.phone.placeholder')"
          @onInput="onInput($event, 'phone')"
        />
        <button
          type="submit"
          class="button"
          :disabled="!form.phone || !form.phone.length"
        >
          {{ $t('globals.send') }}
        </button>
      </form>

      <form
        class="popup-form"
        novalidate
        @submit.prevent="onSubmit('email')"
      >
        <Inputs
          :type="'email'"
          :value="form.email"
          :error="errors.email"
          :label="$t('bookings.popups.booked.inputs.email.label')"
          :placeholder="$t('bookings.popups.booked.inputs.email.placeholder')"
          @input="onInput($event, 'email')"
        />
        <button
          type="submit"
          class="button"
          :disabled="!form.email || !form.email.length"
        >
          {{ $t('globals.send') }}
        </button>
      </form>

      <!-- Rules -->
      <div
        v-if="popup.rules"
        class="popup-form"
      >
        <Inputs
          :value="''"
          :label="$t('bookings.popups.booked.inputs.rules.label')"
          :placeholder="$t('bookings.popups.booked.inputs.rules.placeholder')"
          :disabled="true"
        />
        <button
          type="submit"
          class="button"
          @click="copyRules"
        >
          {{ $t('globals.clipboard') }}
        </button>
      </div>

      <div
        v-if="popup.qr"
        class="popup-block"
      >
        <span class="label small">{{ $t('bookings.popups.booked.inputs.qr.label') }}</span>
        <a
          :href="popup.qr.href"
          :download="popup.qr.name"
          class="button"
        >
          <Sprite
            :type="'pass'"
            :width="24"
            :height="24"
          />
          {{ $t('bookings.popups.booked.inputs.qr.button') }}
        </a>
      </div>
      <div
        v-else-if="popup.instructions"
        class="popup-form"
      >
        <Inputs
          :value="popup.instructions"
          :label="$t('bookings.popups.booked.inputs.instructions.label')"
          :placeholder="$t('bookings.popups.booked.inputs.instructions.placeholder')"
          :disabled="true"
        />
        <button
          type="submit"
          class="button"
          @click="copyInstructions"
        >
          {{ $t('globals.clipboard') }}
        </button>
      </div>

      <div class="popup-actions">
        <button
          type="button"
          class="button button-border button-big"
          @click.prevent="close"
        >
          {{ $t('globals.close') }}
        </button>
      </div>

      <div
        v-show="loading"
        class="popup-loader"
      >
        <Loader />
      </div>
    </template>
  </Popup>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import Popup from '@/components/partials/Popup';

export default {
  components: {
    Loader,
    Sprite,
    Inputs,
    Popup,
  },
  computed: {
    popup() {
      return this.$store.state.popups.booked;
    },
    form() {
      return this.$store.state.popups.booked.form;
    },
    errors() {
      return this.$store.state.popups.booked.errors;
    },
    loading() {
      return this.$store.state.popups.booked.requests !== 0;
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/toggle', 'booked');
      this.$store.commit('popups/resetPopupErrors', 'booked');
      this.$store.commit('popups/updatePopupForm', { popup: 'booked', field: 'phone', value: '' });
      this.$store.commit('popups/updatePopupForm', { popup: 'booked', field: 'email', value: '' });
      this.$store.commit('popups/updatePopup', { popup: 'booked', property: 'instructions', value: null });
      this.$store.commit('popups/updatePopup', { popup: 'booked', property: 'warning', value: false });
      this.$store.commit('popups/updatePopup', { popup: 'booked', property: 'id', value: null });
      this.$store.commit('popups/updatePopup', { popup: 'booked', property: 'qr', value: null });
      this.$store.commit('parkings/resetForm');

      this.$router.push({ name: 'Booking' });
    },
    onInput($event, field) {
      this.$store.commit('popups/resetPopupErrors', 'booked');
      if (!$event) return;
      const { value } = $event.target;
      this.$store.commit('popups/updatePopupForm', { popup: 'booked', field, value });
    },
    onSubmit(type) {
      if (!type) return;
      const valid = this.validate(type);
      if (!valid) return;
      const TYPE = type.charAt(0).toUpperCase() + type.slice(1);
      this.$store.dispatch(`popups/sendBy${TYPE}`);
    },
    validate(type) {
      this.$store.commit('popups/resetPopupErrors', 'booked');
      const value = this.$store.state.popups.booked.form[type];

      if (!value || !value.length) {
        this.$store.commit('popups/updatePopupError', { popup: 'booked', field: type, value: this.$t('notifications.errors.required') });
        return false;
      }

      if (type === 'email') {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valid = regex.test(value);

        if (!valid) {
          this.$store.commit('popups/updatePopupError', { popup: 'booked', field: type, value: this.$t('notifications.errors.required') });
          return false;
        }
      }

      return true;
    },
    copyInstructions() {
      const textArea = document.createElement('textarea');
      textArea.value = this.popup.instructions;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      textArea.style.opacity = '0';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.$store.dispatch('notifications/addNotifs', [{
        text: this.$t('notifications.success.booking.instructions'),
        type: 'success'
      }]);
    },
    copyRules() {
      const textArea = document.createElement('textarea');
      textArea.value = this.popup.rules;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      textArea.style.opacity = '0';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.$store.dispatch('notifications/addNotifs', [{
        text: this.$t('notifications.success.booking.rules'),
        type: 'success'
      }]);
    }
  }
}
</script>

<style lang="scss">
.popup.popup-booked {
  .popup-main-text {
    margin-top: 8px;
    font-size: rem(18px);

    &.warning {
      padding: 10px 38px;
      border-radius: 8px;
      background-color: rgba($warning, .2);
      color: $darkblue;
    }
  }

  .popup-subtitle {
    margin-top: 30px;
    font-family: $secondary;
    font-weight: 600;
    font-style: normal;
    font-size: rem(20px);
    text-align: left;
  }

  .popup-form {
    display: flex;
    align-items: flex-end;
    padding: 20px;
    margin-top: 12px;
    border-radius: 8px;
    background-color: rgba($darkblue, .05);

    .input {
      flex-grow: 1;

      .label {
        color: $darkblue;
        font-size: rem(16px);
      }

      input {
        background-color: $white;
        border-right: 0;
        border-radius: 8px 0 0 8px;
      }

      &.error {
        + .button {
          margin-bottom: 21px;
          padding-bottom: 16px;
        }
      }
    }

    .button {
      border-radius: 0 8px 8px 0;
    }
  }

  .popup-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin-top: 12px;
    border-radius: 8px;
    background-color: rgba($darkblue, .05);

    .label {
      width: 100%;
      color: $darkblue;
      font-size: rem(16px);
    }

    .button {
      margin-top: 10px;
      padding-left: 40px;
      padding-right: 40px;

      .svg {
        fill: $white;
        margin-top: -3px;
        margin-right: 5px;
      }
    }
  }

  .button {
    padding-top: 17px;
    padding-bottom: 15px;
    min-width: 110px;
  }

  .popup-actions {
    margin-top: 30px;

    .button {
      width: 100%;
    }
  }
}
</style>