<template>
  <form
    class="planner"
    @submit.prevent="onSubmit"
    novalidate
  >
    <div class="planner-content">
      <!-- Waypoints -->
      <div
        :class="['planner-block', { 'is-dragging': currentDrag }]"
      >
        <div
          v-for="(waypoint, index) in waypoints"
          :key="`waypoint-${index}`"
          class="planner-waypoint"
          @drag="onDrag($event, waypoint.id)"
          @dragend="onDragEnd($event, waypoint.id)"
          @dragenter="onDragEnter($event, waypoint.id)"
          @dragStart="onDragStart($event, waypoint.id)"
          @dragleave="onDragReset"
          @dragover="onDragResetFalse"
          draggable="true"
        >
          <span class="planner-waypoint-icon"></span>

          <!-- Input -->
          <input
            :value="waypoint.label"
            :type="'text'"
            :placeholder="index === 0 ? $t('globals.from') : $t('globals.to')"
            :name="`waypoint-${randomName()}`"
            :id="`waypoint-${index}`"
            :disabled="waypoint.data && waypoint.data.item"
            class="planner-waypoint-input"
            @input="onInput($event, index)"
          />
            <!-- :disabled="waypoint.data && waypoint.data.parking" -->
          <!-- Book button -->
            <!-- v-if="waypoint.data && waypoint.data.parking" -->
          <button
            v-if="waypoint.data && waypoint.data.item"
            class="planner-waypoint-book"
            type="button"
            @click="onBook(waypoint.data.item)"
          >
            {{ $t('globals.book') }}
          </button>

          <!-- Close button -->
          <button
            type="button"
            class="planner-waypoint-close"
            @click="onRemoveWaypoint(index)"
          >
            <Sprite :type="'waypoint-close'"/>
          </button>

          <ul
            v-if="results && results.length && index === current.index"
            class="planner-waypoint-results"
          >
            <li 
              v-for="(result, index) in results"
              :key="index"
              class="planner-waypoint-result" 
              @click="onResultClick($event, result)" 
            >
              {{ result.place_name }}
            </li>
          </ul>
        </div>

        <button
          class="planner-add-waypoint"
          type="button"
          @click="onAddWaypoint"
        >
          <Sprite :type="'waypoint-add'"/>
          {{ $t('routePlanner.waypoints.add') }}
        </button>

        <div class="planner-radios">
          <Radio
            :label="$t('routePlanner.waypoints.fast')"
            :name="'routingMode'"
            :id="'routingMode-fast'"
            :checked="params.routingMode === 'fast'"
            @onChange="onRadio('fast', 'routingMode')"
          />

          <Radio
            :label="$t('routePlanner.waypoints.short')"
            :name="'routingMode'"
            :id="'routingMode-short'"
            :checked="params.routingMode === 'short'"
            @onChange="onRadio('short', 'routingMode')"
          />
        </div>
      </div>
      <!-- End Waypoints -->

      <template v-if="options.full">
        <!-- Route options -->
        <div class="planner-block">
          <div :class="[
            'planner-block-header',
            { 'is-open': options.route }
          ]">
            <h3 class="planner-block-title">{{ $t('routePlanner.titles.route') }}</h3>

            <button
              class="planner-block-toggle"
              type="button"
              @click="toggleOptions('route')"
            >
              <Sprite :type="options.route ? 'chevron-up-round' : 'chevron-down-round'" :width="15" :height="10"/>
            </button>
          </div>

          <template v-if="options.route">
            <div class="planner-select">
              <Select
                :options="tunnelCategories"
                :value="params.vehicle.tunnelCategory"
                :label="$t('routePlanner.labels.tunnel')"
                :placeholder="$t('routePlanner.placeholders.tunnel')"
                :class="'select-input classic'"
                :allowNull="true"
                @onChange="onVehicleChange($event, 'tunnelCategory')"
              />
            </div>

            <div class="planner-select">
              <Select
                :options="hazardousGoods"
                :value="shippedHazardousGoods"
                :label="$t('routePlanner.labels.shippedHazardousGoods')"
                :placeholder="$t('routePlanner.placeholders.shippedHazardousGoods')"
                :class="'select-input classic'"
                :allowNull="true"
                :multiple="true"
                @onChange="onVehicleHazardousChange($event, 'shippedHazardousGoods')"
              />
            </div>

            <div class="planner-select">
              <Select
                :options="axlesCount"
                :value="params.vehicle.axlesCount"
                :label="$t('routePlanner.labels.axlesCount')"
                :placeholder="$t('routePlanner.placeholders.axlesCount')"
                :class="'select-input classic'"
                :allowNull="true"
                @onChange="onVehicleChange($event, 'axlesCount')"
              />
            </div>

            <template v-if="options.routeFull">
              <div class="planner-input">
                <Inputs
                  :value="params.vehicle.grossWeight"
                  :type="'text'"
                  :label="$t('routePlanner.labels.grossWeight')"
                  :placeholder="$t('routePlanner.placeholders.grossWeight')"
                  :name="'vehicle-grossweight'"
                  :id="'vehicle-grossweight'"
                  class="searchbar-form-input"
                  @onInput="onVehicleInput($event, 'grossWeight')"
                />
              </div>

              <div class="planner-input">
                <Inputs
                  :value="params.vehicle.weightPerAxle"
                  :type="'text'" 
                  :label="$t('routePlanner.labels.weightPerAxle')"
                  :placeholder="$t('routePlanner.placeholders.weightPerAxle')"
                  :name="'vehicle-weightPerAxle'"
                  :id="'vehicle-weightPerAxle'"
                  class="searchbar-form-input"
                  @onInput="onVehicleInput($event, 'weightPerAxle')"
                />
              </div>

              <div class="planner-input">
                <Inputs
                  :value="params.vehicle.height"
                  :type="'text'"
                  :label="$t('routePlanner.labels.height')"
                  :placeholder="$t('routePlanner.placeholders.height')"
                  :name="'vehicle-height'"
                  :id="'vehicle-height'"
                  class="searchbar-form-input"
                  @onInput="onVehicleInput($event, 'height')"
                />
              </div>

              <div class="planner-input">
                <Inputs
                  :value="params.vehicle.length"
                  :type="'text'" 
                  :label="$t('routePlanner.labels.length')"
                  :placeholder="$t('routePlanner.placeholders.length')"
                  :name="'vehicle-length'"
                  :id="'vehicle-length'"
                  class="searchbar-form-input"
                  @onInput="onVehicleInput($event, 'length')"
                />
              </div>

              <div class="planner-input">
                <Inputs
                  :value="params.vehicle.width"
                  :type="'text'"
                  :label="$t('routePlanner.labels.width')"
                  :placeholder="$t('routePlanner.placeholders.width')"
                  :name="'vehicle-width'"
                  :id="'vehicle-width'"
                  class="searchbar-form-input"
                  @onInput="onVehicleInput($event, 'width')"
                />
              </div>
            </template>

            <button
              class="planner-options-toggle"
              type="button"
              @click="toggleOptions('routeFull')"
            >
              {{ $t('routePlanner.buttons.advanced-options') }}
              <Sprite :type="options.routeFull ? 'chevron-up-round' : 'chevron-down-round'" :width="15" :height="10"/>
            </button>
          </template>
        </div>

        <!-- Route options -->
        <div
          v-if="type === 'parkings'"
          class="planner-block"
        >
          <div :class="[
            'planner-block-header',
            { 'is-open': options.stay }
          ]">
            <h3 class="planner-block-title">{{ $t('routePlanner.titles.stay') }}</h3>

            <button
              class="planner-block-toggle"
              type="button"
              @click="toggleOptions('stay')"
            >
              <Sprite :type="options.stay ? 'chevron-up-round' : 'chevron-down-round'" :width="15" :height="10"/>
            </button>
          </div>

          <template v-if="options.stay">
            <div
              v-for="(filter, index) in filters"
              :key="`${filter.id}-${index}`"
              class="planner-filter"
            >
              <Filter
                :filter="filter"
                @filterChange="onFilterChange($event, index)"
              />
            </div>

            <button
              class="planner-options-toggle"
              type="button"
              @click="toggleOptions('stayFull')"
            >
              {{ $t('routePlanner.buttons.all-options') }}
              <Sprite :type="options.stayFull ? 'chevron-up-round' : 'chevron-down-round'" :width="15" :height="10"/>
            </button>
          </template>
        </div>
      </template>
    </div>

    <div class="planner-footer">
      <button class="button button-big" type="submit">{{ $t('routePlanner.buttons.search') }}</button>
      <button
        class="button button-big button-border button-save"
        type="button"
        @click="$emit('saveFavorite')"
      >
        {{ $t('routePlanner.buttons.save_search') }}
      </button>
      <button
        v-if="!options.full"
        class="planner-options-toggle"
        type="button"
        @click="toggleOptions('full')"
      >
        {{ $t('routePlanner.buttons.advanced-options') }}
        <Sprite :type="'chevron-down-round'" :width="15" :height="10"/>
      </button>
    </div>
  </form>
</template>

<script>
import _debounce from 'lodash/debounce';
import Radio from '@/components/elements/Radio';
import Sprite from '@/components/elements/Sprite';
import Select from '@/components/elements/Select';
import Inputs from '@/components/elements/Inputs';
import Filter from '@/components/modules/Search/Filter';

import routePlanner from '@/data/route-planner.json';

export default {
  components: {
    Radio,
    Sprite,
    Select,
    Inputs,
    Filter
  },
  data() {
    return {
      options: {
        full: false,
        route: true,
        routeFull: false,
        stay: true,
        stayFull: false
      },
      token: 'pk.eyJ1Ijoib3RyYW1hcGJveGFjY291bnQiLCJhIjoiY2tucHZtbjA1MTI0MzJubGF2MTA1OHA1ZiJ9.NP7_3MVerJ6Skc9fCSJ3fg',
      current: {
        value: null,
        index: 0
      },
      currentDrag: null,
      currentDragIndex: null,
      results: [],
      tunnelCategories: [...routePlanner.tunnelCategories],
      axlesCount: [...routePlanner.axlesCount],
    }
  },
  computed: {
    waypoints() {
      return this.$store.state.routePlanner.waypoints;
    },
    waypointsCopy() {
      return null;
      return  this.$store.state.routePlanner.waypointsCopy;
    },
    params() {
      return this.$store.state.routePlanner.params;
    },
    hazardousGoods() {
      return routePlanner.hazardousGoods.map(good => {
        good.label = this.$t(`routePlanner.options.hazardousGoods.${good.value}`);
        return good;
      });
    },
    shippedHazardousGoods() {
      const { shippedHazardousGoods } = this.$store.state.routePlanner.params.vehicle;
      if (!shippedHazardousGoods || !shippedHazardousGoods.length) return [];
      return shippedHazardousGoods.split(',');
    },
    type() {
      return this.$store.state.map.type;
    },
    filters() {
      const sl = this.$store.state.map.filters.filter(f => f.id === 'sl');
      const rest = this.$store.state.map.filters.filter(f => f.id !== 'sl' && f.id !== 'range' && f.id !== 'distance');

      if (!this.options.stayFull) return sl;
      return [...sl, ...rest];
    },
  },
  methods: {
    onInput($event, index) {
      const value = $event.target.value;

      this.current.value = value;
      this.current.index = index;
      this.$store.commit('routePlanner/updateWaypointLabel', { value, index })

      if (this.current.value.length < 3) {
        this.results = null;
        return;
      }

      this.debouncedSearch();
    },
    // Waypoints
    onRemoveWaypoint(index) {
      this.$store.commit('routePlanner/removeWaypoint', index);
    },
    onAddWaypoint() {
      this.$store.commit('routePlanner/addWaypoint');
    },
    onBook(parking) {
     this.$emit('book', parking);
    },
    searchPlaces() {
      fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.current.value}.json?access_token=${this.token}&language=${this.$i18n.locale}`)
      .then(response => response.json())
      .then(data => {
        this.results = data.features;
      })
      .catch(error => {
        console.log(error);
      });
    },
    debouncedSearch: _debounce(function(payload) {
      this.searchPlaces()
    }, 500),
    onResultClick($event, result) {
      this[this.type] = result.place_name;
      this.$store.commit('routePlanner/updateWaypointLabel',  { index: this.current.index, value: result.place_name });
      this.$store.commit('routePlanner/updateWaypointData',  { index: this.current.index, value: result });
      this.results = [];
    },
    // Drag events
    onDrag(e, id) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.currentDrag) {
        this.currentDrag = id;
      }
    },
    onDragStart(e, id) {
      e.stopPropagation();
      e.dataTransfer.setData('text/plain', id);
    },
    onDragEnd(e, id) {
      this.currentDrag = null;
    },
    onDragEnter(e, id) {
      e.preventDefault();
      e.stopPropagation();
      if (id === this.currentDrag) return;

      if (this.currentDrag || this.currentDrag === 0) {
        this.$store.commit('routePlanner/updateWaypointsOrder', { replaceTo: id, replaceBy: this.currentDrag})
      }
    },
    onDragReset(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    onDragResetFalse(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    },
    // Inputs
     onVehicleInput($event, property) {
      const val = $event.target.value;
      const value = { ...this.params.vehicle };
      value[property] = val;
      this.$store.commit('routePlanner/updateParams', {
        property: 'vehicle', value
      });
    },
    onVehicleChange($event, property) {
      const value = { ...this.params.vehicle };
      value[property] = $event;
      this.$store.commit('routePlanner/updateParams', {
        property: 'vehicle', value
      });
    },
    onVehicleHazardousChange($event) {
      let hazardousGoods = [...this.shippedHazardousGoods];

      const index = hazardousGoods.indexOf($event);
      const value = { ...this.params.vehicle };

      if (index >= 0) {
        hazardousGoods.splice(index, 1);
      } else {
        hazardousGoods.push($event);
      }

      if (!$event) {
        value.shippedHazardousGoods = [];
      } else {
        value.shippedHazardousGoods = hazardousGoods.join(',');
      }

      this.$store.commit('routePlanner/updateParams', { property: 'vehicle', value });
    },
    onRadio(val, field) {
      const value = { ...this.params };
      value[field] = val;
      this.$store.commit('routePlanner/updateParams', { value });
    },
     onFilterChange({ filter, value }) {
      this.$store.commit('map/updateFilterValue', { filter, value });
    },
    // Toggles
    toggleOptions(type) {
      this.options[type] = !this.options[type];
    },
    // Submit
    onSubmit() {
      this.$store.dispatch('routePlanner/search');
      this.$emit('search');
    },
    // helpers
    randomName() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
.planner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: calc(100% + 40px);
  margin-left: -20px;

  &-content {
    padding: 0 20px;
    width: 100%;
    max-height: calc(100% - 70px);
    overflow: auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:not(:first-child) {
      margin-top: 22px;
      padding-top: 22px;
      border-top: 1px solid rgba(31, 43, 96, 0.1);
    }

    &.is-dragging {
      .planner-waypoint {
        * {
          pointer-events: none;
        }
      }
    }

    &-header {
      display: flex;
      align-items: center;
      width: 100%;

      &.is-open {
        padding-bottom: 24px;
      }

      + .planner-select {
        margin-top:0;
      }
    }

    &-title {
      color: $darkblue;
      font-family: $primary;
      font-style: italic;
      font-size: rem(18px);
      line-height: 1.4;
      text-transform: uppercase;
    }

    &-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      margin-left: auto;
      border-radius: 50%;
      background-color: $mediumgreen;
      cursor: pointer;

      .svg {
        margin-top: 1px;
        margin-left: 1px;
        fill: $white;

        &.svg-chevron-up-round {
          margin-top: -1px;
        }
      }
    }

    .planner-options-toggle {
      margin-top: 15px;
    }
  }

  &-waypoint-copy {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
  }

  &-waypoint {
    display: flex;
    align-items: center;
    position: relative;
    width: calc(100% + 40px);
    /* margin-left: -20px; */
    padding: 1px 20px 0;

    &:first-child {
      padding-top: 0;

      &::before { content: none; }

      .planner-waypoint-icon {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-image: none;
        background-color: $darkblue;
        margin-left: 2px;

        &::after { content: none; }
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 20px;
      width: calc(100% - 68px);
      height: 1px;
      border-top: 2px dashed rgba(31, 43, 96, .1);
    }

    &-icon {
      flex-shrink: 0;
      margin-right: 9px;
      width: 14px;
      height: 18px;
      background-image: url('~@/assets/img/pins/waypoint-pin.png');
      background-size: 100% 100%;

      &::after {
        content: '';
        position: absolute;
        top: -7px;
        left: 26px;
        width: 2px;
        height: 14px;
        background-image: url('~@/assets/img/pins/waypoint-dots.png');
        background-size: 100% 100%;
      }
    }

    &-input {
      display: block;
      width: 100%;
      padding: 14px 0 14px;
      border: 0;
      color: $darkblue;
      font-family: $secondary;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;

      /* &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      } */

      &:disabled {
        opacity: 1;
      }

      &::placeholder {
        color: rgba(31, 43, 96, .5);
      }

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &-book {
      flex-shrink: 0;
      color: $mediumgreen;
      font-size: rem(15px);
      font-weight: 600;
      line-height: 1;
      padding: 14px 0;
      margin-right: 15px;

      &:hover {
        color: $darkblue;
      }
     }

    &-close {
      cursor: pointer;

      &:hover {
        .svg {
          fill: $darkblue;
        }
      }

      .svg {
        fill: $grey;
        transition: fill .3s $ease-out-quart;
      }
    }

    &-results {
      position: absolute;
      top: calc(100% + 5px);
      right: 0;
      z-index: 1;
      width: calc(100% - 23px);
      height: auto;
      border: 1px solid $lightestgrey;
      border-radius: 8px;
      padding: 14px 0 ;
      background-color: $white;
      list-style-type: none;
    }

    &-result {
      padding: 5px 14px;
      cursor: pointer;
      color: $darkblue;
      font-family: $secondary;
      font-size: rem(14px);
    }
  }

  &-add-waypoint {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 5px;
    margin-left: -3px;
    color: $darkblue;
    font-size: rem(12px);
    font-weight: 500;
    line-height: 1;
    opacity: .3;
    transition: opacity .3s $ease-out-quart;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    .svg {
      margin-right: 11px;
      fill: currentColor;
    }
  }

  // Inputs
  &-radios {
    display: flex;
    width: 100%;
    margin-top: 17px;

    .input {
      + .input {
        margin-left: 12px;
      }
    }
  }

  &-input,
  &-select {
    width: 100%;
    margin-top: 8px;
  }

  &-filter {
    width: 100%;

    + .planner-filter {
      margin-top: 15px;
    }
  }

  // Footer
  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0;

    .button {
      width: 100%;

      + .button {
        margin-top: 10px;
      }
    }

    .planner-options-toggle {
      margin-top: 15px;
    }
  }

  &-options-toggle {
    padding: 5px 10px;
    color: $darkblue;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: color .3s $ease-out-quart;

    &:hover {
      color: $mediumgreen;
    }

    .svg {
      fill: currentColor;
      margin-left: 8px;
    }
  }
}
</style>

<style lang="scss">
.planner {
  &-select {
    .select {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        font-size: rem(15px);
        margin-bottom: 0;
        color: $darkblue;
      }

      .selected {
        &-icon {
          .svg {
            fill: $darkblue;
          }
        }
      }

      .custom-select {
        width: 240px;

        .selected {
          padding: 10px 12px 8px;
          font-size: rem(15px);
        }

        .items {
          max-height: 120px;
        }

        .item {
          padding: 6px 12px;
          font-size: rem(15px);
        }
      }
    }
  }

  &-input {
    .input {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        font-size: rem(15px);
        color: $darkblue;
      }

      input {
        flex-shrink: 0;
        width: 240px;
        padding: 10px 12px 8px;
        color: $darkblue;
        font-size: rem(15px);
      }
    }
  }

  &-filter {
    .filter {
      .filter-info {
        margin-top: 4px;

        .svg {
          svg {
            width: 15px !important;
            height: 15px !important;
          }
        }
      }

      .filter-content {
        margin-top: 8px;
      }
    }
  }
}
</style>