import {
  getTrucks,
  getTruck,
  addTruck,
  updateTruck,
  removeTruck,
  checkTruckAccount,
  linkTruckAccount,
  unlinkTruckAccount
} from '@/calls/trucks';

import i18n from '../i18n';


const trucks = {
  namespaced: true,
  state: {
    requests: 0,
    requestsDetail: 0,
    item: {
      name: '',
      licenseCountry: '',
      licensePlate: '',
      brand: '',
      model: '',
    },
    items: [],
    search: null,
    popups: {
      new: {
        visible: false,
        requests: 0,
        success: false,
        errors: {
          name: '',
          licenseCountry: '',
          licensePlate: '',
          brand: '',
          model: '',
        }
      },
      account: {
        visible: false,
        requests: 0,
        success: false,
        accountFound: false,
        confirm: false,
        form: {
          fullname: '',
          phone: ''
        },
        errors: {
          fullname: '',
          phone: ''
        }
      },
      removeAccount: {
        visible: false,
        requests: 0,
        success: false
      },
      update: {
        visible: false,
        requests: 0,
        success: false,
        errors: {
          name: '',
          licenseCountry: '',
          licensePlate: '',
          brand: '',
          model: ''
        }
      },
      remove: {
        visible: false,
        requests: 0,
        success: false
      }
    },
  },
  mutations: {
    updatePopupVisibility(state, { popup, visible }) {
      state.popups[popup].visible = visible;
    },
    updatePopupProperty(state, { popup, property, value }) {
      state.popups[popup][property] = value;
    },
    updatePopupForm(state, { popup, field, value }) {
      state.popups[popup].form[field] = value;
    },
    updatePopupError(state, { popup, field, value }) {
      state.popups[popup].errors[field] = value;
    },
    updatePopupSuccess(state, { popup, field, value }) {
      state.popups[popup].successes[field] = value;
    },
    updateItemProperty(state, { property, value }) {
      state.item[property] = value;
    },
    updateItems(state, items) {
      state.items = items;
    },
    updateItem(state, item) {
      state.item = item;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    updateOneItem(state, { index, item }) {
      state.items[index] = item;
    },
    removeOneItem(state, index) {
      state.items.splice(index, 1);
    },
    resetItem(state) {
      state.item = {
        name: '',
        licenseCountry: '',
        licensePlate: '',
        brand: '',
        model: '',
      }
    },
    resetAccount(state) {
      state.popups.account.form = {
        fullname: '', phone: ''
      };
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
    increaseRequestsDetail(state) {
      state.requestsDetail++;
    },
    decreaseRequestsDetail(state) {
      state.requestsDetail--;
    }
  },
  actions: {
    async getTrucks({ commit, dispatch, state }) {
      commit('increaseRequests');
      const search = state.search && state.search.length ? state.search : null;
      const trucks = await getTrucks(search);

      if (trucks.error) {
        const { tc } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.errors.trucks.getting', 2, { error:  trucks.error}),
          // text: `Error while getting trucks : ${trucks.error}`,
          type: 'error'
        }], { root: true });
        
        commit('decreaseRequests');
        return;
      }

      const trucksItem = trucks.map(truck => {
        if (truck.model) truck.model = truck.model.trim();
        return truck;
      });

      commit('updateItems', trucksItem);
      commit('decreaseRequests');
    },
    async addNew({ commit, state, dispatch }) {
      commit('updatePopupProperty', {
        popup: 'new',
        property: 'requests',
        value: state.popups.new.requests + 1
      });

      const truck = await addTruck(state.item);

      if (truck.hasError) {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.trucks.add', { error:  truck.error}),
          type: 'error'
        }], { root: true });
      } else {
        commit('updatePopupProperty', {
          popup: 'new',
          property: 'success',
          value: true
        });

        dispatch('getTrucks');
      }

      commit('updatePopupProperty', {
        popup: 'new',
        property: 'requests',
        value: state.popups.new.requests - 1
      });
    },
    async update({ commit, state, dispatch }) {
      commit('updatePopupProperty', {
        popup: 'update',
        property: 'requests',
        value: state.popups.update.requests + 1
      });

      const { item } = state;
      const truck = await updateTruck(item);

      if (truck.hasError) {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.trucks.update', { error:  truck.error}),
          // text: `Error while updating truck : ${truck.error}`,
          type: 'error'
        }], { root: true });
      } else {
        commit('updatePopupProperty', {
          popup: 'update',
          property: 'success',
          value: true
        });

        if (state.items && state.items.length) {
          for (let i = 0; i < state.items.length; i++) {
            if (state.items[i].id === item.id) {
              commit('updateOneItem', {
                index: i,
                item: { ...item }
              });
              break;
            }
          }
        }
      }

      commit('updatePopupProperty', {
        popup: 'update',
        property: 'requests',
        value: state.popups.update.requests - 1
      });
    },
    async remove({ commit, state, dispatch }) {
      commit('updatePopupProperty', {
        popup: 'remove',
        property: 'requests',
        value: state.popups.remove.requests + 1
      });

      const { item } = state;
      const truck = await removeTruck(item);

      if (truck.hasError) {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.trucks.remove', { error:  truck.error}),
          // text: `Error while removing truck : ${truck.error}`,
          type: 'error'
        }], { root: true });
      } else {
        if (truck) {
          commit('updatePopupProperty', {
            popup: 'remove',
            property: 'success',
            value: true
          });

          // dispatch('getTrucks');
  
          if (state.items && state.items.length) {
            for (let i = 0; i < state.items.length; i++) {
              if (state.items[i].id === item.id) {
                commit('removeOneItem', i);
                break;
              }
            }
          }
        }
      }

      commit('updatePopupProperty', {
        popup: 'remove',
        property: 'requests',
        value: state.popups.remove.requests - 1
      });
    },
    async getTruck({ commit, dispatch, state }, id) {
      commit('increaseRequestsDetail');
      const truck = await getTruck(id);

      if (truck.hasError) {
        const { tc } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.errors.trucks.getting', 1, { error:  truck.error}),
          // text: `Error while getting truck : ${truck.error}`,
          type: 'error'
        }], { root: true });
      } else {
        // if (truck.model) truck.model = truck.model.trim();
        commit('updateItem', truck);
      }

      commit('decreaseRequestsDetail');
    },
    async checkAccount({ rootState, state, commit, dispatch }) {
      commit('updatePopupProperty', { popup: 'account', property: 'requests', value: state.popups.account.requests + 1 });

      const { t } = i18n.global;
      const { id } = state.item;
      const { phone } = state.popups.account.form;
      const request = await checkTruckAccount(id, { phoneNumber: phone });

      if (request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.trucks.account.checking', { error:  request.error }),
          type: 'error'
        }], { root: true });
      } else {
        const { data } = request;
        if (!data.linkable) {
          // Driver is not linkable (not a truckAccount, )
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.trucks.account.checking', { error: data.message }),
            type: 'error'
          }], { root: true });
        } else {
          if (data.driver) {
            commit('updatePopupProperty', { popup: 'account', property: 'accountFound', value: data.driver });
          } else {
            const fleet = rootState.fleet.current;
            const truck = state.item;

            commit('updatePopupForm', { popup: 'account', field: 'fullname', value: `${fleet.name}-${truck.name}` });
            commit('updatePopupProperty', { popup: 'account', property: 'confirm', value: true});
          }
        }
      }

      commit('updatePopupProperty', { popup: 'account', property: 'requests', value: state.popups.account.requests - 1 });
    },
    async addAccount({ state, rootState, commit, dispatch }) {
      commit('updatePopupProperty', { popup: 'account', property: 'requests', value: state.popups.account.requests + 1 });

      const { form } = state.popups.account;
      const { id } = state.item;

      const request = await linkTruckAccount(id, {
          phoneNumber: form.phone,
          fullName: form.fullname
        });

      if (request.hasError) {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.trucks.account.creating', { error: request.error.message ? request.error.message : request.error}),
          type: 'error'
        }], { root: true });
      } else {
        dispatch('getTrucks');
        commit('updatePopupProperty', { popup: 'account', property: 'success', value: true });
      }

      commit('updatePopupProperty', { popup: 'account', property: 'requests', value: state.popups.account.requests - 1 });
    },
    async confirmAccount({ state, commit, dispatch }) {
      commit('updatePopupProperty', { popup: 'account', property: 'requests', value: state.popups.account.requests + 1 });

      const account = state.popups.account.accountFound;
      const { phone } = state.popups.account.form;
      const { id } = state.item;

      const request = await linkTruckAccount(id, { phoneNumber: phone });

      if (request.hasError) {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.trucks.account.confirm', { error:  request.error}),
          type: 'error'
        }], { root: true });
      } else {
        dispatch('getTrucks');
        commit('updatePopupProperty', { popup: 'account', property: 'success', value: true });
      }

      commit('updatePopupProperty', { popup: 'account', property: 'requests', value: state.popups.account.requests - 1 });
    },
    async removeAccount({ state, commit, dispatch }) {
      commit('updatePopupProperty', { popup: 'removeAccount', property: 'requests', value: state.popups.removeAccount.requests + 1 });

      const { id, fleetPositions } = state.item;
      const request = await unlinkTruckAccount(id, fleetPositions[0].id);

      if (request.hasError) {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.trucks.remove-account', { error:  request.error}),
          type: 'error'
        }], { root: true });
      } else {
        dispatch('getTrucks');
        commit('updatePopupProperty', { popup: 'removeAccount', property: 'success', value: true });
      }

      commit('updatePopupProperty', { popup: 'removeAccount', property: 'requests', value: state.popups.removeAccount.requests - 1 });
    }
  }
};

export default trucks;
