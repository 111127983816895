import axios from 'axios';

export const getFavorites = async () => {
  try {
    const request = await axios.get(`/fleet/favorites`);
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    }
  }
};

export const getFavorite = async (id) => {
  try {
    const request = await axios.get(`/fleet/favorites/${id}`);
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    }
  }
};

export const addFavorite = async (params) => {
  try {
    const request = await axios.post('/fleet/favorites/', { ...params });
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    }
  }
};

export const deleteFavorite = async (id) => {
  try {
    const request = await axios.delete(`/fleet/favorites/${id}`);
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    }
  }
};