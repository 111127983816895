<template>

  <section v-if="parking" class="main-container snap-layout snap-booking" ref="container"
    :class="[`main-container__${step}`, { 'main-container--loading': loading }]">
    <!-- v-if="step !== 'StepMap'" -->
    <button type="button" v-if="step !== 'StepStay'" class="backstreet-back" @click="onBack()">
      <Sprite :width="10" :height="14" :type="'arrow-left'" />
      {{ $t('globals.back') }}
    </button>

    <h3>{{ parking.internationalName }}</h3>

    <breadcrumb :step="step" :previous="previous" />
    <component v-bind:is="step" :datas="datas" @newStep="handleStep" />
    <div v-show="loading" class="main-container-loader">
      <loader />
    </div>

    <Rules />
  </section>
</template>

<script>
import { validateSnapBookingParams } from '@/helpers/snapvalidation';
import Sprite from '@/components/elements/Sprite';

import Breadcrumb from '@/components/modules/Search/Snap_Breadcrumb.vue';
// import StepMap from '@/components/partials/Search/StepMap.vue';
// import StepMapNew from '@/components/partials/Search/StepMapNew.vue';
import StepDetails from '@/components/partials/Search/Snap_StepDetails.vue';
import StepStay from '@/components/partials/Search/Snap_StepStay.vue';
// import StepSlot from '@/components/partials/Search/StepSlot.vue';
import StepReview from '@/components/partials/Search/Snap_StepReview.vue';
// import ParkingDetail from '@/components/partials/Search/ParkingDetail';
import Loader from '@/components/elements/Loader';
import Rules from '@/components/partials/Popups/Rules';
import { SourceEnum } from '../../helpers/snapEnums';
import { setLanguage } from '../../helpers/snaplanguage';
export default {
  components: {
    Breadcrumb,
    // StepMapNew,
    // StepMap,
    // StepDetails,
    StepStay,
    // StepSlot,
    StepReview,
    // ParkingDetail,
    Sprite,
    Loader,
    Rules,
  },

  data() {
    return {
      parkingId: null,
      dayOfBooking: null,
      licensePlate: null,
      fleetId: null,
      email: null,
      clientId: null,
      clientSecret: null,
      step: 'StepStay',
      // step: 'StepMap',
      steps: [
        // 'StepMapNew',
        // 'StepMap',
        'StepStay',
        // 'StepSlot',
        // 'StepDetails',
        'StepReview',
      ],
      previous: [],
      datas: null
    };
  },
  computed: {
    token() {
      return this.$store.getters['snapIntegration/token'];
    },
    hasError() {
      return this.$store.getters['snapIntegration/hasError'];
    },
    parking() {
      return this.$store.state.siParkings.details.parking;
    }
  },
  created() {
    const query = this.$route.query;

    const lowerCaseQuery = Object.keys(query).reduce((acc, key) => {
      acc[key.toLowerCase()] = query[key];
      return acc;
    }, {});

    validateSnapBookingParams(lowerCaseQuery, 'booking');

    this.parkingId = lowerCaseQuery['parkingid'];
    this.dayOfBooking = lowerCaseQuery['day_of_booking'];
    this.licensePlate = lowerCaseQuery['license_plate'];
    this.fleetId = lowerCaseQuery['fleetid'];
    this.email = lowerCaseQuery['email'];
    this.clientId = lowerCaseQuery['clientid'];
    this.clientSecret = lowerCaseQuery['clientsecret'];
    this.language = lowerCaseQuery['language'];

    this.$store.dispatch('snapIntegration/fetchToken', { clientId: this.clientId, clientSecret: this.clientSecret })
      .then(() => {
        const requestBody = {
          validateBookingParams: {
            fleetId: Number(this.fleetId),
            licensePlate: this.licensePlate,
            dateOfBooking: this.dayOfBooking,
            parkingId: Number(this.parkingId),
            languageCode: this.language
          },
          source: {
            source: SourceEnum.BOOKING
          }
        };
        this.$store.dispatch('snapIntegration/validateBooking', {item : requestBody, source :"booking"})
          .then((bookingValidateResponse) => {
            setLanguage(this.language, this.$store);
            this.$store.dispatch('siParkings/setParking', this.parkingId)
              .then(() => {
                this.$store.commit("siParkings/updateRequests", {
                  value: `${this.dayOfBooking.slice(6, 10)}-${this.dayOfBooking.slice(3, 5)}-${this.dayOfBooking.slice(0, 2)}`,
                  property: "date",
                });
                this.$store.commit("siParkings/updateForm", {
                  value: this.licensePlate,
                  property: "licensePlate",
                });
                this.$store.commit("siParkings/updateForm", {
                  value: this.email,
                  property: "email",
                });
                this.$store.commit("siParkings/updateForm", {
                  value: this.fleetId,
                  property: "fleetId",
                });
                this.$store.commit("siParkings/updateForm", {
                  value: this.parkingId,
                  property: "parking",
                });
                this.$store.commit("siParkings/updateForm", {
                  value: this.parking?.internationalName,
                  property: "parkingName",
                });

                this.$store.commit('siParkings/updateDrivers', bookingValidateResponse?.snapDrivers);

                const trucks = bookingValidateResponse?.snapDrivers.map(driver => ({
                  value: driver.truckId,
                  label: driver.truckName,
                  active: true,
                }));

                this.$store.commit('siParkings/updateTrucks', trucks);

                const firstDriver = bookingValidateResponse?.snapDrivers[0] || {};
                this.$store.commit("siParkings/updateForm", {
                  value: firstDriver.truckId,
                  property: "truck",
                });

                this.$store.commit("siParkings/updateForm", {
                  value: firstDriver.driverId,
                  property: "driver",
                });
              })
          })
      })
      .catch((error) => {
        console.error('Error fetching token:', error);
      });
  },
  methods: {
    handleBeforeUnload() {
      this.$store.dispatch('snapIntegration/clearToken');
    },
    handleStep(obj) {
      this.previous[this.step] = this.datas;
      this.step = obj.step
      this.datas = obj.datas
    },
    onBack() {
      const previous = Object.keys(this.previous)[Object.keys(this.previous).length - 1];

      // let query = Object.assign({}, this.$route.query )
      // if(this.step === "StepStay" && query.duration) delete query.duration
      // else if(this.step === "StepSlot" && query.slot) delete query.slot
      // else if(this.step === "StepDetails" && query.parking) delete query.parking
      // else if(this.step === "StepReview" && query.startAt) delete query.startAt
      // if(this.step === "StepSlot" && previous === "StepDetails" && query.duration) delete query.duration
      // this.$router.push({ query: query })

      if (this.step === 'StepStay') this.$store.commit('parkings/resetForm');
      this.step = previous;
      this.datas = this.previous[previous];
      delete this.previous[previous];
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
};
</script>
<style lang="scss">
.main-search {
  .main-container:not(.main-container__StepMap):not(.main-container__StepMapNew) {
    padding: 32px;
  }
}

.svg-arrow-left {
  margin-right: 2px;
}

.backstreet-back {
  color: $snap-text-secondary;
  font-family: $secondary;
  font-size: rem(16px);
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;

  .svg path {
    fill: currentColor !important;
  }
}


.snap-booking {

  .input-textarea,
  .input-common {
    border-color: $snap-lines-default !important;
    border-radius: 3px !important;
    color: $snap-text-primary !important;

    &:focus-visible {
      border-color: $snap-lines-inputs !important;
    }
  }

  .breadcrumb {
    color: $snap-text-primary !important;
    font-weight: bold !important;
    text-transform: uppercase !important;

    .svg-chevron {
      fill: $snap-primary !important;
    }

    &--active .breadcrumb-step__content::after {
      background-color: $snap-primary !important;
    }
  }

  .button {
    background-color: $snap-success !important;
    border-color: $snap-success !important;
    border-radius: 3px !important;
    color: $snap-success-text !important;
    font-weight: bold !important;
    text-transform: uppercase !important;

    &:hover {
      background-color: $snap-success-darken !important;
      border-color: $snap-success-darken !important;
    }

    &:disabled {
      background-color: $snap-action-disabled-background !important;
      border-color: $snap-action-disabled-background !important;
    }

    &-border {
      border: 1px solid $snap-success !important;
      background: transparent !important;
      color: $snap-success !important;
      
      &:hover {
        border: 1px solid $snap-success-darken !important;
        text-decoration: none;
        background: none !important;
        color: $snap-success-darken !important;
      }
    }

    &-ghost {
      border-color: $snap-success-darken !important;
      background: none !important;
      color: $snap-success-darken !important;

      &[disabled] {
        background: none;
        // opacity: .8;
        color: $lightgrey;
      }

      &:hover {
        border-color: $snap-success-darken !important;
        background: $snap-success-darken !important;
        color: $white !important;
      }
    }
  }

  .collapsing-step {
    border-color: $snap-lines-inputs !important;
    border-radius: 3px !important;
    max-width: none;

    &.is-open {
      border-color: $snap-action-default !important;
      border-width: 2px !important;
    }

    .cs-index {
      background-color: $snap-primary-darken !important;
    }

    .cs-title {
      color: $snap-text-primary !important;
      text-transform: none !important;
    }

    .cs-value {
      text-transform: none !important;
    }

    .cs-toggle .svg {
      fill: $snap-text-primary !important;
    }
  }

  .step-stay-slottypes .button-slot {
    background-color: $snap-success !important;
    color: $snap-success-text !important;
  }

  .step-stay-packages-list {
    gap: 24px !important;
  }

  .slot {
    border: none;
    border-radius: 3px !important;
    box-shadow: $snap-elevation-1 !important;
    flex: 1;
    margin-left: 0 !important;
    max-width: fit-content;

    &:hover .slot-time {
      color: $snap-success !important;
    }
  }

  .slot-time {
    color: $snap-text-primary !important;
  }

  .slot-price {
    color: $snap-text-primary !important;
  }

  .slot-thumb .price {
    color: $snap-success !important;
    font-weight: bold !important;
  }

  .table .nickname {
    color: $snap-text-primary !important;
    font-weight: bold !important;
  }

  .step-stay-slots .table-booknow {
    td:first-child {
      padding-left: 65px !important;
    }

    td.availability {
      color: $snap-success !important;
    }
  }

  .step-infos {
    height: auto !important;
  }

  .step-infos-columns {
    min-height: min-content !important;
  }

  .step-infos-input {
    margin-top: rem(16px) !important;
  }

  .step-infos-result {
    color: $snap-text-primary !important;
  }

  .step-details-baseline {
    color: $snap-text-primary !important;

    u {
      color: $snap-primary !important;
      &:hover {
        color: $snap-primary-darken !important;
      }
    }
  }

  .table .insurance p a {
    color: $snap-primary !important;
    &:hover {
      color: $snap-primary-darken !important;
    }
  }
}

.app-loader .app-loader-title {
  color: $snap-text-primary !important;
}


</style>
