<template>
  <div 
    class="input"
    :class="[
      { 'error': error },
      { 'is-disabled': disabled }
    ]"
  >
    <label
      class="label small"
      :class="noLabel ? 'no-label' : ''"
    >
      {{ label }}
      <span
        v-if="counting"
        :class="[
          'input-count',
          { 'has-warning': count <= 3 && count > 1 },
          { 'has-error': count <= 1 }
        ]"
      >
        <span class="input-count-number">{{ count }}</span>
        <!-- characters left -->
      </span>
    </label>

    <textarea
      v-if="type === 'textarea'"
      class="input-textarea"
      ref="input"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      :disabled="disabled"
      @input="onInput"
    ></textarea>
    <div
      v-else-if="type === 'text-prefix'"
      class="input-box"
    >
      <span 
        class="prefix"
      >{{ prefix }}</span>
      <input
        class="input-main"
        ref="input"
        :type="'text'"
        :placeholder="placeholder"
        :required="required"
        :value="value"
        :data-prefix="prefix"
        :min="min"
        :disabled="disabled"
        @input="onInput"
      />
    </div>
    <input
      v-else
      class="input-common"
      ref="input"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      :min="min"
      :maxlength="max"
      :disabled="disabled"
      @input="onInput"
    />
    <strong
      v-if="error"
      class="small error-msg"
    >
      <span>{{ error }}</span>
    </strong>
  </div>
</template>

<script>
import { vModelSelect } from 'vue';

export default {
  name: 'Inputs',
  props: {
    label: {
      type: String,
      default: null
    },  
    type: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },  
    error: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1000
    },
    prefix: {
      type: String,
      default: null
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    counting: {
      type: [Number, Boolean],
      default: false
    }
  },
  computed: {
    count() {
      if (!this.counting) {
        return 0;
      }

      if (!this.value) {
        return this.counting;
      }

      return this.counting - this.value.length;
    }
  },
  watch: {
    value(val) {
      return val;
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
      this.$emit('onInput', e);
    }
  }
}
</script>


<style lang="scss" scoped>
.small {
  color: $mediumgrey;
  font-size:  rem(14px);
  // display: block;

  margin-bottom: 8px;

  transition: color .3s $ease-out-quart;
  .error & {
    color: $error;
  }
}

.no-label {
  opacity: 0;
}

label {
  display: flex;
  width: 100%;
  position: relative;

  > span {
    margin-left: auto;
    color: $success;

    &.has-warning {
      color: $warning;
    }

    &.has-error {
      color: $error;
    }
  }
}

.input-textarea,
.input-common {
  border: 1px solid $lightestgrey;
  border-radius: 8px;

  padding: 20px 16px 18px;

  width: 100%;
  transition: border .3s $ease-out-quart;

  @extend %p;
  color: $darkblue;
  &::placeholder {
    color: $mediumgrey;
  }
  .error & {
    border: 1px solid $error !important;
  }
}

textarea {
  min-height: 200px;
  resize: none;

  &[disabled] {
    color: $grey;
  }
}

.error-msg {
  color: $error;
  margin-top: 4px;

  height: 20px;
  opacity: 0;
  transition: opacity .3s $ease-out-quart;
  .error & {
    opacity: 1;
  }
}

.input-box {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  border: 1px solid $lightestgrey;
  border-radius: 8px;
  padding-left: 0.5rem;
  overflow: hidden;
  transition: border .3s $ease-out-quart;

  @extend %p;
  color: $darkblue;
  &::placeholder {
    color: $mediumgrey;
  }
  .error & {
    border: 1px solid $error !important;
  }
}

.input-box .prefix {
  font-weight: 300;
  font-size: 16px;
  color: #000;
  padding: 10px 0 8px 5px;
}

.input-box .input-main {
  flex-grow: 1;
  background: #fff;
  border: none;
  outline: none;
  padding: 16px 8px 14px;
  width: 100%;
}

</style>
