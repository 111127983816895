
class Form {
  static validation(form, properties) {
    const errors = [];

    for (let i = 0; i < properties.length; i++) {
      const property = properties[i];
      const value = form[property];
      switch (property) {
        case 'email':
          if (!value || !value.length) {
            errors.push({ property, type: 'required' });
          } else {
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (property === 'email' && !regex.test(value)) {
              errors.push({ property, type: 'email_invalid' });
            }
          }
          break;
      
        default:
          if (!value || !value.length) errors.push({ property, type: 'required' });
          break;
      }
    }

    return errors;
  }
}

export default Form;