<template>
  <!-- Popup Instruction -->
  <Popup
    v-if="popup.visible"
    :classes="'popup-rules'"
  >
    <template v-slot:content>
      <!-- Form -->
      <div class="popup-header">
        <img class="popup-header-img" src="/img/rules.svg"/>
        <h2 class="popup-title h4">{{ $t('components.parking_info.rules') }}</h2>
      </div>

      
      <form class="popup-form">      
        <Inputs v-if="popupHtml.value === null || popupHtml.value.length === 0"
          ref="description"
          type="textarea"
          :value="popup.value"
          :disabled="true"
        />
      </form>

      <form class="popup-form">      
        <span class="info-rules" v-html="popupHtml.value"></span>
      </form>

      <div class="popup-actions">
        <button
          class="button button-ghost"
          @click="close"
        >
          {{ $t('globals.close') }}
        </button>
      </div>
    </template>
  </Popup>
</template>

<script>
/* eslint-disable */
import Popup from '@/components/partials/Popup';
import Inputs from '@/components/elements/Inputs';

export default {
  components: {   
    Popup,
    Inputs
  },
  data() {
    return {
      type: 'rules',
      typeHtml : 'rulesHTML'
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.rules;
    },
    popupHtml() {
      return this.$store.state.popups.rulesHTML;
    }
  },
  methods: {
    close(popup) {
      this.$store.commit('popups/updatePopup', { popup: this.type, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { popup: this.type, property: 'value', value: null });

      this.$store.commit('popups/updatePopup', { popup: this.typeHtml, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { popup: this.typeHtml, property: 'value', value: null });

    }
  }
}
</script>