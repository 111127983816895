<template>
  <button :class="['slot', { 'selected': selected }]">
    <p class="slot-thumb">
      <span
        class="slot-time"
        v-html="$t('components.slot_thumb.stay', { time })"
      />
      <span class="slot-price">
        {{ price === 0 ? '&nbsp;' : $t('components.slot_thumb.as_from') }}
        <strong class="price">{{ price === 0 ? $t('components.slot_thumb.free') : price + '€' }}</strong>
        <small>{{ price === 0 ? '&nbsp;' : $t('components.slot_thumb.vat') }}</small>
      </span>
    </p>
    <!-- <button 
      class="button" 
      type="button"
    >
      {{ $t('components.slot_thumb.select') }}
    </button> -->
    <span v-if="selectItem" class="slot-round"></span>
  </button>
</template>

<script>
export default {
  name: "SlotThumb",
  props: {
    time: {
      type: [Number, String]
    },
    price: {
      type: [Number, String]
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectItem: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
  .slot {
    width: 200px;
    box-shadow: 4px 4px 10px rgba($black, 0.08);
    border: 1px solid $lightestgrey;
    border-radius: 11px;
    padding: 15px 30px;
    margin-right: 24px;
    margin-bottom: 32px;
    cursor: pointer;
    transition: background-color .3s $ease-out-quart;

    &:hover {
      background-color: rgba($grey, .08);

      .slot-time {
        color: $mediumgreen;
      }
    }

    &.selected {
      .slot-round {
        &::after {
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          left: calc(50% - 5px);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $mediumgreen;
         }
      }
    }

    &-thumb {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .time {
        display: block;
        font-size: rem(34px);
        line-height: 1;
      }

      .price {
        display: block;
        font-size: rem(40px);
        font-weight: 500;
      }
    }

    &-time {
      font-family: $secondary;
      font-weight: 700;
      font-style: italic;
      font-size: rem(32px);
      color: $darkblue;
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: 26px;
      transition: color .3s $ease-out-quart;
    }

    &-price {
      font-family: $secondary;
      font-weight: 500;
      text-transform: uppercase;
      font-style: italic;
      font-size: rem(24px);
      line-height: 1.2;
      margin-bottom: 26px;

      small {
        font-size: 18px;
        line-height: 1;
        /* margin-top: -2px; */
        display: block;
      }
    }

    &-round {
      display: block;
      margin: 0 auto;
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid $mediumgreen;
    }
  }
</style>