<template>
  <div class="filter">
    <span class="filter-icon">
      <Sprite
        :type="`feature-${filter.icon}`"
        :width="20"
        :height="23"
      />
    </span>
    <div class="filter-header">
      <h4 class="filter-title">{{ filter.id === 'range' ?  translations['database.filters.distance.name'] : translations[`${filter.translationKey}.name`] }}</h4>
      <button
        v-if="filter.id === 'sl'"
        class="filter-info"
        :title="$t('globals.more_info')"
        @click.stop="openSecurityLevels"
      >
        <Sprite :type="'info'" :width="24" :height="24" />
      </button>
    </div>

    <!-- Content -->
    <div class="filter-content">
      <template v-if="filter.id === 'range'">
        <Range
          :id="filter.id"
          :min="filter.min"
          :max="filter.max"
          :value="filter.value"
          @onInput="onRange($event)"
          @onChange="onRange($event, true)"
        />
      </template>
      <template v-if="filter.options">
        <button
          v-for="(item, ind) in filter.options"
          :key="`${filter.id}-${ind}`"
          class="filter-content-item"
          type="button"
          @click="onClick(item, filter)"
        >
          <span class="icon">
            <Sprite
              v-show="item.isActive"
              :type="`check`"
              :width="12"
              :height="9"
            />
          </span>
          {{ translations[`${item.translationKey}.name`] }}
          <!-- {{ item.label }} -->
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import Range from '@/components/elements/Range';

export default {
  components: {
    Range,
    Sprite
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    translations() {
      return this.$store.state.translations.keys;
    }
  },
  methods: {
    onRange(value, update = false) {
      this.$emit('filterChange', { filter : { id: 'range' }, value, update });
      // this.$emit('filterChange', { id: 'range', value, update });
    },
    onClick(option, filter) {
      this.$emit('filterChange', { filter, value: option.value });
    },
    openSecurityLevels() {
      this.$store.commit('securityLevels/updateIndex', 0);
      this.$store.commit('securityLevels/toggle');
    },
  }
}
</script>

<style lang="scss" scoped>
.filter {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 10px;
  grid-template-areas: 
    "icon header"
    "icon rating"
    "content content"
  ;

  &:not(:first-child) {
    border-top: 1px solid $lightestgrey;
    padding-top: 16px;
  }

  &:not(:last-child) {
    padding-bottom: 16px;
  }

  &-icon {
    grid-area: icon;
  }

  &-header {
    display: flex;
    align-items: center;
    grid-area: header;
  }

  &-title {
    padding-top: 3px;
    font-size: rem(16px);
    line-height: 1.12;
    text-transform: uppercase;
  }

  &-info {
    margin-top: -1px;
    margin-left: 10px;
    cursor: pointer;

    .svg {
      fill: $mediumgreen;
      transition: fill .3 $ease-out-quart;
    }

    &:hover {
      .svg { fill: $darkblue; }
    }
  }

  &-rating {
    display: flex;
    grid-area: rating;
    margin-top: 4px;
    justify-self: flex-start;

    @for $i from 1 through 5 {
      &[data-rating="#{$i}"]:not(:hover) {
        .svg:nth-child(-n + #{$i}) {
          fill: $yellow;
        }
      }
    }

    &:hover {
      .svg {
        fill: $yellow;

        &:hover {
          ~ .svg {
            fill: rgba($grey, .5);
          }
        }
      }
    }

    .svg {
      fill: rgba($grey, .5);
      cursor: pointer;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-area: content;
    margin-top: 20px;

    &-item {
      position: relative;
      width: calc(50% - 10px);
      flex-shrink: 0;
      padding-left: 38px;
      padding-top: 4px;
      min-height: 21px;
      transition: color .3s $ease-out-quart;
      color: $darkblue;
      font-family: $secondary;
      font-weight: 500;
      font-size: rem(14px);
      line-height: 1.12;
      text-align: left;
      cursor: pointer;

      &:hover {
        color: $mediumgreen;
      }

      &:nth-child(n + 3) {
        margin-top: 10px;
      }

      &:nth-child(even) {
        margin-left: 20px;
      }

      &:nth-child(odd):last-child {
        width: 100%;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 0;
        width: 21px;
        height: 21px;
        border: 1px solid rgba($grey, .2);
        border-radius: 3px;
        transform: translateY(-50%);

        .svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>