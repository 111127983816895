<template>
    <div class="error-page">
      <h1>{{ $t('globals.error_occured') }}</h1>
      <p>{{ errorMessage }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      errorMessage: {
        type: String,
        required: true,
      },
    },
    created() {
    console.log('Error Message:', this.errorMessage);
  },
  };
  </script>
  
  <style scoped>
  .error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
  }
  </style>