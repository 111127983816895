<template>
  <div v-if="search" class="quicksearch">
    <input ref="input" type="search" :placeholder="search.placeholder" @input="onInput" @search="onClear">
  </div>
</template>

<script>
// import Fuse from 'fuse.js';
import _debounce from 'lodash/debounce';

export default {
  name: 'QuickSearch',
  data() {
    return {
      results: null,
    }
  },
  computed: {
    search() {
      return this.$store.state.layout.search;
    }
  },
  watch: {
    $route() {
      this.pleaseClear()
    },
  },
  methods: {
    onInput(evt) {
      const { value } = evt.target;
      this.$store.commit('siBookings/updateFilter', {
        value: 1,
        property: 'page'
      });
      this.debouncedUpdate(value);
    },
    debouncedUpdate: _debounce(function (value) {
      // this.$emit('search', value);
      this.$store.state.layout.search.onInput(value);
    }, 300),
    onClear() {
      if (this.$refs.input) {
        this.$refs.input.value = '';
      }
      // this.$emit('search', '');
      this.$store.state.layout.search.onInput('');
    },
    pleaseClear() {
      if (this.$refs.input) {
        this.$refs.input.value = '';
      }
    },
    // placeholder() {
    //   const { name } = this.$route;
    //   switch (name) {
    //     case ('Trucks'):
    //       return this.$t('components.quick_search.placeholders.trucks');
    //     case ('Drivers'):
    //       return this.$t('components.quick_search.placeholders.driver');
    //     default:
    //       return this.$t('components.quick_search.placeholders.default');
    //   }
    // }
  },
}
</script>

<style lang="scss" scoped>
.quicksearch {
  position: relative;

  &.onInput::after {
    opacity: 0;
  }

  &:after {
    content: '';

    width: 20px;
    height: 20px;

    display: block;

    position: absolute;
    top: 50%;
    right: 20px;
    background-color: $white;
    background-image: url('~@/assets/svg/search.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    transform: translateY(-50%);
    opacity: 1;
    pointer-events: none;

    transition: opacity .2s $ease-out-quart;
  }

  input {
    width: 280px;
    background: $white;
    color: $darkblue;
    border-radius: 8px;
    border: 1px solid $grey;
    padding: 11px 16px;

    @extend %p;

    &::placeholder {
      @extend %p;
      color: $darkblue;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      right: 0;

      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      /* background: $mediumgreen; */

      background-image: url('~@/assets/svg/cross.svg');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;

      cursor: pointer;
    }
  }
}

.quicksearch {
  +.main-container {
    padding-top: 0 !important;
  }
}
</style>