import axios from 'axios';

const getFleet = async (id) => {
  return await axios.get(`/fleet/company/`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}
const getUser = async () => {
  return await axios.get('/users/profile')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

export {
  getFleet,
  getUser
}