<template>
  <div
    :class="[
      'searchbar',
      { 'has-filters': filtersActive }
    ]">
    <div class="searchbar-form">
      <input 
        ref="input"
        :value="value"
        :type="'search'" 
        :required="true"
        :placeholder="$t('components.search_bar.placeholder')"
        :name="randomName()"
        :id="'search-bar'"
        class="searchbar-form-input"
        @input="onInput"
      />

      <ul
        v-if="places && places.length"
        class="searchbar-form-places"
      >
        <li 
          v-for="(place, index) in places"
          :key="index"
          class="searchbar-form-place" 
          @click="(evt) => onResultClick(evt, place)" 
        >
          {{ place.place_name }}
        </li>
      </ul>
      <button
        class="searchbar-form-submit"
        type="submit"
        @click.prevent="onClick" 
      >
        <Sprite 
          :type="'search'" 
          :width="23" 
          :height="23" 
        />
      </button>
    </div>

    <button
      class="searchbar-filter"
      @click="$emit('filter')"
    >
      <Sprite
        :type="'filters'"
        :width="22"
        :height="18"
      />
    </button>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';

import Sprite from '@/components/elements/Sprite.vue';

export default {
  components: {
    Sprite
  },
  data() {
    return {
      token: 'pk.eyJ1Ijoib3RyYW1hcGJveGFjY291bnQiLCJhIjoiY2tucHZtbjA1MTI0MzJubGF2MTA1OHA1ZiJ9.NP7_3MVerJ6Skc9fCSJ3fg',
      places: []
    }
  },
  computed: {
    value() {
      return this.$store.state.map.search;
    },
    filtersActive() {
      const { filters, type } = this.$store.state.map;

      if (!filters.length) return false;

      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];

        if (filter.id === 'range') {
          if (parseInt(filter.value, 10) !== 700) return true;
        } else {
          if (type !== 'electric') {
            for (let j = 0; j < filter.options.length; j++) {
              const option = filter.options[j];
              if (option.isActive) return true
            }
          }
        }
      }

      return false;
    }
  },
  methods: {
    onInput(e) {
      if(!e) return;
      const { target } = e;
      this.places = [];

      // this.search = target.value;
      // this.value = target.value
      this.$store.commit('map/update', { property: 'search', value: target.value });
      if(this.value.length > 2) this.debouncedUpdate();
    },
    onResultClick(evt, result) {
      this.$store.commit('map/update', { property: 'search', value: result.place_name || result.matching_place_name });
      this.places = [];

      this.$store.commit('map/update', { property: 'centerMarker', value: true });
      this.$store.commit('parkings/updateForm', { value: this.value,  property: 'place' });
      this.$store.commit('parkings/updateForm', { value: result.center,  property: 'coordinates' });
      this.$store.commit('parkings/updateForm', { property: 'coords', value: null });
      this.$store.commit('map/update', { property: 'center', value: { lat: result.center[1], lng: result.center[0], zoom: this.$store.state.map.center.zoom } });

      this.$store.dispatch('map/getParkingsType');
      this.$store.dispatch('map/getServices');
    },
    getPlaces() {
      // fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${this.token}&language=fr`)
      fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.value}.json?access_token=${this.token}&language=${this.$i18n.locale}`)
      .then(response => response.json())
      .then(data => {
        // this.data = data;
        this.places = data.features;
      })
      .catch(error => {
        console.log(error);
      });
    },
    randomName() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 7; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    debouncedUpdate: _debounce(function(payload) {
      this.getPlaces()
    }, 500),
  }
};
</script>

<style lang="scss" scoped>
.searchbar {
  display: flex;
  width: 100%;

  &.has-filters {
    .searchbar-filter {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 14px;
        right: 16px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $lightgreen;
      }
    }
  }

  // Form
  // -----------------------------------------------------------------------------
  &-form {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    position: relative;

    &-input {
      @extend %p;
      color: $darkblue;
      width: 100%;
      position: relative;
      border: 1px solid $lightestgrey;
      border-right: none;
      border-radius: 8px 0 0  8px;
      padding: 14px 0 14px 16px ;
      display: flex;
      align-items: center;
      &::placeholder {
        color: $darkblue;
      }
      &::-webkit-search-cancel-button{
        position:relative;
        right:0;  

        -webkit-appearance: none;
        width: 10px;
        height: 10px;
        /* background: $mediumgreen; */

        background-image: url('~@/assets/svg/cross.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        cursor: pointer;
      }
    }
    .svg-search {
      width: 59px;
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-places {
      background: $white;
      position: absolute;
      top: calc(100% + 5px);
      left: 0%;
      z-index: 2;

      width: 100%;

      border: 1px solid $lightestgrey;
      border-radius: 8px;
      padding: 14px 0 ;

      list-style: none;
    }

    &-place {
      @extend %p;
      padding: 5px 14px;
      color: $darkblue;
      background: transparent;
      cursor: pointer;
      transition: background .3s $ease-out-quad;

      &:hover {
        background: $grey-background;
      }
    }

    &-submit {
      width: 60px;
      flex-shrink: 0;
      border: 1px solid $lightestgrey;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      cursor: pointer;
    }

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 15px);
      right: 0;
      width: 1px;
      height: 30px;
      background-color: $lightestgrey;
    }
  }

  // Filters
  // -----------------------------------------------------------------------------
  &-filter {
    width: 60px;
    flex-shrink: 0;
    border: 1px solid $lightestgrey;
    border-left: 0;
    border-radius: 0 8px 8px 0;
    cursor: pointer;

    .svg {
      fill: $darkblue;
      transition: fill .3s $ease-out-quart;
    }

    &:hover,
    &:focus {
      .svg {
        fill: $mediumgreen;
      }
    }
  }
}
</style>
