<template>
  <div 
    class="input"
    :class="error ? 'error' : '' "
  >
    <input 
      ref="input"
      type="radio"
      :id="id"
      :name="name"
      :checked="checked"
      @input="updateInput"
    />
    <label :for="id" class="label small">{{ label }}</label>
    <strong 
      v-if="error"
      class="small error-msg"
    >
      <span>{{ error }}</span>
    </strong>
  </div>
</template>

<script>
export default {
  name: 'Radio',
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },  
    error: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: null
    },
    checked: { 
      type: Boolean,
      required: true
    },
  },
  methods: {
    updateInput() {
      this.$emit('onChange', this.id);
    }
  }
}
</script>


<style lang="scss" scoped>
input[type='radio'] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

input[type='radio'] ~ label {
  position: relative;

  display: flex;
  align-items: center;

  color: $darkblue;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
  }
  
  &::before {
    width: 16px;
    height: 16px;
    border: 4px solid $lightgrey;
    margin-right: 8px;
    /* background-color: $lightgrey; */
    /* margin-top: -4px; */
    // margin-top: -7px;
  }
  
  &::after {
    position: absolute;
    top: 50%;
    left: 3px;
    width: 10px;
    height: 10px;

    background: $mediumgreen;
    transform: translateY(-50%) scale(0);

    transition: transform 0.3s ease;
  }
}

input[type='radio']:checked ~ label::after {
  transform: translateY(-50%) scale(1);
}

.error-msg {
  display: block;
  color: $error;
  margin-top: 4px;

  height: 20px;
  opacity: 0;
  transition: opacity .3s $ease-out-quart;
  .error & {
    opacity: 1;
  }
}
</style>