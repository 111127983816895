<template>
    <section ref="container" class="main-container"
        :class="{ 'no-booking': bookings && bookings.length === 0, 'main-container--loading': loading }">
        <table v-if="bookings.length" class="table" :class="{ 'table-hasQr': hasQr }" cellspacing="0">
            <!-- TABLE HEAD -->
            <Thead :head="theadBooking" :filters="filters" @sort="sortTable" />

            <!-- TABLE BODY -->
            <tbody>
                <tr v-for="booking in bookings" :key="booking.id" :id="'book-' + booking.id">
                    <TBody v-for="(b, index) in tbodyBooking" :key="index" :body="b" :el="booking" />
                </tr>
            </tbody>
        </table>
        <!-- NO RESULTS -->
        <NoResult v-else>
            <slot>{{ $t('bookings.no_result') }}</slot>
        </NoResult>

        <div v-show="loading" class="main-container-loader">
            <loader />
        </div>

        <!-- Pagination and Page Size Selector -->
        <div v-if="bookings.length" class="pagination-container">
            <!-- Page Size Selector -->
            <!-- <label for="page-size">asadfs</label>
            <select id="page-size" v-model="pageSize" @change="updatePageSize">
                <option v-for="size in pageSizeOptions" :key="size" :value="size">{{ size }}</option>
            </select> -->

            <!-- Pagination Buttons -->
            <div class="pagination-buttons">
                <button v-for="page in totalPages" :key="page" @click="setPage(page)"
                    :class="{ active: currentPage === page }" class="pagination-button">
                    {{ page }}
                </button>
            </div>
        </div>

        <div class="booking-count">
            <p>{{ $t('bookings.total_data') }} : {{ this.$store.state.siBookings.bookings.totalOfBookings }} </p>
        </div>

        <!-- Remove Popup -->
        <popup v-if="popupCancel.visible" :classes="'popup-booking'">
            <template v-slot:content>
                <template v-if="popupCancel.success">
                    <h2 class="popup-title">{{ $t('bookings.popups.cancel.success.title') }}</h2>
                    <div class="popup-main">
                        <img class="popup-main-img" src="/img/success.png" />
                        <p class="popup-main-text">{{ $t('bookings.popups.cancel.success.text') }}</p>
                        <button class="button button-big" @click="closePopup('cancel')">
                            {{ $t('globals.ok_maj') }}
                        </button>
                    </div>
                </template>
                <template v-else>
                    <h2 class="popup-title">{{ $t('bookings.popups.cancel.title') }}</h2>

                    <div class="popup-main">
                        <p class="popup-main-text">{{ $t('bookings.popups.cancel.text') }}</p>
                    </div>

                    <div class="popup-actions">
                        <button type="button" class="button button-border" @click.prevent="closePopup('cancel')">
                            {{ $t('globals.close') }}
                        </button>
                        <button type="submit" class="button" @click="cancelBooking">
                            {{ $t('globals.cancel') }}
                        </button>
                    </div>
                </template>

                <div v-show="popupCancel.requests !== 0" class="popup-loader">
                    <loader />
                </div>
            </template>
        </popup>
    </section>
</template>

<script>
import { SourceEnum } from '../../helpers/snapEnums';
import { validateSnapBookingParams } from '../../helpers/snapvalidation';
import moment from 'moment';
import { formatDate } from '@/helpers/helpers';

import NoResult from '@/components/partials/Bookings/NoResult';
import Thead from '@/components/elements/Snap_THead';
import TBody from '@/components/elements/TBody';
import Loader from '@/components/elements/Loader';
import Popup from '@/components/partials/Popup';

import countries from "@/data/countries";
import { setLanguage } from '../../helpers/snaplanguage';

export default {
    name: 'List',
    components: {
        NoResult,
        Thead,
        TBody,
        Loader,
        Popup
    },
    data() {
        return {
            tmpBooking: [],
            currentList: [],
            hasQr: false,
            // message: this.$t('bookings.no_result'),
            tab: [],
            filtersArr: {},
            tbodyBooking: [
                // Select
                {
                    title: (booking) => {
                        if (booking.paymentStatus.toLowerCase() === 'pending' && (booking?.status && booking?.status?.toLowerCase() !== 'canceled')) {
                            return `<input ${this.selectedBooking.indexOf(booking.id) > -1 ? "checked" : ""} type="checkbox" value="${booking.id}" id="${booking.id}"><label for="${booking.id}"></label>`;
                        }
                    },
                    class: 'checkbox',
                    action: (booking) => this.onCheck(booking),
                },
                //ID
                {
                    title: (booking) => {
                        const { id } = booking;

                        return `<span class="legend small">${id}</span>`;

                    }
                },
                // Name
                // { title: (booking) => { 

                //     // if (booking.driverName) return `<span class="nickname">${booking.driverName}</span>`;
                //     // return `<span class="nickname">${this.$t('globals.no_name')}</span>`;
                //     return `<span class="nickname">${this.getDriverName(booking)}</span>`;

                //   } 
                // },
                // Type of freight & plate
                {
                    title: (booking) => {
                        const truckLicenseCountry = booking.truckLicenseCountry ? booking.truckLicenseCountry.trim() : null;
                        const truckLicensePlate = booking.truckLicensePlate ? booking.truckLicensePlate.trim() : null;

                        if ((!truckLicenseCountry && !truckLicensePlate) || (!truckLicenseCountry.length && !truckLicensePlate.length)) {
                            return `${this.setSlot(booking)}<br> -`;
                        }

                        return `
              ${this.setSlot(booking)}<br> ${truckLicenseCountry} ${truckLicensePlate}
            `;
                    }
                },

                // CHANGES
                // Nominal duration & price
                { title: (booking) => `${booking.packageDuration}${this.$t('globals.hours_short')}<br> ${booking.packagePrice}€` },
                // {  title: (booking) => `${booking.package.duration}${this.$t('globals.hours_short')}<br> ${booking.package.price}€` },
                //
                {
                    title: (booking) => {
                        if (!booking.priceDetails.extraDurationHours && !booking.priceDetails.extraDurationPriceWithoutFee) return '-';
                        if (!booking.priceDetails.extraDurationPriceWithoutFee) return `${booking.priceDetails.extraDurationHours}${this.$t('globals.hours_short')}<br> -`;
                        if (!booking.priceDetails.extraDurationHours) return `-<br> ${booking.priceDetails.extraDurationPriceWithoutFee}€`;
                        return `${booking.priceDetails.extraDurationHours}${this.$t('globals.hours_short')}<br> ${booking.priceDetails.extraDurationPriceWithoutFee}€`;
                    },
                    class: 'warning'
                },
                // Arrival Date & Time
                {
                    title: (booking) => {
                        const timezone = booking.parkingTimeZoneId;
                        const d = booking?.parkingIsIntegrated && booking.checkinAt ? formatDate(booking.checkinAt, timezone) : formatDate(booking.startAt, timezone);
                        return `${d.date}<br> <span class="legend small">${d.hoursDigits} ${this.$t(`globals.${d.hoursAmPm}`)}</span>`;

                    }
                },
                // Departure Date & Time
                {
                    title: (booking) => {
                        const timezone = booking.parkingTimeZoneId;

                        const d = booking?.parkingIsIntegrated && booking.checkoutAt ? formatDate(booking.checkoutAt, timezone) : formatDate(booking.endAt, timezone);
                        return `${d.date}<br> <span class="legend small3">${d.hoursDigits} ${this.$t(`globals.${d.hoursAmPm}`)}</span>`;
                    }
                },
                // Parking infos
                {
                    title: (booking) => {
                        if (booking.parkingInternationalName) {
                            let country = [...countries.filter(c => c.code.toLowerCase() === booking.parkingCountryCode.toLowerCase())];
                            if (country.length) country = country[0].name;
                            else country = booking.parkingCountryCode;

                            return `${booking.parkingInternationalName}<br> ${booking.parkingCity}${booking.parkingCountryCode ? ' - ' + country : ''}`;
                        }

                        // if(booking.parking) return `${booking.parking.internationalName}<br> ${booking.parking.address.city}${booking.parking.address.country ? ' - ' + booking.parking.address.country.name : ''}`;
                        // if(booking.package.parking) return `${booking.parkingInternationalName}<br> ${booking.package.parking.address.city}${booking.parkingCountryCode ? ' - ' + booking.parkingCountryCode : ''}`;
                        return '-';
                    }
                },
                // Payment status
                {
                    title: (booking) => {
                        const { paymentStatus } = booking;

                        if (!paymentStatus || paymentStatus === 'Undefined') return this.$t('bookings.labels.payment_status.fleet');

                        const statutes = {
                            pending: { classes: 'awaiting', label: this.$t('bookings.labels.payment_status.awaiting') },
                            rejected: { classes: 'decline', label: this.$t('bookings.labels.payment_status.declined') },
                            accepted: { classes: 'approved', label: this.$t('bookings.labels.payment_status.approved') },
                        }

                        const current = statutes[paymentStatus.toLowerCase()];

                        return `<span class="status no-hover ${current.classes}">${current.label}</span>`;
                    }
                },
                {
                    title: (booking) => {
                        const { referentialNumber } = booking;
                        const refNumber = referentialNumber == null || referentialNumber === '' ?
                            this.$t('bookings.labels.not_applicable') : referentialNumber;

                        return `<span class="legend small">${refNumber}</span>`;

                    }
                },
                // Status
                {
                    title: (booking) => {
                        const { status } = booking;

                        if (!status || status === 'Undefined') return '-';

                        const statutes = {
                            booked: { classes: 'approved', label: this.$t('bookings.labels.status.booked') },
                            canceled: { classes: 'decline', label: this.$t('bookings.labels.status.canceled') },
                            checkedin: { classes: 'approved', label: this.$t('bookings.labels.status.checked_in') },
                            checkedout: { classes: 'approved', label: this.$t('bookings.labels.status.checked_out') },
                            payed: { classes: 'approved', label: this.$t('bookings.labels.status.paid') },
                            noshow: { classes: 'decline', label: this.$t('bookings.labels.status.not_shown') },
                        };

                        const current = statutes[status.toLowerCase()];
                        return `<span class="status no-hover ${current.classes}">${current.label}</span>`;
                    }
                },
                // Actions
                {
                    title: (booking) => null,
                    class: 'td--buttons',
                    buttons: (booking) => {
                        const actions = [];

                        if (booking.status === 'Booked' && booking.paymentStatus === 'Undefined') {
                            actions.push({
                                label: this.$t('globals.cancel'),
                                id: booking.id,
                                classes: '',
                                action: (booking) => {
                                    this.cancelClick(booking);
                                }
                            });
                        }

                        if (booking.parkingIsIntegrated) {
                            actions.push({
                                label: this.$t('globals.pass'),
                                id: booking.id,
                                classes: 'button-icon',
                                icon: { type: 'qr', width: 24, height: 24 },
                                action: (booking) => {
                                    this.downloadQR(booking);
                                }
                            });
                        }

                        if (!booking.parkingIsIntegrated) {
                            actions.push({
                                label: this.$t('globals.pass'),
                                id: booking.id,
                                classes: 'button-icon',
                                icon: { type: 'pass', width: 30, height: 30 },
                                action: (booking) => {
                                    this.getInstructions(booking);
                                }
                            });
                        }

                        return actions;
                    }
                }
            ],
            // currentPage: 1,
            // pageSize: 10,
            // totalPages: this.$store.state.siBookings.bookings.totalOfBookings / this.$store.state.siBookings.filters.pageSize, // This will be calculated based on API response
            // pageSizeOptions: [5, 10, 25, 50], // Available page size options
            // totalCount: this.$store.state.siBookings.bookings.totalOfBookings, // Add totalCount to your data properties
        }
    },
    computed: {
        token() {
            return this.$store.getters['snapIntegration/token'];
        },
        // current() {
        //     return this.$store.state.parkings.current;
        // },
        bookings() {
            const bookings = this.$store.state.siBookings.bookings.bookingsInfos;
            if (!bookings || !Array.isArray(bookings)) {
                return [];
            }

            // bookings.filter(b => {
            //     b.slotValue = this.slots[b.slotId]
            //     // b.slotValue = this.slots[b.package.slotId]
            //     b.parking = this.parkings[`id-${b.parkingId}`]
            //     // b.parking = this.parkings[`id-${b.package.parkingId}`]
            //     return b;
            // });
            return bookings;
        },
        title() {
            return this.$route.meta.titleTemplate;
        },
        // slots() {
        //     return this.$store.state.parkings.slots;
        // },
        // parkings() {
        //     return this.$store.state.parkings.byId;
        // },
        filters() {
            return this.$store.state.siBookings.filters;
        },
        // opt() {
        //     return this.$store.state.user.opt;
        // },
        loading() {
            return this.$store.state.siBookings.requests !== 0;
        },
        status() {
            return this.$route.query.status;
        },
        query() {
            return this.$route.query;
        },
        popupCancel() {
            return this.$store.state.siBookings.popups.cancel
        },
        selectedBooking() {
            return this.$store.state.siBookings.selected;
        },
        theadBooking() {
            return [

                { title: this.$t('bookings.thead.checked'), class: "small  small-col head-hidden" },
                { title: 'Otra ' + this.$t('bookings.thead.id'), class: "small  small-col head", key: "id", sortable: true },
                //{ title: this.$t('bookings.thead.name'), class: "small head" },
                { title: this.$t('bookings.thead.type_license'), class: "small head" },
                { title: this.$t('bookings.thead.nominal'), class: "small head" },
                { title: this.$t('bookings.thead.extended'), class: "small head" },
                { title: this.$t('bookings.thead.arrival'), class: "small head" },
                { title: this.$t('bookings.thead.departure'), class: "small head" },
                { title: this.$t('bookings.thead.parking_infos'), class: "small head" },
                { title: this.$t('bookings.thead.payment_status'), class: "small head" },
                { title: this.$t('bookings.thead.reference_number'), class: "small head" },
                { title: this.$t('bookings.thead.status'), class: "small head" },
                { title: this.$t('bookings.thead.action'), class: "head-hidden" },
            ];
        },
        totalPages() {
            const totalOfBookings = this.$store.state.siBookings.bookings.totalOfBookings;
            const pageSize = this.$store.state.siBookings.filters.pageSize;

            // Make sure to handle division by zero, or invalid values if necessary
            return pageSize > 0 ? Math.ceil(totalOfBookings / pageSize) : 0;
        },
        currentPage() {
            return this.$store.state.siBookings.filters.page;
        },
        order() {
            return this.$store.state.siBookings.filters.order;
        },
    },
    watch: {
        // Refactor calls
        // query(to, from) {
        //   const { paymentStatus } = to;
        //   this.updatePaymentStatus(paymentStatus);
        //   this.$store.dispatch('bookings/setBookings');
        //   this.$store.commit('bookings/updateSelectedBooking', []);
        // },
        // currentList(arr) {
        //     this.$store.commit('siBookings/updateCurrentBookingsPaginate', arr.length);
        //     return arr;
        // },
        // opt() {
        //     this.handleView();
        // },
        loading() {
            if (this.$refs.container) {
                this.$refs.container.scrollTo(0, 0);
            }
        },
        selectedBooking(selected) {
            return selected
        },
        bookings() {
            this.setHasQr();
        }
    },
    created() {
        const query = this.$route.query;

        const lowerCaseQuery = Object.keys(query).reduce((acc, key) => {
            acc[key.toLowerCase()] = query[key];
            return acc;
        }, {});

        validateSnapBookingParams(lowerCaseQuery, 'listbooking');

        this.fleetId = lowerCaseQuery['fleetid'];
        this.clientId = lowerCaseQuery['clientid'];
        this.clientSecret = lowerCaseQuery['clientsecret'];
        this.language = lowerCaseQuery['language'];

        this.$store.dispatch('snapIntegration/fetchToken', { clientId: this.clientId, clientSecret: this.clientSecret, source:"listbooking", lang: lowerCaseQuery['language'] })
            .then(() => {
                const requestBody = {
                    validateBookingParams: {
                        fleetId: Number(this.fleetId),
                        languageCode: this.language
                    },
                    source: {
                        source: SourceEnum.LIST_BOOKING
                    }
                };
                this.$store.dispatch('snapIntegration/validateBooking', {item : requestBody, source :"listbooking"})
                    .then((bookingValidateResponse) => {
                        this.$store.commit('siBookings/updateFilter', {
                            value: this.fleetId,
                            property: 'fleetId'
                        });

                        // if (typeof paymentStatus === 'undefined') this.$store.commit('siBookings/updatePaymentStatus', null);
                        // else this.$store.commit('siBookings/updatePaymentStatus', paymentStatus);

                        // const { byId, slots } = this.$store.state.parkings;
                        // if (Array.isArray(byId) && !byId.length) this.$store.dispatch('siParkings/setParkings');
                        // if (Array.isArray(slots) && !slots.length) this.$store.dispatch('siParkings/setSlots');

                        if (this.$store.state.siBookings.requests === 0) {
                            this.$store.dispatch('siBookings/setBookings');
                            // if (this.$store.state.settings.isOwner) this.$store.dispatch('siBookings/getPlanners');
                        }

                        setLanguage(this.language, this.$store);
                    });
            })
            .catch((error) => {
                console.error('Error fetching token:', error);
            });
    },
    methods: {
        handleBeforeUnload() {
            this.$store.dispatch('snapIntegration/clearToken');
        },
        addTools() {
            this.$store.commit('layout/updateSearch', {
                placeholder: this.$t('components.quick_search.placeholders.snaplistbooking'),
                onInput: (value) => {
                    this.$store.commit('siBookings/updateSearch', value);
                    this.$store.dispatch('siBookings/setBookings', value);
                }
            });

            this.$store.commit('layout/updateDownload', {
                onClick: (evt) => {
                    evt.preventDefault();

                    this.$store.dispatch('siBookings/downloadReport');
                }
            });
        },
        setHasQr() {
            let hasQr = false;

            for (let i = 0; i < this.bookings.length; i++) {
                const b = this.bookings[i];
                if (b.parkingIsIntegrated) {
                    hasQr = true;
                    break;
                }
            }

            this.hasQr = hasQr;
        },
        updatePaymentStatus(status) {
            if (typeof status === 'undefined') {
                this.$store.commit('siBookings/updatePaymentStatus', null);
            } else {
                this.$store.commit('siBookings/updatePaymentStatus', status);
            }
        },
        setSlot(booking) {
            // booking.slotValue = this.slots[booking.slotId]
            // booking.slotValue = this.slots[booking.package.slotId]
            const trad = this.$t(`components.slot_type.${booking.slotValue}`);
            return trad;
            // return booking.slotValue;
        },
        handleView() {
            const from = (this.opt.page - 1) * this.opt.per;
            const to = this.opt.page * this.opt.per;
            // if(this.tmpBooking) this.tmpBooking;
            if (this.tmpBooking) this.tmpBooking = [...this.currentList].slice(from, to);
        },
        // On
        onCheck(booking) {
            const index = this.selectedBooking.indexOf(booking.id);
            index > -1 ? this.selectedBooking.splice(index, 1) : this.selectedBooking.push(booking.id)
            this.$store.commit('siBookings/updateSelectedBooking', this.selectedBooking);
        },
        onFilter(filters) {
            if (!this.bookings.length) return;

            if (filters.search) {
                this.tmpBooking = filters.search;
            } else this.tmpBooking = this[this.tab];

            this.tmpBooking = this.tmpBooking.filter(el => {
                const date = moment(el.createdAt).format('YYYYMMDD')
                if (filters.dates.start && filters.dates.end) return date >= filters.dates.start && date <= filters.dates.end;
                else return el;
            }).sort((a, b) => moment(a.createdAt).format('YYYYMMDDHHmm') - moment(b.createdAt).format('YYYYMMDDHHmm'))

            let end;
            const start = moment(filters.dates.start).format("DD[.]MM[.]YYYY");

            if (filters.dates.end && filters.dates.start != filters.dates.end) end = `${moment(filters.dates.end).format("DD[.]MM[.]YYYY")}`;
            else end = start;

            if (filters.dates.start != filters.dates.end) this.message = `No booking between ${start} and ${end}`
            else if (filters.dates.start) this.message = `No booking the ${start}`
            else this.message = `No booking`;
            // } 
            // Define message

            if (this.tmpBooking && !this.tmpBooking.length) this.message = `No booking`
            this.currentList = this.tmpBooking;

            this.handleView();
        },
        // Format
        dateFormat(date) {
            return moment(date).format("DD[.]MM[.]YYYY");
        },
        hourFormat(date) {
            return moment(date).format("HH:mm a");
        },
        downloadQR(booking) {
            this.$store.dispatch('siBookings/downloadQR', booking);
        },
        closePopup(popup) {
            this.$store.commit('siBookings/updatePopupProperty', {
                popup, property: 'visible', value: false
            });

            this.$store.commit('siBookings/updatePopupProperty', {
                popup, property: 'success', value: false
            });

            if (popup === 'cancel') {
                this.$store.commit('siBookings/updatePopupProperty', {
                    popup, property: 'booking', value: null
                });
            }
        },
        cancelClick(booking) {
            this.$store.commit('siBookings/updatePopupProperty', {
                popup: 'cancel', property: 'booking', value: { ...booking }
            });

            this.$store.commit('siBookings/updatePopupProperty', {
                popup: 'cancel', property: 'visible', value: true
            });
        },
        cancelBooking() {
            this.$store.dispatch('siBookings/cancelBooking');
        },
        getDriverName(booking) {

            // return driverName      
            if (booking?.driverNameCognito && booking?.driverNameCognito !== 'n.a.') {
                return booking?.driverNameCognito
            }

            return this.$t('globals.unknow')

        },
        getInstructions(booking) {
            this.$store.dispatch('siBookings/getInstructions', booking);
        },
        setPage(page) {
            if (page !== this.currentPage) {
                this.$store.commit('siBookings/updateFilter', {
                    value: page,
                    property: 'page'
                });
                this.currentPage = page;
                this.$store.dispatch('siBookings/setBookings');
            }
        },
        sortTable({ key, direction }) {
            this.$store.commit('siBookings/updateFilter', { value: key, property: 'sortColumn' });
            this.$store.commit('siBookings/updateFilter', { value: direction, property: 'sortDirection' });

            this.$store.dispatch('siBookings/setBookings');
        },
        // updatePageSize() {
        //     this.$store.commit('siBookings/updateFilter', {
        //         value: 2,
        //         property: 'pagesize'
        //     });
        //this.currentPage = 1; // Reset to the first page when page size changes
        // this.$store.dispatch('siBookings/setBookings');
        // },
    },
    beforeMount() {
        this.addTools();
    },
    beforeUnmount() {
        this.$store.commit('layout/updateSearch', null);
        this.$store.commit('layout/updateDownload', null);
    },
    mounted() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
};
</script>


<style lang="scss" scoped>
table {
    position: relative;
}

thead {
    position: relative;
    z-index: 3;
    background: $white;
}

tr {
    border-bottom: 1px solid $lightestgrey;
}

tbody {
    position: relative;
    z-index: 1;
}

th,
td {
    // padding-left: 12px;
    padding-right: 12px;
}

.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.pagination-buttons {
    display: flex;
    justify-content: center;
}

.pagination-button {
    margin: 0 5px;
    padding: 10px;
    cursor: pointer;
}

.pagination-button.active {
    font-weight: bold;
    border: 2px solid #000;
}

#page-size {
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;
}
</style>
