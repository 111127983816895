
<template>
  <td
    :class="[`${body.class}`, { 'td-inline': body.inlineButton && body.inlineButton(el) }]"
  >
    <template v-if="body.title(el) && body.title(el).length">
      <!-- v-if="!body.icon && !body.action && (body.inlineButton || !body.inlineButton(el))" -->
      <p 
        v-if="!body.icon && !body.action"
        v-html="body.title(el)"
      />
      <span 
        v-else-if="!body.icon && body.action"
        @change="body.action(el)"
        v-html="body.title(el)"
      />
      <span
        v-if="body.inlineButton && body.inlineButton(el)"        
        :class="body.inlineButton(el).classes === undefined ? 'button button-icon' : 'button button button-icon ' + body.inlineButton(el).classes" 
        @click="body.inlineButton(el).action(el)"        
      >
        <!-- {{ body.inlineButton }} -->
        <Sprite 
          :width="body.inlineButton(el).icon.width"
          :height="body.inlineButton(el).icon.height"
          :type="body.inlineButton(el).icon.type"
        />
      </span>
    </template>
    <Sprite 
      v-if="body.icon"
      :width="body.icon.width"
      :height="body.icon.height"
      :type="body.icon.type"
      @click="body.action(el)"
    />
    <template v-if="body.buttons">
      <button
        v-for="(butt, index) in body.buttons(el)"
        :key="`button-${index}-${butt.id}`"
        :class="`button ${butt.classes}`"
        @click="butt.action(el)"
        :disabled="butt.isDisabled === undefined ? false : butt.isDisabled"
      >
        <span :class="{'u-visually-hidden': butt.icon}">{{ butt.label }}</span>
        <Sprite 
          v-if="butt.icon"
          :width="butt.icon.width"
          :height="butt.icon.height"
          :type="butt.icon.type"
        />
      </button>
    </template>
  </td>
</template>

<script>

import Sprite from '@/components/elements/Sprite';

export default {
  name: 'TBody',
  components: {
    Sprite
  },
  props:{
    body: {
      type: [Array, Object]
    },
    el: {
      type: [Array, Object]
    }
  },
}
</script>

<style lang="scss">
  .svg {
    fill: $mediumgreen;
    vertical-align: middle;
    transition: fill .3s $ease-out-quart;
  }
  .icon {

    cursor: pointer;

    &:hover .svg{
      fill: $green;
    }
  }
  .svg-crossgray{
    cursor: default;
  }
  .button-icon-disabled{
    cursor: default;
  }
  .svg-accounting {
    transform: rotate(-90deg);
  }
  td {
    vertical-align: middle;
  }
  .nickname span {
    color: $darkblue;
  }
</style>
