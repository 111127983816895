<template>
  <div
    ref="container"
    :class="[
      'select',
      { 'select--open': open },
      { 'error': error }
    ]"
  >
    <label v-if="label" class="label">{{ label }}</label>
    <div 
      class="custom-select"
      :class="{
        'custom-select--open': open,
        'custom-select--search': hasSearch,
      }"
    >
      <strong 
        @click="onToggle"
        class="selected"
      > 
        {{ placeholderText }}
        <span class="selected-icon">
          <Sprite :type="'chevron'" />
        </span>
      </strong>
      <input
        v-show="hasSearch && open"
        ref="search"
        class="custom-select-search"
        type="text"
        :placeholder="$t('components.select.search')"
        :value="search"
        @input="onSearch"
      />
      <ul
        ref="ul"
        class="items"
      >
        <li 
          v-if="allowNull && (!search || !search.length)"
          :class="['item', { 'item--selected': !selected && !multiple }]"
          @click="onClick({ value: '' })"
        >
          {{ placeholder }}
        </li>
        <!-- v-for="option in options" -->
        <li
          v-for="option in displayedOptions"
          :key="option.value"
          :rel="option.value"
          :class="[
            'item',
            // { 'item--selected': selected && selected.value === option.value },
            { 'item--selected': (multiple && value.indexOf(option.value) >= 0) || (!multiple && value === option.value) },
            { 'item--disabled': option.disabled }
          ]"
          @click="() => onClick(option)"
        >
          {{ option.label ? option.label : option.value }}
          <small v-if="option.disabled && option.disabledLabel">{{ option.disabledLabel }}</small>
        </li>
        <li
          v-show="search && search.length && !displayedOptions.length"
          class="item item--no-result"
        >
          {{ $t('components.select.no_result') }}
        </li>
      </ul>
    </div>
    <select
      ref="input"
      :name="name"
      :required="required"
      :value="value"
      autocomplete="off"
      @change="onChange"
    >
      <option 
        v-if="allowNull"
        :selected="!selected"
        value=""
      >
        {{ placeholder }}
      </option>
      <option 
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="value === option.value"
      >
        {{ option.label ? option.label : option.value }}
      </option>
    </select>
    <strong 
      v-if="error"
      class="small error-msg"
    >
      <span>{{ error }}</span>
    </strong>
  </div>
</template>

<script>
/* eslint-disable */
import Sprite from './Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    hasSearch: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      search: null,
      _documentClick: this.onDocumentClick.bind(this)
    }
  },
  computed: {
    selected() {
      if (!this.value) return null;
      if (this.multiple) return null;
      const selected = this.options.filter(o => o.value === this.value);
      return selected[0];
    },
    selecteds() {
      if (!this.multiple) return [];
      const selecteds = this.options.filter(o => this.value.indexOf(o.value) >= 0);
      return selecteds;
    },
    placeholderText() {
      if (this.selected) {
        if (this.selected.label) return this.selected.label;
        return this.selected.value;
      }

      if (this.multiple) {
        if (this.selecteds.length) {
          return this.selecteds.map(s => s.label).join(', ');
        }
      }

      if (this.hasSearch) return this.$t('components.select.search');

      return this.placeholder;
    },
    displayedOptions() {
      if (!this.search || !this.search.length) return this.options;

      const options = this.options.filter(o => {
        if (o.label) {
          const lower = o.label.toLowerCase();
          const index = lower.indexOf(this.search);
          return index >= 0;
        }

        return false;
      });

      return options;
    }
  },
  methods: {
    onClick(option) {
      // this.open = false;
      // this.search = null;
      // document.removeEventListener('click', this._documentClick);
      // this.$emit('onChange', option.value);

      if (!this.multiple || (!option && this.multiple)) {
        this.open = false;
        document.removeEventListener('click', this._documentClick);
      }

      this.search = null;

      if (!this.multiple || (option && this.multiple)) {
        this.$emit('onChange', option ? option.value : null);
      }
    },
    onSearch(e) {
      const { target } = e;
      const { value } = target;
      this.search = value;
    },
    onToggle() {
      this.open = !this.open;
      this.search = null;

      if (this.open) {
        document.addEventListener('click', this._documentClick);

        if (this.hasSearch) {
          setTimeout(() => {
            this.$refs.search.focus();
          }, 100);
        }
      } else {
        document.removeEventListener('click', this._documentClick);
      }
    },
    onDocumentClick(e) {
      const { target } = e;
      const isDescendant = this.isDescendant(this.$refs.container, target);
      if (!isDescendant && this.open) {
        this.onToggle();
      }
    },
    isDescendant(parent, child) {
      let node = child.parentNode;
      while (node) {
        if (node === parent) return true;
        node = node.parentNode;
      }

      return false;
    }
  }
}
</script>