import axios from 'axios';
import qs from 'qs';

const getParking = (id) => {
  return axios.get(`/fleet/parkings/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};
const getParkings = (items) => {
  return axios.get(`/fleet/parkings`, {
    params: items,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      });
    }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};
const getNearest = (items) => {
  return axios.get(`/fleet/parkings/nearest`, {
    params: items
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const getFilters = () => {
  return axios.get(`/fleet/parkings/filters`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
}
const getSlots = () => {
  return axios.get(`/fleet/slots`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
}
const getCommodities = (id) => {
  return axios.get(`/fleet/parkings/${id}/commodities`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
}
const getMedia = (id) => {
  return axios.get(`/fleet/parkings/${id}/medias`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
}

const parkingsAvailableBookings = (id, options) => {
  return axios.get(`/fleet/parkings/${id}/periods?Slot=${options.Slot}&Duration=${options.Duration}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
}

const parkingsAvailableBookingsPerDate = (id, options) => {
  return axios.get(`/fleet/parkings/${id}/BookablePeriodsPerDate?Slot=${options.Slot}&Duration=${options.Duration}&FromDate=${options.Date}&ToDate=${options.Date}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
}

const getAvailableParkings = (items) => {
  return axios.get(`/fleet/parkings/parkingtypes`, {
    params: items,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      });
    }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

export {
  getParking,
  getParkings,
  getFilters,
  getSlots,
  getNearest,
  getCommodities,
  getMedia,
  parkingsAvailableBookings,
  parkingsAvailableBookingsPerDate,
  getAvailableParkings
}
