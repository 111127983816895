import i18n from '../i18n';
import moment from 'moment';

import {
  getServiceBookings
} from '@/calls/services';

const reservations = {
  namespaced: true,
  state: {
    items: [],
    current: null,
    serviceType: null,
    filters: {
      search: null,
      dates: {
        start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
    },
    requests: {
      list: 0,
      detail: 0
    }
  },
  mutations: {
    updateItems(state, items) {
      state.items = items;
    },
    updateCurrent(state, booking) {
      state.current = booking;
    },
    updateServiceType(state, type) {
      state.serviceType = type;
    },
    updateDynamicFilters(state, filters) {
      state.filters = filters;
    },
    increaseRequests(state, type) {
      state.requests[type]++;
    },
    decreaseRequests(state, type) {
      state.requests[type]--;
    }
  },
  actions: {
    async getReservation({ state, commit }, { id }) {
      commit('increaseRequests', 'detail');

      const request = await getReservation(id);

      if (!request.hasError) {
        commit('updateCurrent', request.data);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.reservation.getting'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests', 'detail');
    }, 
    async getReservations({ state, commit }) {
      commit('increaseRequests', 'list');

      const startAt = state.filters?.dates?.start;
      const endAt = state.filters?.dates?.end;
      const types = {
        tw: 1,
        tc: 2
      };

      const request = await getServiceBookings({
        type: state.serviceType ? types[state.serviceType] : null,
        startAt: startAt ? moment(startAt).format('YYYY-MM-DD[T]00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
        endAt: endAt ? moment(endAt).format('YYYY-MM-DD[T]23:59:59') : null 
      });

      if (!request.hasError) {
        const reservations = request.map(r => {
          try { r.description = JSON.parse(r.description); }
          catch (e) { r.description = null; }
          return r;
        });

        commit('updateItems', reservations);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.reservations.getting'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests', 'list');
    },
  }
};

export default reservations;
