<template>
  <div class="step-review">
    <div class="step-details-section">
      <!-- <h2 class="h4">{{ $t('steps.review.titles.details') }}</h2> -->
      <table cellspacing="0" class="table table-booknow__review">
        <tbody>
          <tr>
            <td>
              <div class="main-left__result result">
                <address class="result--left">
                  <h3 class="h4">{{ parking.internationalName || parking.localName }}</h3>
                  <p>{{ parking.address.street }} {{ parking.address.zipCode }} {{ parking.address.city }}, {{parking.address.country.name}}</p>
                </address>
              </div>
            </td>
            <td>
              <button
                v-if="parking?.securityLevel?.icon && parking.securityLevel.id !== 6"
                @click="openSecurityLevels"
                :class="'security-blazon security--'+parking?.securityLevel?.icon"
                type="button"
              >
                {{ parking?.securityLevel?.icon }}
              </button>
            </td>
          </tr>
          <tr>
            <td class="nickname">{{ $t('steps.review.labels.booking_date') }}</td>
            <td>{{ $t('steps.review.labels.now') }}</td>
          </tr>
          <tr>
            <td class="nickname">{{ $t('steps.review.labels.date') }}</td>
            <td>{{date}}</td>
          </tr>
          <tr>
            <td class="nickname">{{ $t('steps.review.labels.check_in') }}</td>
            <td>{{checkin}}</td>
          </tr>
          <tr>
            <td class="nickname">{{ $t('steps.review.labels.check_out') }}</td>
            <td>{{checkout}}</td>
          </tr>
          <tr>
            <td class="nickname">{{ $t('steps.review.labels.duration') }}</td>
            <td>{{ review?.duration }} {{review?.duration != "1" ? this.$t('globals.hours').toLowerCase() : this.$t('globals.hour').toLowerCase() }} </td>
          </tr>
          <!-- <tr>
            <td class="nickname">{{ $t('steps.review.labels.driver') }}</td>
            <td>{{ driver?.label }}</td>
          </tr> -->
          <tr>
            <td class="nickname">{{ $t('steps.review.labels.truck') }}</td>
            <td>{{ truck }}</td>
          </tr>
          <!-- <tr>
            <td class="nickname">{{ $t('steps.review.labels.date') }}</td>
            <td>{{date}}</td>
          </tr> -->
          <!-- <tr>
            <td class="nickname">{{ $t('steps.review.labels.check_in') }}</td>
            <td>{{checkin}}</td>
          </tr> -->
          <!-- <tr>
            <td class="nickname">{{ $t('steps.review.labels.check_out') }}</td>
            <td>{{checkout}}</td>
          </tr> -->
          <!-- <tr>
            <td class="nickname">{{ $t('steps.review.labels.place') }}</td>
            <td>{{ $t(`components.slot_type.${slotName}`) }}</td>
          </tr> -->
          <!-- <tr>
            <td class="nickname">{{ $t('steps.review.labels.duration') }}</td>
            <td>{{ review?.duration }} {{review?.duration != "1" ? this.$t('globals.hours').toLowerCase() : this.$t('globals.hour').toLowerCase() }} </td>
          </tr> -->
          <tr>
            <td class="nickname">{{ $t('steps.review.labels.place') }}</td>
            <td>{{ $t(`components.slot_type.${slotName}`) }}</td>
          </tr>
          <tr>
            <td
              class="nickname"
              v-html="$t('steps.review.labels.price')"
            />
            <td>{{review?.price}} €</td>
          </tr>
          <tr>
            <td
              class="nickname"
              v-html="$t('steps.review.labels.extension_price')"
            />
            <td>{{review?.extension}}€ / {{ $t('globals.hour') }}</td>
          </tr>

          <tr>
            <td class="nickname" v-html="$t('labels.booking_referential_number')" />
            <td>
              <div class="editable-field">
                <template v-if="isEditing">
                  <input
                    ref="editInput"
                    v-model="editableReferentialNumber"
                    @blur="disableEdit()"
                    @input="saveEdit($event)"
                    class="edit-input input-common"
                    maxlength="50"
                  />
                </template>
                <template v-else>
                  <span>{{ review?.referentialNumber }}</span>
                  <button @click="toggleEdit" class="edit-btn">✏️</button>
                </template>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <!-- <div class="step-details-section step-details-section--flex"> -->
      <!-- <div class="step-detail-section-item"> -->
      <!-- <div class="step-details-section">
        <h2 class="h4">{{ $t('steps.review.titles.insurance') }}</h2>
        <table cellspacing="0" class="table table-booknow__review">
          <tbody>
            <tr>
              <td class="nickname">{{ $t('steps.review.labels.flex') }}</td>
              <td class="insurance">
                <sprite
                  v-if="isFlex"
                  :type="'check'"
                  :width="26"
                  :height="18"
                />
                <template v-else>
                  <sprite
                    :type="'cross'"
                    :width="26"
                    :height="18"
                  />
                  <p v-html="$t('steps.review.labels.no-flex', { email: 'sales@weareotra.com' })"/>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

      <!-- <div class="step-detail-section-item"> -->
      <div class="step-details-section">
        <h2 class="h4">{{ $t('steps.review.titles.total') }}</h2>
        <table cellspacing="0" class="table table-booknow__review">
          <tbody>
            <tr>
              <td 
                class="nickname"
                v-html="$t('steps.review.labels.total')"
              />
              <td>
                {{review?.price }} €
                <!-- {{review?.price + flexPrice }} € -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    <!-- </div> -->

    <!-- <div class="step-details-section">
    </div> -->
    <p
      v-if="parking && parking.translations && parking.translations[0] 
      && (parking.translations[0].rules || parking.translations[0].rulesHTML)"
      class="step-details-baseline"
      v-html="$t('steps.review.baseline')"
      @click="onRules"
    />
    <button class="button button-big" type="submit" @click="submitBook">
      {{ $t('steps.review.button') }}
    </button>

    <div
        v-show="loader"
        class="popup-loader"
      >
        <Loader/>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { formatDate } from '@/helpers/helpers';

// import Sprite from '@/components/elements/Sprite';
import Loader from '@/components/elements/Loader';
export default {
  name: 'StepReview',
  components: {
    // Sprite,
    Loader
  },
  data() {
    return {
      isEditing: false,
      editableReferentialNumber: this.$store.state.siParkings.form.referentialNumber,
      flexPrice: 5,
      loader: false
    };
  },
  computed: {
    driver() {
      const filtereds = this.$store.state.siParkings.drivers.filter(driver => ((driver.lastName && driver.firstName) || driver.nickname || driver.fullName) && driver.driverId === this.$store.state.siParkings.form.driver );
      const mapeds = filtereds.map(driver => {
        let label = driver.fullName;

        if (!label || !label.length) label = driver.lastName && driver.firstName ? `${driver.firstName} ${driver.lastName}`: driver.nickname;

        return {
          value: driver.id,
          label
        };
      });

      const shifted = mapeds.shift();
      return shifted;
    },
    truck() {
      return this.$store.state.siParkings.form.licensePlate
    },
    date() {
      const day = moment(this.$store.state.siParkings.form.startAt).format('DD');
      const month = this.$t(`components.calendar.months.${moment(this.$store.state.siParkings.form.startAt).format('M')}`);
      // const month = this.$t(`globals.months.${moment(this.$store.state.parkings.form.startAt).format('M')}`);
      const year = moment(this.$store.state.siParkings.form.startAt).format('YYYY');
      return `${day} ${month}, ${year}`;
      // return moment(this.$store.state.parkings.form.startAt).format('DD MMMM, YYYY');
    },
    checkin() {
      const d = formatDate(this.$store.state.siParkings.form.startAt, this.$store.state.siParkings.details.parking.timeZoneId, null, 'HH:mm a');
      return d.hoursDigits + ' ' + this.$t(`globals.${d.hoursAmPm}`);
    },
    checkout() {
      const date = this.$store.state.siParkings.form.startAt;
      const match = date.match(/(\+|\-)[0-9]{2}:[0-9]{2}$/g);
      const dateString = match.length > 0 ? date.replace(match[0], '') : date;
      const timezone = this.$store.state.siParkings.details.parking.timeZoneId;
      const d = moment.tz(dateString, timezone).add(this.$store.state.siParkings.form.duration, 'h');
      return d.format('HH:mm') + ' ' +  this.$t(`globals.${d.format('a')}`);
      return moment(this.$store.state.siParkings.form.startAt).add(this.$store.state.siParkings.form.duration, 'h').format('HH:mm a');
    },
    review() {
      return this.$store.state.siParkings.form;
    },
    parking() {
      return this.$store.state.siParkings.details.parking;
    },
    isFlex() {
      return this.$store.state.fleet.current?.isFlex;
    },
    slotName() {
      const slotId = this.$store.state.siParkings.form.slot - 1;
      const slots = this.$store.state.siParkings.details.slots;
      return slots[slotId].icon;
    },
  },
  // data() {
  //   return {
  //     flexPrice: 5,
  //   }
  // },
  beforeMount() {
    // this.$store.dispatch('trucks/getTruck', this.$store.state.siParkings.form.truck)
    // this.$store.dispatch('fleet/getFlex');
  },
  methods: {
    onChecked({value}) {
      this.flexPrice = value ? 5 : 0
    },
    submitBook() {
      this.loader = true;
      const item = {
        driverId: this.review.driver,
        truckId: this.review.truck,
        packageId: this.review.package,
        startAt: this.review.startAt,
        referentialNumber: this.review.referentialNumber,
        snapFleetEmail: this.$store.state.siParkings.form.email,
        fleetId: this.$store.state.siParkings.form.fleetId
      }
      this.$store.dispatch('siBookings/sendBooking', item);
      // this.loader = false;
    },
    // openSecurityLevels() {
    //   const index = this.parking.securityLevel ? 4 - this.parking.securityLevel.id : 0;
    //   this.$store.commit('securityLevels/updateIndex', index);
    //   this.$store.commit('securityLevels/toggle');
    // },
    onRules() {
      const { translations } = this.parking;
      this.$store.commit('popups/updatePopup', { popup: 'rules', property: 'value', value: translations[0].rules });
      this.$store.commit('popups/updatePopup', { popup: 'rules', property: 'visible', value: true });

      this.$store.commit('popups/updatePopup', { popup: 'rulesHTML', property: 'value', value: translations[0].rulesHTML });
      this.$store.commit('popups/updatePopup', { popup: 'rulesHTML', property: 'visible', value: true });

    },
    toggleEdit() {
      this.isEditing = true;
      this.editableReferentialNumber = this.review.referentialNumber;
      this.$nextTick(() => {
        this.$refs.editInput?.focus();
      });
    },
    disableEdit(e) {
      this.isEditing = false;
    },
    saveEdit(e) {
      // this.isEditing = false;
      // this.$store.commit('siParkings/updateReferentialNumber', this.editableReferentialNumber);
      if (!e) return;
      const { target } = e;
      const { value } = target;

      this.$store.commit('siParkings/updateForm', { value, property : 'referentialNumber' });
    },
  }
}
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 20px;
    border: 1px solid $lightestgrey;
    border-top: 0px solid $lightestgrey;
    border-bottom: 0px solid $lightestgrey;

    td {
      padding-top: 15px;
      padding-bottom: 15px;

      &:nth-child(3) {
        padding-left: 43px;
      }
    }
  }

  .security-blazon {
    display: inline-block;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 12px 9px;
    border: 1px solid $mediumgreen;
    border-radius: 8px;
    background-color: $white;
    color: $mediumgreen;
    font-family: $primary;
    font-weight: 600;
    font-style: italic;
    font-size: rem(14px);
    text-transform: uppercase;
    line-height: 1;
    transition: color .3s $ease-out-quart, background-color .3s $ease-out-quart;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $mediumgreen;
    }
  }

  .step-details-section {
    &:not(:first-child) {
      margin-top: 34px;
    }

    + .button {
      margin-top: 30px;
    }

    &.step-details-section--flex {
      display: flex;
      justify-content: space-between;
      
      .step-detail-section-item {
        width: calc(50% - 15px);

        /* &:first-child {
          width: calc(50% - 30px);
        } */
      }
    }
  }

  .step-details-baseline {
    margin-top: 24px;
    margin-bottom: 32px;
    color: $darkblue;
    font-family: $secondary;
    font-size: rem(18px);
    font-weight: 500;
  }

  .editable-field {
  display: flex;
  align-items: center;
}

.edit-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.edit-input {
  border: 1px solid #ddd;
  padding: 3px;
  width: 50%;
}
</style>

<style lang="scss">
.step-details-baseline {
  u {
    cursor: pointer;
    color: $darkblue;
    font-family: $secondary;
    font-size: rem(18px);
    font-weight: 500;
    text-decoration: underline;
    transition: color .3s $ease-out-quart;

    &:hover {
      color: $mediumgreen;
    }
  }
}
</style>