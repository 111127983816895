import i18n from '../i18n';
// import { useI18n } from 'vue-i18n';
import {
  getBooking,
  getBookings,
  newBooking,
  acceptBooking,
  confirmBooking,
  rejectBooking,
  cancelBooking,
  getQR,
  getParkingInstruction
} from '@/calls/bookings';
import { getPlanners } from '@/calls/planners';
import { getBalanceReport } from '@/calls/report';

import moment from 'moment';

const bookings = {
  namespaced: true,
  state: {
    currentBookingList: [],
    currentBookingPaginate: [],
    currentTab: null,
    bookings: [],
    filters: {
      dates: {
        start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      search: null
    },
    status: null,
    paymentStatus: null,
    statutes: {
      awaiting: 0, // 'undefined' in API
      approved: 10, // 'booked' in API
      decline: 20, // 'canceled' in API
    },
    statusChange: {},
    selected: [],
    search: null,
    download: null,
    requests: 0,
    requestsCreate: 0,
    planners: [],
    selectedPlanner: '',
    qr: {
      src: ''
    },
    popups: {
      cancel: {
        booking: null,
        visible: false,
        requests: 0,
        success: false
      }
    }
  },
  mutations: {
    updatePopupProperty(state, { popup, property, value }) {
      state.popups[popup][property] = value;
    },
    updateSearch(state, search) {
      state.search = search;
    },
    updateDownload(state, download) {
      state.download = download;
    },
    updatePaymentStatus(state, status) {
      state.paymentStatus = status;
    },
    updateStatus(state, status) {
      state.status = status;
    },
    updateStatusChange(state, statusChange) {
      state.statusChange = statusChange;
    },
    updateCurrent(state, booking) {
      state.current = booking;
    },
    updateBookings(state, bookings) {
      state.bookings = bookings;
    },
    updateSelectedBooking(state, selected) {
      state.selected = selected;
    },
    updateCurrentBookingList(state, currentBookingList) {
      state.currentBookingList = currentBookingList;
    },
    updateCurrentBookingsPaginate(state, currentBookingPaginate) {
      state.currentBookingPaginate = currentBookingPaginate;
    },
    updateDynamicFilters(state, filters) {
      state.filters = filters;
    },
    updatePlanners(state, planners) {
      state.planners = planners;
    },
    updateSelectedPlanner(state, planner) {
      state.selectedPlanner = planner;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
    increaseRequestsCreate(state) {
      state.requestsCreate++;
    },
    decreaseRequestsCreate(state) {
      state.requestsCreate--;
    }
  },
  actions: {
    setBooking({ commit }, id) {
      const booking = getBooking(id);
      booking.then((promise) => {
        return commit('updateBookings', promise);
      })
    },
    setStatus({commit}, ids) {
      const bookingsUpdated = this.state.bookings.bookings.map( b => {
        if(ids.indexOf(b) > -1) return ids[ids.indexOf(b)];
        else return b;
      });
      return commit('updateBookings', bookingsUpdated);
    },
    async setBookings({ commit, state, rootState }, params) {
      const fleetId = rootState?.fleet?.current?.id;
      if (!fleetId) { return; }

      commit('increaseRequests');
      const { isOwner, context } = rootState.settings;

      let plannerId = null;
      if (!context.isAdmin) plannerId = isOwner ? state.selectedPlanner : context.fleetPlanner.id;

      const start = state.filters?.dates?.start;
      const end = state.filters?.dates?.end;
      const { status, search, paymentStatus } = state;

      // REAL CALL
      const booking = await getBookings({
        fleetId,
        startAt: start ? moment(start).format('YYYY-MM-DD[T]00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
        endAt: end ? moment(end).format('YYYY-MM-DD[T]23:59:59') : null,
        fleetPlannerId: plannerId ? plannerId : null,
        search, status
      });

      if (paymentStatus) {
        const data = booking.filter(b => {
          if (paymentStatus === 'pending') {
            return b.paymentStatus.toLowerCase() === paymentStatus.toLowerCase() && b.status.toLowerCase() !== 'canceled';
          }

          return b.paymentStatus.toLowerCase() === paymentStatus.toLowerCase();
        });
        commit('updateBookings', data);
      } else {
        commit('updateBookings', booking);
      }

      commit('decreaseRequests');

      return true;
      return booking.then((promise) => {
        commit('updateBookings', promise);
        commit('decreaseRequests');
        return promise;
      });
      
    },
    byDriver({ commit }, id) {
      const booking = getBookings({'DriverId': id});
      return booking.then((promise) => {
        commit('updateBookings', promise);
        return promise;
      });
    },
    async sendBooking({ commit, dispatch, rootState }, item) {
      commit('increaseRequestsCreate');
      const booking = await newBooking(item);
      const { t } = i18n.global;

      if (!booking.hasError) {
        const { id } = booking;
        const { isIntegrated, instructions, rules } = booking.parking;
        const { hasAccount } = booking.driver;
        commit('popups/updatePopup', { popup: 'booked', property: 'id', value: id }, { root: true });
        commit('popups/updatePopup', { popup: 'booked', property: 'warning', value: !hasAccount }, { root: true });
        commit('popups/updatePopup', { popup: 'booked', property: 'rules', value: rules }, { root: true });
        commit('popups/updatePopup', { popup: 'booked', property: 'instructions', value: instructions }, { root: true });
        if (isIntegrated) {
          const qr = await getQR(id);
          if (qr && !qr.hasError) {
            const href = qr;
            const qrObj = {
              href: `data:application/pdf;base64,${href}`,
              name: `Booking-${id}.pdf`
            };
            commit('popups/updatePopup', { popup: 'booked', property: 'qr', value: qrObj }, { root: true });
          }
        }
        commit('popups/toggle', 'booked', { root: true });
        // commit('updateBookings', booking);
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.booking.creation', { error: booking.error.message ? booking.error.message : booking.error }),
          type: 'error',
        }], { root: true });
      }

      commit('decreaseRequestsCreate');
    },
    async acceptBookings({ commit, state, dispatch }) {
      commit('increaseRequests');
      const errors = [];
      const { tc } = i18n.global;

      for (let i = 0; i < state.selected.length; i++) {
        const selected = state.selected[i];
        const accept = await acceptBooking(selected);

        if (accept.hasError) {
          errors.push(accept.error);
        }
      }

      if (errors.length) {
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.errors.booking.accept', errors.length, { errors: errors.join('<br>') }),
          type: 'error'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.success.booking.accept', state.selected.length),
          type: 'success'
        }], { root: true });
        commit('updateSelectedBooking', []);
        dispatch('setBookings');
      }

      commit('decreaseRequests');
    },
    async rejectBookings({ commit, state, dispatch }) {
      commit('increaseRequests');
      const errors = [];
      const { tc } = i18n.global;

      for (let i = 0; i < state.selected.length; i++) {
        const selected = state.selected[i];
        const reject = await rejectBooking(selected);
        if (reject.hasError) {
          errors.push(reject.error);
        }
      }

      if (errors.length) {
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.errors.booking.reject', errors.length, { errors: errors.join('<br>') }),
          type: 'error'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: tc('notifications.success.booking.reject', state.selected.length),
          type: 'success'
        }], { root: true });
        commit('updateSelectedBooking', []);
        dispatch('setBookings');
      }

      commit('decreaseRequests');
    },
    async cancelBooking({ commit, state, dispatch }) {
      const { booking } = state.popups.cancel;
      const { t } = i18n.global;
      if (!booking) return;

      commit('updatePopupProperty', {
        popup: 'cancel',
        property: 'requests',
        value: state.popups.cancel.requests + 1
      });

      const cancel = await cancelBooking(booking.id);

      if (cancel.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.booking.cancel', { errors: cancel.error }),
          type: 'error'
        }], { root: true });
      } else {
        commit('updatePopupProperty', {
          popup: 'cancel',
          property: 'success',
          value: true
        });

        dispatch('setBookings');
      }

      commit('updatePopupProperty', {
        popup: 'cancel',
        property: 'requests',
        value: state.popups.cancel.requests - 1
      });
    },
    async getPlanners({ commit, state, dispatch }) {
      commit('increaseRequests');

      const request = await getPlanners();

      if (!request.hasError) {
        const planners = request.filter(p => p.user && (p.user.firstname || p.user.lastname)).map(p => {
          return {
            value: p.id,
            label: p.user.firstname && p.user.lastname ? `${p.user.firstname} ${p.user.lastname}` : '-'
          }
        });
        // planners.push({
        //   value: '555',
        //   label: 'Testing La Niche'
        // });
        // planners.push({
        //   value: '345',
        //   label: 'Smoky Testing'
        // });

        commit('updatePlanners', planners);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.planners.list', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async downloadQR({ commit, state, dispatch }, booking) {
      const { id } = booking;
      const request = await getQR(id);
      console.log(request);
      if (!request.hasError) {
        const src = request;
        const a = document.createElement('a');
        a.href='data:application/pdf;base64,' + src;
        a.setAttribute('download', `Booking-${id}.pdf`);
        a.click();
        // window.location.href = 'data:application/octet-stream;base64,' + request;
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.booking.qr', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }
    },
    async downloadReport({ commit, dispatch }) {
      commit('increaseRequests');

      const response = await getBalanceReport();

      if (!response.hasError) {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `activity-balance-report-${new Date().getTime()}.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.booking.report', { error: response.error }),
          type: 'error'
        }], { root: true });
      }
      
      commit('decreaseRequests');
    },
    async getInstructions({ commit, state, dispatch }, booking) {

      commit('increaseRequests');

      const { parkingId } = booking;
      const request = await getParkingInstruction(parkingId);      
      const { t } = i18n.global;

      if (!request?.hasError) {
        dispatch('notifications/addNotifs', [{
          text: !request || request.length === 0 ? t('notifications.default_instructions') : request,
          type: 'instructions'
        }], { root: true });
      } else {
        
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.booking.qr', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');

    },

  }
};

export default bookings;
