import { redirectFailed } from "./redirectHelper";

export function validateSnapBookingParams(query, source) {
  const bookingParams = Object.freeze(['parkingId', 'day_of_booking', 'license_plate', 'fleetId', 'email', 'clientId', 'clientSecret']);
  const listBookingParams = Object.freeze(['fleetId', 'clientId', 'clientSecret']);

  let requiredParams;

  if (source === 'listbooking') {
    requiredParams = listBookingParams;
  } else {
    requiredParams = bookingParams;
  }
  
  let errorCodes = [];

  const lowerCaseQuery = Object.keys(query).reduce((acc, key) => {
    acc[key.toLowerCase()] = query[key];
    return acc;
  }, {});

  requiredParams.forEach(param => {
    const lowerCaseParam = param.toLowerCase();
    if (!(lowerCaseParam in lowerCaseQuery)) {
      errorCodes.push(`Missing${param}`);
    } else if (!lowerCaseQuery[lowerCaseParam]) {
      errorCodes.push(`Empty${param}`);
    }
  });

  if (errorCodes.length > 0) {
    if (source === 'listbooking') {
      let lang = lowerCaseQuery['language'] ? lowerCaseQuery['language'] : "en";
      window.location.href = `/snaperror?error=${encodeURIComponent(errorCodes.join(', '))}&language=${lang}`;
    } else {
      redirectFailed(errorCodes.join(','))
    }    
  }
}
