<template>
  <aside 
    class="modal"
    :class="isVisible ? 'modal--visible' : ''"
  >
    <div class="modal-container">
      <div class="abbr">{{abbr}}</div>
      <h3 class="h4">
        <strong v-if="nickname"> {{nickname}} </strong>
        —
        <strong v-if="fleetname"> {{fleetname}}</strong>
      </h3>
      <a class="h4 phone" href="tel:{{phone}}">{{phone}}</a>

    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        class="button button-border"
        @click="$emit('close')"
      >
        {{ $t('globals.close') }}
      </button>
      <button type="button" class="button ">{{ $t('globals.contact') }}</button>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    driver: {
      type: Object,
    },
    fleet: {
      type: [Object, Boolean, String],
      default: null
    },
  },
  data() {
    return {
      abbr: '',
      phone: '',
      nickname: '',
      fleetname: '',
    }
  },
  computed: {
    fleetNameFromProps() {
      if (this.fleet) return this.fleet;
      return this.$t('components.modal.freelance');
    }
  },
  watch: {
    driver(driver) {
      this.abbr = `${driver.firstname.charAt(0) + driver.lastname.charAt(0)}`
      this.nickname = driver.firstname;
      this.phone = driver.mobile;
    },
    fleetNameFromProps(fleet) {
      if(fleet) this.fleetname = fleet.name;
      else this.fleetname = this.$t('components.modal.freelance');
    },
    // fleet(fleet) {
    //   if(fleet) this.fleetname = fleet.name;
    //   else this.fleetname = " Freelance";
    // }
  }
}
</script>
