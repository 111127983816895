import {
  addDriver,
  checkDriverAccount,
  linkDriverAccount,
  createDriverNoAccount
} from '@/calls/drivers';

import {
  send,
  getQR
} from '@/calls/bookings';

import {
  addFavorite,
  deleteFavorite
} from '@/calls/favorites';

import {
  getServiceBooking,
} from '@/calls/services';

import i18n from '../i18n';

const popups = {
  namespaced: true,
  state: {
    newDriver: {
      visible: false,
      requests: 0,
      success: false,
      form: {
        phone: '',
        fullname: '',
        locale: 'en'
      },
      errors: {
        phone: '',
        fullname: '',
        locale: 'en'
      }
    },
    linkDriver: {
      visible: false,
      requests: 0,
      success: false,
      account: null,
      updateFleet: false,
      message: '',
      fleetsName: ''
    },
    booked: {
      visible: false,
      requests: 0,
      id: null,
      warning: false,
      qr: null,
      instructions: null,
      rule: null,
      form: {
        phone: '',
        email: '',
      },
      errors: {
        phone: '',
        email: '',
      }
    },
    rules: {
      visible: false,
      requests: 0,
      value: null
    },
    rulesHTML: {
      visible: false,
      requests: 0,
      value: null
    },
    newFavorite: {
      visible: false,
      requests: 0,
      form: {
        name: '',
        type: 'search',
        serviceType: 'parkings',
        place: null,
        map: null,
        filters: [],
        route: {
          waypoints: [],
          params: {}
        }
      }
    },
    deleteFavorite: {
      visible: false,
      requests: 0,
      favorite: null
    },
    reservation: {
      visible: false,
      id: null,
      item: null,
      requests: 0
    }
  },
  mutations: {
    toggle(state, popup) {
      state[popup].visible = !state[popup].visible;
    },
    updatePopup(state, { popup, property, value }) {
      state[popup][property] = value;
    },
    updatePopupForm(state, { popup, field, value }) {
      state[popup].form[field] = value;
    },
    updatePopupError(state, { popup, field, value }) {
      state[popup].errors[field] = value;
    },
    resetPopupForm(state, popup) {
      for (const key in state[popup].form) {
        if (state[popup].form.hasOwnProperty(key)) {
          state[popup].form[key] = null;
        }
      }
    },
    resetPopupErrors(state, popup) {
      for (const key in state[popup].errors) {
        if (state[popup].errors.hasOwnProperty(key)) {
          state[popup].errors[key] = null;
        }
      }
    },
    increaseRequests(state, popup) {
      state[popup].requests++;
    },
    decreaseRequests(state, popup) {
      state[popup].requests--;
    }
  },
  actions: {
    // New driver
    driverCreationDispatch({ state, dispatch}) {
      
      const { phone } = state.newDriver.form;

      if (!phone) {
        dispatch('createDriverWithFullname');
        return;
      }

      dispatch('checkDriverWithPhone');
    },
    // New driver with only fullname
    async createDriverWithFullname({ state, commit, dispatch }) {
      commit('updatePopup', { popup: 'newDriver', property: 'requests', value: state.newDriver.requests + 1 });

      const { fullname } = state.newDriver.form;
      const request = await createDriverNoAccount({ fullname });

      if (!request.hasError) {
        dispatch('drivers/getDrivers', null, { root: true });
        commit('updatePopup', { popup: 'newDriver', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.drivers.add', { error: driver.error.message ? driver.error.message : driver.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { popup: 'newDriver', property: 'requests', value: state.newDriver.requests - 1 });
    },
    // New driver with phone
    async checkDriverWithPhone({ state, commit, dispatch }) {
      commit('updatePopup', { popup: 'newDriver', property: 'requests', value: state.newDriver.requests + 1 });

      const { t } = i18n.global;
      const { phone } = state.newDriver.form;
      const request = await checkDriverAccount({ phoneNumber: phone });

      if (!request.hasError) {
        const { data } = request;
        if (data.linkable) {
          
          if (!data.driver) {
            // Show full form for creation
            dispatch('createDriverWithPhone');
          } else {
            // Show linkDriver popup
            commit('toggle', 'linkDriver');
            commit('updatePopup', { popup: 'linkDriver', property: 'account', value: data.driver });

            if (data.updateFleet)
            {
              commit('updatePopup', { popup: 'linkDriver', property: 'updateFleet', value: data.updateFleet });
              commit('updatePopup', { popup: 'linkDriver', property: 'message', value: data.message });
              commit('updatePopup', { popup: 'linkDriver', property: 'fleetsName', value: data.fleetsName });
            }

            // Reset newDriver popup
            commit('updatePopup', { popup: 'newDriver', property: 'visible', value: false });
            commit('updatePopup', { popup: 'newDriver', property: 'success', value: false });
          }
        } else {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.drivers.checking', { error: data.message }),
            type: 'error'
          }], { root: true });
        }
      } else {
        const { error } = request;
        const { message, detail } = error;
        const err = detail ? detail : message;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.drivers.checking', { error: err ? err : error}),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { popup: 'newDriver', property: 'requests', value: state.newDriver.requests - 1 });
    },
    async createDriverWithPhone({ state, commit, dispatch }) {
      commit('updatePopup', { popup: 'newDriver', property: 'requests', value: state.newDriver.requests + 1 });

      const { phone, fullname, locale } = state.newDriver.form;
      let driver = await addDriver({ phoneNumber: phone, fullName: fullname, locale: locale ? locale : 'en' });
      const { t } = i18n.global;

      if (!driver.hasError) {
        dispatch('drivers/getDrivers', null, { root: true });
        commit('updatePopup', { popup: 'newDriver', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.drivers.add', { error: driver.error.message ? driver.error.message : driver.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { popup: 'newDriver', property: 'requests', value: state.newDriver.requests - 1 });
    },
    // Link driver
    async linkDriverWithPhone({ state, commit, dispatch }) {
      commit('updatePopup', { popup: 'linkDriver', property: 'requests', value: state.linkDriver.requests + 1 });
      
      const { account, updateFleet } = state.linkDriver;
     
      const driver = await linkDriverAccount({ driverId: account.id, updateFleet: updateFleet });
      const { t } = i18n.global;

      if (!driver.hasError) {
        dispatch('drivers/getDrivers', null, { root: true });
        commit('updatePopup', { popup: 'linkDriver', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.drivers.add', { error: driver.error.message ? driver.error.message : driver.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { popup: 'linkDriver', property: 'requests', value: state.linkDriver.requests - 1 });
    },
    // New favorite
    async saveFavorite({ state, rootState, commit, dispatch }) {
      commit('updatePopup', { popup: 'newFavorite', property: 'requests', value: state.newFavorite.requests + 1 });

      const settings = {
        filters: state.newFavorite.form.filters,
        map: state.newFavorite.form.map,
        serviceType: state.newFavorite.form.serviceType
      };

      if (state.newFavorite.form.type === 'route') {
        // Route
        settings.route = state.newFavorite.form.route;
      } else {
        // Search
        if (state.newFavorite.form.place) {
          settings.place = state.newFavorite.form.place;
        }
      }

      const params = {
        fleetId: rootState.fleet.current.id,
        name: state.newFavorite.form.name,
        status: state.newFavorite.form.type === 'search' ? 1 : 2,
        jsonSettings: JSON.stringify(settings)
      };

      const request = await addFavorite(params);
      const { t } = i18n.global;

      if (!request.hasError) {
        commit('updatePopup', { popup: 'newFavorite', property: 'visible', value: false });
        commit('updatePopupForm', { popup: 'newFavorite', field: 'name', value: null });
        commit('updatePopupForm', { popup: 'newFavorite', field: 'map', value: null });
        commit('updatePopupForm', { popup: 'newFavorite', field: 'route', value: null });
        commit('updatePopupForm', { popup: 'newFavorite', field: 'filters', value: null });

        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.favorites.add'),
          type: 'success'
        }], { root: true });
      } else {
        let error = request.error?.response?.data ? request.error?.response?.data : request.error;

        if (error === 'namealreadyexists') {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.favorites.namealreadyexists'),
            type: 'error'
          }], { root: true });
        } else {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.favorites.add', { error }),
            type: 'error'
          }], { root: true });
        }
      }

      commit('updatePopup', { popup: 'newFavorite', property: 'requests', value: state.newFavorite.requests - 1 });
    },
    // Delete favorite
    async deleteFavorite({ state, rootState, commit, dispatch }) {
      commit('updatePopup', { popup: 'deleteFavorite', property: 'requests', value: state.deleteFavorite.requests + 1 });

      const request = await deleteFavorite(state.deleteFavorite.favorite.id);
      const { t } = i18n.global;

      if (!request.hasError) {
        commit('updatePopup', { popup: 'deleteFavorite', property: 'visible', value: false });
        commit('updatePopup', { popup: 'deleteFavorite', property: 'favorite', value: null });

        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.favorites.delete'),
          type: 'success'
        }], { root: true });
        dispatch('favorites/getFavorites', null, { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.favorites.delete', { error: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { popup: 'deleteFavorite', property: 'requests', value: state.deleteFavorite.requests - 1 });
    },
    // Booked
    async sendByPhone({ commit, state, dispatch }) {
      commit('increaseRequests', 'booked');
      const { phone } = state.booked.form;
      const request = await send(state.booked.id, { phoneNumber: phone });
      const { t } = i18n.global;

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.booking.send', { sentto: phone }),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.booking.send', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests', 'booked');
    },
    async sendByEmail({ commit, state, dispatch }) {
      commit('increaseRequests', 'booked');
      const { email } = state.booked.form;
      const request = await send(state.booked.id, { email });
      const { t } = i18n.global;

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.booking.send', { sentto: email }),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.booking.send', { error: request?.error?.response?.data?.title ? request?.error?.response?.data?.title : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests', 'booked');
    },
    async getReservation({ commit, state, dispatch }) {
      commit('increaseRequests', 'reservation');
      const request = await getServiceBooking(state.reservation.id);
      if (!request.hasError) {
        const reservation = request;
        try { reservation.description = JSON.parse(reservation.description); }
        catch (e) { reservation.description = null; }
        commit('updatePopup', { popup: 'reservation', property: 'item', value: reservation });
      }
      commit('decreaseRequests', 'reservation');
    }
  }
};

export default popups;