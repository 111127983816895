import { fetchToken, bookingValidation,fetchParkingById } from '@/calls/apiSnapIntegration';
import { apiSnapIntegration } from '@/calls/apiSnapIntegration';
import { redirectFailed } from '../helpers/redirectHelper';

const snapIntegration = {
  namespaced: true,
  state: {
    token: null,
    error: null,
    parking : null
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setError(state, error) {
      state.error = error;
    },
    clearToken(state) {
      state.token = null;
    },
    setParking(state, parking) {
      state.parking = parking;
    },
  },
  actions: {
    async fetchToken({ commit }, { clientId, clientSecret, source, lang }) {
      try {
        const tokenResponse = await fetchToken(clientId, clientSecret);
        commit('setToken', tokenResponse);
        sessionStorage.setItem('authToken', tokenResponse);

        apiSnapIntegration.defaults.headers.common['Authorization'] = `Bearer ${tokenResponse}`;

      } catch (error) {
        commit('setError', error);
        if (source === 'listbooking') {
          if(!lang){lang = "en"}
          window.location.href = `/snaperror?error=CouldNotGenerateToken&language=${lang}`;
        }else{
          redirectFailed('CouldNotGenerateToken');
        }
        throw error;
      }
    },
    clearToken({ commit }) {
      commit('clearToken');
      sessionStorage.removeItem('authToken');
    },
    async validateBooking({ commit }, {item, source}) {
      try {
        const bookingValidateResponse = await bookingValidation(item);
        return bookingValidateResponse;
        // commit('setToken', tokenResponse);
      } catch (error) {      

        let errorDetail = 'Unknown error';
        if (error.message) {
          errorDetail = error.message;
        }

        commit('setError', errorDetail);
        if (source === 'listbooking') {
          let lang = item['language'] ? item['language'] : "en";
          window.location.href = `/snaperror?error=${encodeURIComponent(errorDetail)}&language=${lang}`;
        }else{
          redirectFailed(errorDetail);
        }
        
        throw error;
      }
    },
    async fetchParkingById({ commit }, parkingId) {
      try {
        const response = await fetchParkingById(parkingId);
        commit('setParking', response);
      } catch (error) {
        let errorDetail = 'Unknown error';
        if (error.message) {
          errorDetail = error.message;
        }

        commit('setError', errorDetail);

        redirectFailed(errorDetail);
        throw error;
      }
    },
  },
  getters: {
    token: (state) => state.token,
    hasError: (state) => !!state.error,
    getParking:(state) => state.parking
  }
};

export default snapIntegration;
