<template>
  <div class="service-detail hide-scrollbar">
    <button 
      type="button"
      class="backstreet-back"
      @click="$emit('onBack')"
    >
      <Sprite 
        :width="10" 
        :height="14" 
        :type="'arrow-left'" 
      />
      {{ $t('globals.back') }}
    </button>

    <template v-if="!loading && service">
      <div class="service-detail-header">
        <ParkingThumb
          :parking="service"
          :isService="true"
        />

        <button
          v-if="service"
          class="button button-big book-button"
          @click.prevent="$emit('onBook')"
        >
          {{ $t('globals.book') }}
        </button>
      </div>

      <slot name="content"></slot>

      <div class="service-detail-section">
        <h4 class="service-detail-title">{{ $t('services.detail.titles.map') }}</h4>
        <Map 
          v-if="service"
          :lat="service.latitude" 
          :lng="service.longitude" 
        />
      </div>
    </template>
    <div class="service-detail-loader" v-else>
      <Loader/>
    </div>
  </div>
</template>

<script>
import ParkingThumb from '@/components/modules/Search/ParkingThumb';
import Map from '@/components/modules/Search/DetailsMap';
import Loader from '@/components/elements/Loader';
import Sprite from '@/components/elements/Sprite'

export default {
  name: 'ServiceDetail',
  components: {
    ParkingThumb, 
    Map,
    Loader, 
    Sprite,
  },
  props: {
    service: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.service-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  position: relative;
  padding: 32px;
  overflow: auto;
  border-top: 1px solid $lightestgrey;

  // Header
  &-header {
    position: relative;
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $lightestgrey;

    .book-button {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 200px;
    }

    .result {
      padding-top: 18px;
      max-width: 506px;

      .h4 {
        font-size: rem(24px);
        line-height: 1.4;

        + p { margin-top: 16px; }
      }

      &-from { display: none; }
      &-footer {
        margin-top: 16px;
        line-height: 16px;
      }

      &-info { line-height: 15px; }
    }
  }

  &-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
    background-color: rgba($white, .7);
  }
}
</style>

<style lang="scss">
.service-detail {
  // Grid
  .service-detail-grid {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  .service-detail-aside {
    width: 300px;
    margin-right: 30px;
    position: sticky;
    top: 0;
  }

  .service-detail-content {
    width: calc(100% - 330px);
    /* height: 4000px; */
  }

  // Section
  .service-detail-section {
    width: 100%;
    margin-bottom: 30px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .service-detail-title {
    color: $darkblue;
    font-family: $secondary;
    font-size: rem(24px);
    font-style: normal;
    font-weight: 500;
    line-height: (26.4 / 24);

    &:not(:first-child) {
      margin-top: 30px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .service-detail-text {
    width: calc(100% - 45px);
    max-width: 1048px;
    // max-width: 715px;
    color: rgba($mediumgrey, .8);
    font-family: $secondary;
    font-size: rem(18px);
    font-weight: 500;
    line-height: (25.2 / 18);
  }
}
</style>