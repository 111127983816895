import {
  // getLanguages,
  getCountries,
  getSettings,
  getContext
} from '@/calls/settings';
import { slotFlagsText } from '@vue/shared';

const settings = {
  namespaced: true,
  state: {
    language: 'en',
    languages: [],
    currency: 'eur',
    currencies: [],
    country: 'be',
    countries: [],
    activeFeatures: {},
    context: null,
    isOwner: false,
    requests : {
      settings: 0
    }
  },
  mutations: {
    updateLanguage(state, language) {
      state.language = language;
    },
    updateLanguages(state, languages) {
      state.languages = languages;
    },
    updateCountry(state, country) {
      state.country = country;
    },
    updateCurrency(state, currency) {
      state.currency = currency;
    },
    updateActiveFeatures(state, features) {
      state.activeFeatures = features;
    },
    updateCountries(state, countries) {
      state.countries = countries;
    },
    updateCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    updateContext(state, context) {
      state.context = context;
    },
    updateOwnership(state, ownership) {
      state.isOwner = ownership;
    },
    increaseRequests(state, type) {
      state.requests[type]++;
    },
    decreaseRequests(state, type) {
      state.requests[type]--;
    }
  },
  actions: {
    setCountry({ commit }, country) {
      return commit('updateCountry', country);
    },
    async setCountries({ state, commit }) {
      commit('increaseRequests', 'settings');
      const countries = await getCountries();
      commit('updateCountries', countries);
      commit('decreaseRequests', 'settings');
    },
    setContext({ commit, state, dispatch }) {
      const context = getContext();
      context.then((promise) => {
        if(promise.isAdmin) {
          commit('companies/updateProperty', {
            property: 'visible',
            value: true
          }, { root: true });
          dispatch('companies/getCompanies', null, { root: true });
        } else {
          const fleet = promise?.fleetPlanner?.fleet;
          if (fleet) commit('fleet/updateFleet', fleet, { root: true });
        }

        commit('updateOwnership', promise.fleetPlanner && promise.fleetPlanner.isOwner);
        return commit('updateContext', promise);
      });
    },
    async setSettings({ commit, state }, types = ['languages', 'activeFeatures']) {
      commit('increaseRequests', 'settings');
      const settings = await getSettings();

      for (let i = 0; i < types.length; i++) {
        const type = types[i];
        const typeUpper = type.charAt(0).toUpperCase() + type.slice(1);
        commit(`update${typeUpper}`, settings[type]);
      }

      commit('decreaseRequests', 'settings');
    }
  }
};

export default settings;
