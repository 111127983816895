import { fetchParkingById } from '@/calls/apiSnapIntegration';

const siParkings = {
  namespaced: true,
  state: {
    // current: [],
    // parkings: [],
    // filters: [],
    // map: null,
    // byId: [],
    // slots: [],
    // allSlots: [],
    details: {},
    drivers: [],
    trucks: [],
    results: [],
    requests: {
      details: 0,
      filters: 0,
      parkings: 0,
      slots: 0,
      calendar: 0,
      date: null
    },
    // search: {
    //   type: 'parkings',
    //   parkings: [],
    // },
    form: {
      parkingName: null,
      parking: null,
      place: null,
      coords: null,
      result: null,
      truck: null,
      driver: null,
      package: null,
      duration: null,
      slot: null,
      startAt: null,
      date: null,
      loadPeriods: false,
      referentialNumber:null,
      licensePlate:null,
      email:null,
      fleetId:null
    },
    // buttons: [],
    // options: {
    //   range: {
    //     min: 50,
    //     max: 700
    //   }
    // },  
    // parking: {}
  },
  mutations: {
    // updateCurrent(state, parking) {
    //   state.current = parking;
    // },
    // updateParkings(state, parkings) {
    //   state.parkings = parkings;
    // },
    // updateParking(state, parking) {
    //   state.parking = parking;
    // },
    // updateFilters(state, filters) {
    //   state.filters = filters;
    // },
    updateButtons(state, buttons) {
      state.buttons = buttons;
    },
    // updateFilterValue(state, { filter, value }) {
    //  state.filters =  state.filters.map(filt => {
    //     if (filt.id === filter.id) {
    //       if (filt.id === 'range') {
    //         filt.value = value;
    //       } else {
    //         filt.options = filt.options.map(option => {
    //           if (option.value === value) option.isActive = !option.isActive;
    //           return option;
    //         });
    //       }
    //     }
    //     return filt;
    //   });
    // },
    // updateSearchValue(state, { property, value }) {
    //   state.search[property] = value;
    // },
    // updateMap(state, map) {
    //   state.map = map;
    // },
    // updatebyId(state, byId) {
    //   state.byId = byId;
    // },
    updateSlotById(state, slots) {
      state.slots = slots;
    },
    updateSlots(state, slots) {
      state.allSlots = slots;
    },
    updateDetails(state, details) {
      state.details = details;
    },
    updateTrucks(state, trucks) {
      state.trucks = trucks;
    },
    // updateResults(state, results) {
    //   state.results = results;
    // },
    updateDrivers(state, drivers) {
      state.drivers = drivers;
    },
    updateForm(state, { property, value }) {
      state.form[property] = value;
    },
    resetForm(state) {
      state.form = {
        parkingName: null,
        parking: null,
        place: null,
        coords: null,
        result: null,
        truck: null,
        driver: null,
        package: null,
        duration: null,
        slot: null,
        startAt: null,
        date: null,
        referentialNumber:null
      }
    },
    increaseRequests(state, type) {
      state.requests[type]++;
    },
    decreaseRequests(state, type) {
      state.requests[type]--;
    },
    updateRequests(state, { property, value }) {
      state.requests[property] = value;
    }
  },
  actions: {
    // resetFilters({ state, commit }) {
    //   state.filters.map(filter => {
    //     if (filter.id === 'range') {
    //       commit('updateFilterValue', {
    //         filter: { id: 'range' },
    //         value: state.options.range.max
    //       });
    //       return;
    //     }

    //     filter.options.map(option => {
    //       if (option.isActive) {
    //         commit('updateFilterValue', {
    //           filter: { id: filter.id},
    //           value: option.value
    //         });
    //       }
    //     });
    //   });
    // },
    // setCurrent({ commit, dispatch }, id) {
    //   let parking = this.state.parkings.parkings.filter(p => Number(p.id) === Number(id));
    //   parking = parking.shift()
    //   dispatch('settings/setCountry',
    //     parking.address.country,
    //     { root: true }
    //   );
    //   return commit('updateCurrent', parking);
    // },
    async setParking({ commit }, id) {
      await fetchParkingById(id)
      .then((promise) => {
        // commit('updateCurrent', promise);
        // commit('updateParking', promise);
        return commit('updateDetails', promise);
      })
    },
    // setParkings({ commit }) {
    //   commit('increaseRequests', 'parkings');
    //   const parking = fetchParkingById();
    //   const byId = {};

    //   return parking.then((promise) => {
    //     commit('updateParkings', promise);
    //     promise.forEach(element => {
    //       byId[`id-${element.id}`] = element
    //     });
    //     commit('updatebyId', byId);
    //     commit('decreaseRequests', 'parkings');
    //     return promise;
    //   });
    // },   
    setSlots({ commit }) {
      commit('increaseRequests', 'slots');
      const slots = getSlots();
      const arr = [];
      return slots.then((promise) => {
        promise.forEach(element => {
          arr[element.id] = element.icon
        });
        commit('updateSlots', promise);
        commit('updateSlotById', arr);
        commit('decreaseRequests', 'slots');
        return promise;
      });
    }, 
    // async infos({ commit, dispatch }, id) {
    //   commit('increaseRequests', 'details');

    //   try {
    //     const parking = await fetchParkingById(id);

    //     // for (let i = 0; i < parking.commodities.length; i++) {
    //     //   const commodity = parking.commodities[i];
    //     //   if (commodity.commodities && commodity.commodities.length) {
    //     //     const translation = await getTranslation(i18n.global.locale, commodity.translationKey);
    //     //   }
    //     // }
    //     commit('updateDetails', parking);
    //   } catch (error) {
    //     dispatch('notifications/addNotifs', [{
    //       text: `Error while getting parking infos : ${error}`,
    //       type: 'error'
    //     }], { root: true });
    //   }

    //   commit('decreaseRequests', 'details');

    //   // parking
    //   // .then(details => {
    //   //   commit('updateDetails', details);
    //   //   commit('decreaseRequests', 'details');
    //   // })
    //   // .catch(error => {
    //   //   dispatch('notifications/addNotifs', [{
    //   //     text: `Error while getting parking infos : ${error}`,
    //   //     type: 'error'
    //   //   }], { root: true });
    //   //   commit('decreaseRequests', 'details');
    //   // });
    // },
    // async getTrucks({ commit, dispatch }) {
    //   const trucks = await getSelectTrucks();

    //   if (trucks.hasError) {
    //     dispatch('notifications/addNotifs', [{
    //       text: `Error while getting trucks : ${trucks.error}`,
    //       type: 'error'
    //     }], { root: true });
    //   } else {
    //     commit('updateTrucks', trucks);
    //   }
    // },
    
    // async getDrivers({ commit, dispatch }) {
    //   const drivers = await getDrivers();

    //   if (drivers.hasError) {
    //     dispatch('notifications/addNotifs', [{
    //       text: `Error while getting trucks : ${drivers.error}`,
    //       type: 'error'
    //     }], { root: true });
    //   } else {
    //     commit('updateDrivers', drivers);
    //   }
    // }
  }  
};

export default siParkings;
