<template>
  <div class="breadcrumb-container">
    <h2
      v-if="displayName"
      class="h4">{{ this.$store.state.parkings.form.parkingName }}
    </h2>
    <ol 
      class="breadcrumb" 
      v-if="['StepDetails', 'StepStay', 'StepReview'].indexOf(step) > -1"
    >
      <li 
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        class="breadcrumb-step"
        :class="{
          'breadcrumb--active': breadcrumb.condition === step,
          'breadcrumb--previous': previous[breadcrumb.condition] || breadcrumb.condition === 'StepStay' && step === 'StepSlot',
        }"
      >
        <Sprite 
          v-if="index > 0" 
          :width="10" 
          :height="14" 
          :type="'chevron'" 
        />
        <span class="breadcrumb-step__content">
          {{index + 1}}. {{ $t(breadcrumb.title) }}
          <!-- {{index + 1}}. {{breadcrumb.title}} -->
        </span>
      </li>
    </ol>
    <div class="breadcrumb-line"></div>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  name: "Breadcrumb",
  components:{
    Sprite,
  },
  props: {
    step: {
      type: String
    },
    previous: {
      type: [String, Array]
    }
  },
  data() {
    return {
      breadcrumbs: [
        {title: 'components.breadcrumbs.stay', condition: 'StepStay'},
        // {title: 'components.breadcrumbs.slot', condition: 'StepSlot'},
        // {title: 'components.breadcrumbs.infos', condition: 'StepDetails'},
        {title: 'components.breadcrumbs.review', condition : 'StepReview'}
      ]
    }
  },
  computed: {
    displayName() {
      return this.step.match(/StepDetails|StepStay|StepSlot|StepReview/);
    }
  }
}
</script>

<style lang="scss" scoped>
  .breadcrumb {
    font-family: $primary;
    font-weight: 500;
    font-style: italic;
    font-size: rem(18);

    color: $darkblue;
    line-height: 1.2;
    text-transform: uppercase;

    list-style: none;

    display: flex;
    align-items: center;

    padding-left: 0;
    margin: 20px 0 24px;

    &-container {
      .h4 {
        margin-top: 20px;
      }
    }


    .svg-chevron {
      transform: rotate(-90deg);
      margin: 0 16px;
    }
    &-step {
      opacity: .5;
    }
    &--previous, &--active {
      opacity: 1;
    }
    &-step__content {
      position: relative;
    }
    &--active{

      .breadcrumb-step__content::after {
        display: block;
        content: '';
        width: 60px;
        height: 4px;
  
        position: absolute;
        bottom: -8px;
        left: 0;
  
        opacity: 1;
        background: $lightgreen;
  
        border-radius: 0 0 20px 20px;
      }
    }
  }
</style>