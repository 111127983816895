<template>
  <div class="dtchoice">
    <h2 class="h4">{{ title }}</h2>

    <div class="dtchoice-columns">
      <!-- Driver -->
      <div class="dtchoice-column">
        <Inputs
          :type="'text'"
          :value="search.driver"
          :label="$t('labels.driver_name')"
          :placeholder="$t('labels.search_driver')"
          @input="onInput($event, 'driver')"
        />
        <ul
          ref="drivers"
          class="dtchoice-list"
        >
          <li
            v-for="d in displayedDrivers"
            :key="d.value"
          >
            <button
              class="dtchoice-result"
              :ref="`drivers-${d.value}`"
              :class="{'is-selected': driver && d.value === driver.value}"
              @click="onClick(d, 'driver')"
            >
              {{ d.label }}
              <Sprite
                v-if="driver && d.value === driver.value"
                :type="'check'"
                :width="14"
                :height="16"
              />
            </button>
          </li>
        </ul>
      </div>

      <div class="dtchoice-column">
        <Inputs
          :type="'text'"
          :value="search.truck"
          :label="$t('labels.truck_name')"
          :placeholder="$t('labels.search_truck')"
          @input="onInput($event, 'truck')"
        />
        <ul
          ref="trucks"
          class="dtchoice-list"
        >
          <li
            v-for="t in displayedTrucks"
            :key="t.value"
          >
            <button
              class="dtchoice-result"
              :ref="`trucks-${t.value}`"
              :class="{'is-selected': truck && t.value === truck.value}"
              @click="onClick(t, 'truck')"
            >
              {{ t.label }}
              <Sprite
                v-if="truck && t.value === truck.value"
                :type="'check'"
                :width="14"
                :height="16"
              />
            </button>
          </li>
        </ul>
      </div>
    </div>

    <button
      class="dtchoice-next button button-big"
      :disabled="disableNext"
      @click.prevent="onNext"
    >
      {{ $t('globals.select') }}
    </button>

    <div v-if="loading" class="dtchoice-loader">
      <loader/>
    </div>
  </div>
</template>

<script>
// Components
import Sprite from '@/components/elements/Sprite';
import Inputs from '@/components/elements/Inputs';
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Sprite,
    Inputs,
    Loader, 
  },
  props: {
    title: {
      type: String,
      default: null
    },
    disableNext: {
      type: Boolean,
      default: null
    },
    driver: {
      type: Object,
      default: null
    },
    drivers: {
      type: Array,
      default: () => []
    },
    truck: {
      type: Object,
      default: null
    },
    trucks: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: {
        driver: '',
        truck: ''
      }
    }
  },
  computed: {
    displayedDrivers() {
      const search = this.search.driver;
      const drivers = this.drivers
        .filter(driver => ((driver.lastName && driver.firstName) || driver.nickname || driver.fullName) && driver.status === 'Active')
        .map(driver => {
          const label = driver.fullName;

          if (!label || !label.length) {
            label = driver.lastName && driver.firstName ? `${driver.firstName} ${driver.lastName}`: driver.nickname
          }

          return {
            value: driver.driverId,
            label,
            truckID: driver.truckId,
            isTruck: driver.isTruck,
            email: driver.email,
            phoneNumber: driver.phoneNumber,
          };
        })
        .filter(driver => {
          if (!search.length) return true;
          if (driver.label) {
            const searchLower = search.toLowerCase();
            const lower = driver.label.toLowerCase();
            const index = lower.indexOf(searchLower);
            return index >= 0;
          }

          return false;
        });

      return drivers;
    },
    displayedTrucks() {
      const search = this.search.truck;
      
      const trucks = this.trucks
        .filter(truck => truck.label && truck.label.trim().length)
        .filter(truck => {
          if (!search.length) return true;
          if (truck.label) {
            const searchLower = search.toLowerCase();
            const lower = truck.label.toLowerCase();
            const index = lower.indexOf(searchLower);
            return index >= 0;
          }

          return false;
        });

      return trucks;
    }
  },
  watch: {
    driver(val) {
      if (val) {
        this.scrollToSelected('drivers');
      }
    },
    truck(val) {
      if (val) {
        this.scrollToSelected('trucks');
      }
    }
  },
  methods: {
    onInput(e, type) {
      if (!e) return;
      const { target } = e;
      const { value } = target;
      this.search[type] = value;
    },
    onClick(value, property) {
      if (property === 'driver') {
        this.$emit('onChangeDriver', value);
        const driver = this.drivers.filter(d => d.driverId === value.value)[0];
        const truck = this.trucks.filter(t => `${t.value}` === `${driver.truckId}`);

        if (truck.length) {
          // this.$store.commit('parkings/updateForm', {
          //   value: truck[0].value,
          //   property: 'truck'
          // });
          this.$emit('onChangeTruck', truck[0]);
        } else {
          this.$emit('onChangeTruck', null);
          // this.$store.commit('parkings/updateForm', { value: null, property: 'truck' });
        }
      }

      if (property === 'truck') {
        this.$emit('onChangeTruck', value);

        let driver = this.drivers.filter(d => `${d.truckId}` === `${value.id}`);
        // const formDriver = this.driver;

        if (driver.length) {
          // Driver assigné
          driver = driver[0];

          if (driver.isTruck) {
            // Is truck account
            // this.$store.commit('parkings/updateForm', { value: driver.value, property: 'driver' });
            this.$emit('onChangeDriver', driver);
          } else {
            // Not truck account

            if (this.driver) {
              // Has selected truck
              const selectedDriver = this.drivers.filter(d => `${d.driverId}` === `${this.driver.value}`)[0];

              // Selected truck is truck account
              if (selectedDriver.isTruck) {
                // this.$store.commit('parkings/updateForm', { value: driver.value, property: 'driver' })
                this.$emit('onChangeDriver', driver);
              };
            } else {
              // No selected truck
              // this.$store.commit('parkings/updateForm', { value: driver.value, property: 'driver' });
              this.$emit('onChangeDriver', driver);
            }
          }
        } else {
          if (this.driver) {
            // Has selected truck
            const selectedDriver = this.drivers.filter(d => `${d.driverId}` === `${this.driver.value}`)[0];

            // Selected truck is truck account
            // if (selectedDriver.isTruck) this.$store.commit('parkings/updateForm', { value: null, property: 'driver' });
            if (selectedDriver.isTruck) this.$emit('onChangeDriver', null);
          }
        }
      }
    },
    onNext() {
      this.$emit('onNext');
      // const driver = this.drivers.filter(d => `${d.value}` === `${this.driver}`)[0];
      // this.$store.commit('popups/updatePopupForm', { popup: 'booked', field: 'email', value: driver.email ? driver.email : '' });
      // this.$store.commit('popups/updatePopupForm', { popup: 'booked', field: 'phone', value: driver.phoneNumber ? driver.phoneNumber : '' });

      // this.$emit('newStep', {
      //   step: 'StepReview',
      //   datas: this.datas
      // })
    },
    scrollToSelected(type) {
      const $container = this.$refs[type];
      const $selected = this.$refs[`${type}-${type === 'drivers' ? this.driver.value : this.truck.value}`]

      const containerRect = $container.getBoundingClientRect();
      const selectedRect = $selected.getBoundingClientRect();
      const scrollTop = $container.scrollTop;

      const selectedPos = (selectedRect.y + scrollTop) - containerRect.y;
      const selectedHeight = selectedRect.height;

      const viewStart = scrollTop + selectedHeight;
      const viewEnd = (scrollTop + containerRect.height) - selectedHeight;

      if (selectedPos + selectedHeight < viewStart) {
        $container.scrollTop = selectedPos;
        return
      }

      if (selectedPos > viewEnd) {
        $container.scrollTop = selectedPos - containerRect.height + selectedHeight;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dtchoice {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 980px;
    height: 80%;
    position: relative;
    /* padding-top: 12px; */

    .h4 {
      width: 100%;
    }

    &-columns {
      display: flex;
      flex-shrink: 1;
      overflow: hidden;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &-column {
      display: flex;
      flex-direction: column;
      width: calc(50% - 12px);
      height: 100%;

      + .step-infos-column {
        margin-left: 24px;
      }
    }

    &-list {
      margin-top: 16px;
      list-style: none;
      overflow: auto;
    }

    &-result {
      display: flex;
      align-items: center;
      padding: 10px 20px 9px;
      width: 100%;
      color: $darkblue;
      transition: color .3s $ease-out-quart;
      font-family: $secondary;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      cursor: pointer;

      .svg {
        margin-left: auto;
      }

      &.is-selected {
        background-color: rgba($grey, .1);
      }

      &:hover {
        color: $mediumgreen;
      }
    }

    &-next {
      align-self: flex-start;
      margin-top: auto;
      min-width: 200px;
    }

    .dtchoice-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($white, .7);
    }
  }
</style>