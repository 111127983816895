import axios from 'axios';

const getXMLFiles = () => {
  return axios.get(`/napapi/files/static`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

export {
  getXMLFiles
}
  