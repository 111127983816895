<template>
  <div
    class="grid-dashboard snap-dashboard snap-layout"
    :class="{
      'grid--small': sidebar === false,
      'grid--nosubbar': this.$route.meta.parent !== 'parking'
    }"
  >
    <!-- <Header /> -->
    <!-- <Sidebar /> -->
    <main 
      class="main"
      :class="[className, {
        'grid--main': approvementComponent || plannerSelect,
        'grid--main-nofilter': !approvementComponent && !plannerSelect,
        'grid--main-nofooter': !approvementComponent && !plannerSelect,
        'grid--main-minimal': isMinimal
      }]"
    >
      <!-- :class="[className, {
        'grid--main': quickSearchComponent || approvementComponent || newButton || plannerSelect,
        'grid--main-nofilter': !quickSearchComponent && !approvementComponent && !newButton && !plannerSelect,
        'grid--main-nofooter': !quickSearchComponent && !approvementComponent && !newButton && !plannerSelect && !paginateComponent,
        'grid--main-minimal': isMinimal
      }]"
    > -->
      <section class="main-header">
        <h2 v-if="title" class="h4">{{ $t(title) }}</h2>

        <div v-if="tools" class="main-header-tools">
          <!-- <Date v-if="dateComponent" />
          <Available v-if="availableComponent" /> -->
          <!-- <DownloadButton ref="downloadButton" /> -->
          <QuickSearch ref="quickSearch"/>
          <DateCalendar v-if="calendarComponent" />
          <Buttons v-if="buttons && buttons.length" :buttons="buttons"/>
        </div>

        <div
          v-if="subtitle"
          class="main-header-bottom"
        >
          <p class="main-header-subtitle">{{ $t(subtitle) }}</p>
        </div>
      </section>

        <!-- v-show="approvementComponent || quickSearchComponent || newButton"  -->
        <!-- :class="{'main-filters--spaced': (approvementComponent || newButton) && quickSearchComponent}" -->
        <!-- v-show="approvementComponent || quickSearchComponent"  -->
        <!-- :class="{'main-filters--spaced': (approvementComponent) && quickSearchComponent}" -->
      <!-- <Filters 
        v-show="approvementComponent" 
        :class="{'main-filters--spaced': approvementComponent}"
        :approvement="approvementComponent"
        :plannerSelect="plannerSelect"
      /> -->
        <!-- :quickSearch="quickSearchComponent" -->
        <!-- :button="newButton" -->
      
      <router-view/>
      <!-- <section 
        v-if="paginateComponent" 
        class="main-footer"
      >
        <Paginate />
      </section> -->
    </main>
    <!-- <Footer /> -->

    
    <!-- <div
      v-if="validContext() === false"
      class="ribbon"
    >
      {{ $t('globals.layout.warning') }}
    </div> -->

    <!-- Popups -->
    <!-- <Companies /> -->
    <!-- <SecurityLevels v-if="$store.state.securityLevels.visible"/> -->
    <!-- <Spontaneous/> -->
    <!-- <NewDriver/>
    <LinkDriver/> -->
    <Booked />
    <Rules />
    <Modal />
    <Notifications />
  </div>
  <!-- <div
    v-else
    class="app-loader"
  >
    <h1 class="app-loader-title h4">{{ $t('globals.layout.loader') }}</h1>
    <Loader/>
  </div> -->
</template>

<script>
import moment from 'moment';
// import Header from '@/components/common/Header';
// import Footer from '@/components/common/Footer';
// import Sidebar from '@/components/common/Sidebar';

// import Date from '@/components/partials/Header/Date';
import Buttons from '@/components/modules/Header/Buttons';
import DateCalendar  from '@/components/partials/Header/Snap_DateCalendar';
// import Available  from '@/components/partials/Header/Available';

// import Paginate from '@/components/elements/Paginate';
// import Loader from '@/components/elements/Loader';
// import Filters from '@/components/partials/Header/Filters';
import QuickSearch from '@/components/modules/Snap_QuickSearch';
// import DownloadButton from '@/components/elements/DownloadButton.vue';

import Modal from '@/components/modules/Modal';
// import Companies from '@/components/partials/Companies';
import Notifications from '@/components/common/Notifications';
// import SecurityLevels from '@/components/partials/SecurityLevels';
// import Spontaneous from '@/components/partials/Spontaneous';

import Rules from '@/components/partials/Popups/Rules';
import Booked from '@/components/partials/Popups/Booked';
// import NewDriver from '@/components/partials/Popups/NewDriver';
// import LinkDriver from '@/components/partials/Popups/LinkDriver';

export default {
  components: {
    // Header,
    // Sidebar,
    // Footer,
    // Date,
    DateCalendar, 
    // Available,
    // Paginate,
    // Filters,
    Modal,
    Buttons,
    Notifications,
    // Loader,
    // Companies,
    // SecurityLevels,
    // Spontaneous,
    // NewDriver,
    // LinkDriver,
    Rules,
    Booked,
    QuickSearch,
    // DownloadButton
  },
  data() {
    return {
      // hasValidContext: true
    };
  },
  computed:{
    sidebar() {
      return this.$store.state.user.sidebar;
    },
    className() {
      return `main-${this.$route.name ? this.$route.name.toLowerCase() : ''}`;
    },
    title() {
      return this.$route.meta?.titleTemplate;
    },
    subtitle() {
      return this.$route.meta?.subtitle;
    },
    // dateComponent() {
    //   return this.$route.meta.components ? this.$route.meta.components.Date : null;
    // },
    calendarComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Calendar : null;
    },
    quickSearchComponent() {
      return this.$route.meta.components ? this.$route.meta.components.QuickSearch : null;
    },
    approvementComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Approvement : null;
    },
    // availableComponent() {
    //   return this.$route.meta.components ? this.$route.meta.components.Available : null;
    // },
    // paginateComponent() {
    //   return this.$route.meta.components ? this.$route.meta.components.Pagination : null;
    // },
    plannerSelect() {
      if (!this.$route.meta.components || !this.$route.meta.components.PlannersSelect) return null;
      const { isOwner } = this.$store.state.settings;
      return isOwner;
    },
    isMinimal() {
      return this.$route.name.match(/Search|EditProfile|TW|TWStepDetail|TWStepReview/);
    },
    tools() {
      const { layout } = this.$store.state;
      return layout.search || layout.buttons.length > 0 || this.$route.meta.components?.Calendar;
    },
    fleet() {
      return this.$store.state.fleet.current;
    },
    // context() {
    //   return this.$store.state.settings.context;
    // },
    // appLoaded() {
    //   const { context } = this.$store.state.settings;
    //   const { current } = this.$store.state.fleet;
    //   return context && current;
    // },
    buttons() {
      return this.$store.state.layout.buttons;
    }
  },
  watch: {
    // $route() {
    //   this.$store.commit('bookings/updateCurrentBookingList', null);
    //   this.$store.commit('bookings/updateCurrentBookingsPaginate', null);
    //   this.$store.commit('bookings/updateSelectedBooking', []);
    //   this.$store.commit('user/updatePagination', { per: 10, page: 1 });

    //   if (this.fleet && this.fleet.id) this.getViewData();
    // },
    // fleet() {
    //   this.getViewData();
    // },
    // context() {
    //   this.validContext();
    // },
    // hasValidContext(value, oldValue) {
    //   if (value === false && oldValue === true) {
    //     this.$store.dispatch('notifications/addNotifs', [{
    //       text: 'You are using an account that does not correspond to this platform.',
    //       type: 'error',
    //       signout: true
    //     }], { root: true });
    //   }
    // }
  },
  created() {
    // this.setSettings();
  },
  methods: {
    // setSettings() {
    //   this.$store.dispatch('settings/setSettings');
    // },
    // getViewData() {
    //   this.$store.dispatch('translations/getTranslations');

    //   const { name } = this.$route;
    //   const { id } = this.$route.params;
    //   const { service, paymentStatus } = this.$route.query;
    //   console.log("name", name);
    //   switch (name) {        
    //     case 'EditProfile':
    //       const { settings } = this.$store.state.settings.requests;
    //       const { languages } = this.$store.state.settings;
    //       if (settings === 0 && (!languages || !languages.length)) this.$store.dispatch('settings/setSettings');
    //       break;
    //     case 'Search':
    //       // Refactor calls
    //       // const parkings = this.$store.dispatch('parkings/setParkings');
    //       const { filters, requests } = this.$store.state.map;
    //       if (requests.filters === 0 && (!filters || !filters.length)) this.$store.dispatch('map/getFilters');
    //       this.$store.dispatch('parkings/setSlots');
    //       break;
    //     case 'Drivers':
    //       // Refactor calls
    //       if (this.$store.state.drivers.requests === 0) this.$store.dispatch('drivers/getDrivers');
    //       break;
    //     case 'Trucks':
    //       // Refactor calls
    //       if (this.$store.state.trucks.requests === 0) this.$store.dispatch('trucks/getTrucks');
    //       const { countries } = this.$store.state.settings;
    //       if (!countries || !countries.length) this.$store.dispatch('settings/setCountries');
    //       break;
    //     case 'Planners':
    //       // Refactor calls
    //       if(this.$store.state.planners.requests === 0) this.$store.dispatch('planners/getPlanners');
    //       break;
    //     case 'Booking':
    //       // Refactor calls
    //       if (typeof paymentStatus === 'undefined') this.$store.commit('bookings/updatePaymentStatus', null);
    //       else this.$store.commit('bookings/updatePaymentStatus', paymentStatus);

    //       const { byId, slots } = this.$store.state.parkings;
    //       if (Array.isArray(byId) && !byId.length) this.$store.dispatch('parkings/setParkings');
    //       if (Array.isArray(slots) && !slots.length) this.$store.dispatch('parkings/setSlots');

    //       if (this.$store.state.bookings.requests === 0) {
    //         this.$store.dispatch('bookings/setBookings');
    //         if (this.$store.state.settings.isOwner) this.$store.dispatch('bookings/getPlanners');
    //       }
    //       break;
    //     case 'Favorites':
    //       if (this.$store.state.favorites.requests === 0) this.$store.dispatch('favorites/getFavorites');
    //       this.$store.dispatch('map/getFilters');
    //       break;
    //     case 'TW':
    //       if (id || id === 0) {
    //         this.$store.commit('tw/updateId', id);
    //         this.$store.dispatch('tw/getService');
    //       }
    //       break;
    //     case 'TWStepDetail':
    //       this.$store.dispatch('parkings/getTrucks');
    //       this.$store.dispatch('parkings/getDrivers');

    //       if (id || id === 0) {
    //         this.$store.commit('tw/updateId', id);
    //         this.$store.dispatch('tw/getService');
    //       }
    //       break;
    //     case 'TC':
    //       if (id || id === 0) {
    //         this.$store.commit('tc/updateId', id);
    //         this.$store.dispatch('tc/getService');
    //       }
    //       break;
    //     case 'TCStepDetail':
    //       this.$store.dispatch('parkings/getTrucks');
    //       this.$store.dispatch('parkings/getDrivers');

    //       if (id || id === 0) {
    //         this.$store.commit('tc/updateId', id);
    //         this.$store.dispatch('tc/getService');
    //       }
    //       break;
    //     case 'Reservations':
    //       if (typeof service === 'undefined') this.$store.commit('reservations/updateServiceType', null);
    //       else this.$store.commit('reservations/updateServiceType', service);
    //       this.$store.dispatch('reservations/getReservations');

    //       break;
    //     default:
        
    //       break;
    //   }
    // },
    // validContext() {
    //   if(this.context) {
    //     // Context for blocking popup
    //     this.hasValidContext = this.context.isAdmin || this.context.isFleetPlanner;
    //     // this.hasValidContext = this.context.isAdmin || this.context.isFleetPlanner;

    //     // Context for ribbon
    //     return this.context.isAdmin || this.context.isFleetPlanner;
    //     // return !this.context.isAdmin && this.context.isFleetPlanner;
    //   }

    //   return null;
    // }
  },
}
</script>

<style lang="scss">
  /* SCSS */
  .app-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .app-loader-title {
      margin-bottom: 24px;
      margin-top: -24px;
      letter-spacing: 0;
    }
  }


  .snap-dashboard {
    max-width: none !important;
    grid-template-columns: min(0, 280px) 1fr !important;
    grid-template-rows: min(0, 90px) auto min(0, 58px) !important;

    & .main-container {
      border-right: none !important;
    }

      & .quicksearch input {
          border-radius: 3px;
          padding-right: 40px;

          color: $snap-text-primary !important;

          &::placeholder {
            color: $snap-text-disabled !important;
          }

          &:placeholder-shown {
              text-overflow: ellipsis;
          }
      }

      & .table .head {
        text-wrap: balance !important;
        font-weight: 600 !important;
        br {
          display: none;
        }
      }
    }
</style>
