<template>
  <aside 
    class="calendar"
    :class="isVisible ? 'calendar--visible' : ''"
    @click="onClick"
  >
    <header 
      class="calendar__header"
      @click="$emit('close')"
    >
      <!-- <h1 class="small"> {{ $t('globals.start_date', $store.state.profile.language) }} </h1> -->
      <time class="h4">{{dateFormat(startDate)}} </time>
      <span class="h4 spacing" v-if="endDate && endDate !== startDate"> - </span>
      <time class="h4" v-if="endDate && endDate !== startDate">{{dateFormat(endDate)}}</time>
      <span v-if="!readOnly">
        <Sprite type="arrow" :width="15" :height="10" />
      </span>
    </header>
    <div class="calendar__container prevent">
      <nav class="calendar__nav">
        <button type="button" class="prev-month" @click="subtractMonth">
          <Sprite :key="'prev'" type="chevron" :width="10" :height="16" />
        </button>
        <h2 class="current-month">{{ month +' '+ year }}</h2>
        <button type="button" class="next-month" @click="addMonth">
          <Sprite :key="'next'" type="chevron" :width="10" :height="16"/>
        </button>
      </nav>
      <section class="calendar__body">
        <ol class="weekdays">
          <li class="weekday" v-for="(day, index) in days" :key="day+index">
            {{ day }}
          </li>
        </ol>
        <ol class="dates">
          <li class="date date--before" v-for="(blank, i) in firstDayOfMonth" :key="blank+'date'+i">{{blank}}</li>
          <li 
            @click="handleDate"
            @dblclick="resetDate"
            class="date"
            v-for="(date, i) in daysInMonth"  
            :key="date+i"
            :data-time="year + dateContext.format('MM') + digitFormat(date)" 
            :class="setClass(year + dateContext.format('MM') + digitFormat(date))">
            {{ date }}
          </li>
        </ol>
      </section>
      <footer v-if="!fromToday && forceDuration === 0" class="calendar__footer">
        <button type="button" @click="setToday">{{ $t('components.calendar.selections.from_today') }}</button>
        <button type="button" @click="setDates(-1)">{{ $t('components.calendar.selections.yesterday') }}</button>
        <button type="button" @click="setDates(-7, 1)">{{ $t('components.calendar.selections.last_7_days') }}</button>
        <button type="button" @click="setDates(-30, 1)">{{ $t('components.calendar.selections.last_30_days') }}</button>
        <button type="button" @click="setMonth">{{ $t('components.calendar.selections.this_month') }}</button>
        <button type="button" @click="setMonth(-1)">{{ $t('components.calendar.selections.previous_month') }}</button>
    </footer>
    </div>
  </aside>
</template>

<script>
import moment from 'moment';

import Sprite from '@/components/elements/Sprite.vue';

export default {
  components: {
    // Button,
    Sprite
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    onStart: {
      type: String,
    },
    onEnd: {
      type: String,
    },
    period: {
      type: String,
    },
    fromToday: {
      type: Boolean,
      default: false
    },
    forceDuration: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    year() {
      return this.dateContext.format('Y');
    },
    month() {
      return this.$t(`components.calendar.months.${this.dateContext.format('M')}`);
      // return this.dateContext.format('MMMM');
    },
    daysInMonth() {
      return this.dateContext.daysInMonth();
    },
    currentDate() {
      return this.dateContext.get('date');
    },
    firstDayOfMonth() {
      const firstDay = moment(this.dateContext).subtract((this.currentDate), 'days');
      // const firstDay = moment(this.dateContext).subtract((this.currentDate - 1), 'days');
      return firstDay.weekday();
    },
    initialDate() {
      return this.today.get('date');
    },
    initialMonth() {
      return this.today.format('MMMM');
    },
    initialYear() {
      return this.today.format('Y');
    },
    days() {
      return [
        this.$t('components.calendar.days_abbr.1'),
        this.$t('components.calendar.days_abbr.2'),
        this.$t('components.calendar.days_abbr.3'),
        this.$t('components.calendar.days_abbr.4'),
        this.$t('components.calendar.days_abbr.5'),
        this.$t('components.calendar.days_abbr.6'),
        this.$t('components.calendar.days_abbr.7')
      ];
    }
  },
  watch: {
    onStart(val) {
      this.startDate = moment().subtract(7, 'days').format('YYYYMMDD');
    },
    onEnd(val) {
      this.endDate = moment().format('YYYYMMDD');
    },
    period(val) {
      return val;
    },
    $route() {
      // if(!this.fromToday) {
      //   this.startDate =  moment().subtract(7, 'days').format('YYYYMMDD');
      //   this.endDate = moment().format('YYYYMMDD');
      // }

      // this.$emit('change', {
      //   start:this.startDate,
      //   end:this.endDate,
      // });
    }
  },
  methods: {
    digitFormat(date) {
      return (`0${date}`).slice(-2);
    },
    dateFormat(date) {
      return moment(date).format("DD[.]MM[.]YYYY");
    },
    addMonth() {
      this.dateContext = moment(this.dateContext).add(1, 'month');
    },
    subtractMonth() {
      this.dateContext = moment(this.dateContext).subtract(1, 'month');
    },
    handleDate(evt) {
      const { target } = evt;
      const date = target.getAttribute('data-time');

      this.setStartDate(target, date);
      this.setEndDate(target, date);
      
    },
    resetDate(evt) {
      const { target } = evt;
      const date = target.getAttribute('data-time');
      this.startDate = date;  
      this.endDate = date;
      this.$emit('change', {
        start:this.startDate,
        end:this.endDate,
      });
    },
    setStartDate(target, date) {
      if(this.forceDuration > 0) {
        this.startDate = date;
        this.endDate = moment(this.startDate, "YYYYMMDD").add(this.forceDuration, 'days').format("YYYYMMDD");
        this.$emit('change', {
          start:this.startDate,
          end:this.endDate,
        });
      } else {
        if(!this.startDate || date < this.startDate) {
          this.startDate = date;
          this.endDate = date;
          this.$emit('change', {
            start:this.startDate,
            end:this.endDate,
          });
        }
      }
    },
    setEndDate(target, date) {
      if(this.forceDuration === 0) {
        if(this.startDate && this.startDate < date) this.endDate = date;
        this.$emit('change', {
          start:this.startDate,
          end:this.endDate,
        });
      } else {
        
      }
    },
    setClass(date) {
      const className = [];
      if (this.startDate < date && this.endDate > date && this.startDate !== this.endDate) className.push('selected-date');
      if (this.startDate === date) className.push('start-date');
      if (this.endDate === date) className.push('end-date');
      if (this.fromToday && moment(this.today).format('YYYYMMDD') > moment(date).format('YYYYMMDD')) className.push('disabled');
      return className.join(' ');
    },
    setToday() {
      this.startDate = moment().format('YYYYMMDD');
      this.endDate = moment().format('YYYYMMDD');
      this.$emit('change', {
        start:this.startDate,
        end:this.endDate,
      });
    },
    setDates(start, end) {
      if(start) this.startDate = moment().subtract(Math.abs(start), 'days').format('YYYYMMDD'); 
      if(end) this.endDate = moment().subtract(Math.abs(end), 'days').format('YYYYMMDD'); 
      else this.endDate = this.startDate;
      this.$emit('change', {
        start:this.startDate,
        end:this.endDate,
      });
    },
    setMonth(start = 0) {
      this.startDate = moment().subtract(Math.abs(start), 'month').startOf('month').format('YYYYMMDD');
      this.endDate = moment().subtract(Math.abs(start), 'month').endOf('month').format('YYYYMMDD');
      this.$emit('change', {
        start:this.startDate,
        end:this.endDate,
      });
    },
    onClick(evt) {
      if(!this.readOnly) {
        const { target } = evt
        if (this.isVisible && !target.classList.contains('prevent') && !target.closest('.prevent')) this.$emit('close');
      }
    }
  },
  data() {
    return {
      startDate: moment().format('YYYYMMDD'),
      endDate: moment().format('YYYYMMDD'),
      calendar: [],
      today: moment(),
      dateContext: moment(),
      // days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    };
  },
  mounted() {
    if(this.forceDuration === 0) {
      this.startDate = !this.fromToday ? moment().subtract(7, 'days').format('YYYYMMDD') : moment().format('YYYYMMDD');
      this.endDate = moment().format('YYYYMMDD');
    } else {
      this.startDate = moment().format('YYYYMMDD');
      this.endDate = moment().add(this.forceDuration, 'days').format('YYYYMMDD');
    }
    
    this.$emit('change', {
      start:this.startDate,
      end:this.endDate,
    });
    document.addEventListener('keydown', evt => {
      if (this.isVisible && evt.keyCode === 27) this.$emit('close');
    })
  }
};
</script>

<style lang="scss">
  button.read-only,
  button.read-only * {
    cursor: default;
  }
</style>
