<template>
  <ul class="buttons">
    <li
      v-for="button in buttons"
      :key="`header-button-${button.label}`"
      class="buttons-el"
    >
      <a
        v-if="button.href"
        :href="button.href"
        class="button button-big"
        :class="button.classes"
      >
        {{ button.label }}
      </a>
      <button
        v-else
        @click="button.action()"
        class="button button-big"
        :class="button.classes"
        :disabled="button.isDisabled === undefined ? false : button.isDisabled"
      >
        {{ button.label }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    buttons: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  list-style: none;
  /* margin-top: -9px;
  margin-bottom: -9px; */

  &-el {
    &:not(:first-child) {
      margin-left: 24px;
    }

    * {
      display: block;
    }

    .book-button {
      min-width: 200px;
    }
  }
}
</style>