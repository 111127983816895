<template>
    <!-- class="main-left__result result parking-thumb" -->
  <div
    class="result parking-thumb"
    @mouseenter="$emit('mouse_enter', parking)"
    @mouseleave="$emit('mouse_leave', parking)"
  >
    <address class="result--left">
      <h3 v-if="isService" class="h4">{{ parking.name }}</h3>
      <h3 v-else class="h4">
        {{ parking.internationalName || parking.localName }}
        <button
          v-if="parking.securityLevel && parking.securityLevelId !== 6 && isDetail"
          class="security"
          :title="$t('globals.more_info')"
          @click="openSecurityLevels"
        >
          {{ translations[`${parking.securityLevel.translationKey}.name`] }}
        </button>
      </h3>
      <p>{{ parking.address.street }} {{ parking.address.zipCode }} {{ parking.address.city }}, {{parking.address.country.name}}</p>
      <p>{{ $t('labels.gps_coordinates') }} {{ parking.address.geolocationLatitude }}, {{ parking.address.geolocationLongitude }}</p>
    </address>

    <div class="result--right">
      <div v-if="parking.securityLevel && parking.securityLevelId !== 6 && !isDetail" class="security-container">
        <button
          class="security"
          :title="$t('globals.more_info')"
          @click="openSecurityLevels"
        >
          {{ translations[`${parking.securityLevel.translationKey}.name`] }}
        </button>
      </div>
      <span v-if="!isService && parking.isBookable" class="result-from">{{ $t('globals.from') }} <span class="result-from__price">{{ parking.fromPrice || parking.bestPrice || parking.minimalPrice || 0 }}€</span></span>
    </div>

    <div
      v-if="!isService"
      class="result-footer"
    >
      <span class="result-info">
        <Sprite :type="'parking-i'" :width="8" :height="10" />
        {{ parking.totalSlotNumber }}
      </span>
      <ul class="result-footer-slots">
        <template v-for="(slot, index) in parkingSlots">
          <li
            v-if="slot.otraSlotNumber && slotType[slot.slotId]"
            class="result-info"
            :key="index" 
          >
            <span class="result-info__type">
            {{ $t(`components.slot_type.${slotType[slot.slotId]}`) }}
            </span>: {{ slot.otraSlotNumber }}
          </li>
        </template>
      </ul>
    </div>

    <div
      v-if='$slots.actions'
      class="result-actions"
    >
      <slot name="actions"/>
    </div>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  name: "ParkingThumb",
  components: {
    Sprite
  },
  computed: {
    slotType() {
      return this.$store.state.parkings.slots;
    },
    translations() {
      return this.$store.state.translations.keys;
    },
    parkingSlots() {
      let slots = [...this.parking.parkingSlots];
      slots = slots.sort((a, b) => {
        if(a.slotId < b.slotId) return -1;
        if(a.slotId > b.slotId) return 1;
        return 0;
      });

      return slots;
    }
  },
  props: {
    parking: {
      type: Object
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isService: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openSecurityLevels() {
      const index = this.parking.securityLevel ? 4 - this.parking.securityLevel.id : 0;
      this.$store.commit('securityLevels/updateIndex', index);
      this.$store.commit('securityLevels/toggle');
    }
  }
}
</script>

<style lang="scss">
  .result {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // cursor: pointer;

    &--left {
      width: calc(100% - 105px);
      color: $grey;
      @extend %p;
      font-size: rem(14px);

      .h4 {
        .security {
          display: inline-block;
          margin-left: 5px;
        }
      }

      p {
        margin-top: 10px;
        color: #000;
        + p {
          margin-top: 0;
        }
      }
    }

    &--right {
      margin-left: 20px;
    }

    .svg-security  {
      fill: $white;
      margin-right: 5px;
    }

    .security {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 7px 12px 6px 12px;
      border: 1px solid $mediumgreen;
      border-radius: 8px;
      background-color: $white;
      color: $mediumgreen;
      font-family: $primary;
      font-weight: 600;
      font-style: italic;
      font-size: rem(14px);
      text-transform: uppercase;
      line-height: 1;
      transition: color .3s $ease-out-quart, background-color .3s $ease-out-quart;
      cursor: pointer;

      &:hover {
        color: $white;
        background-color: $mediumgreen;
      }

      &-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        + .result-from {
          margin-top: 12px;
        }
      }
    }

    &-from {
      display: block;
      text-align: center;
      @extend %p;
      font-size: rem(12px);

      &__price {
        margin-top: 6px;
        display: block;
        font-weight: 700;
        font-size: rem(24px);
      }
    }

    &-footer {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin-top: 10px;
      list-style: none;
      @extend %p;
      line-height: 1;
      font-size: rem(14px);

      .svg-parking-i {
        background: $darkblue;
        fill: $white;
        padding: 3px 4px;
        border-radius: 3px;
        margin-right: 11px;
      }

      &-slots {
        display: flex;
        flex-wrap: wrap;
        // padding-top: 1px;

        .result-info {
          padding-top: 1px;
          padding-bottom: 1px;
        }
      }
    }

    &-info {
      display: flex;
      align-items: center;
      flex-shrink: none;
      margin-right: 16px;

      &__type {
        color: $darkblue;
      }
    }

    &-actions {
      display: flex;
      width: 100%;
      margin-top: 24px;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(50% - 9px);
        padding-top: 12px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        /* white-space: nowrap; */

        + .button {
          margin-left: 18px;
        }

        &[disabled] {
          background-color: $mediumgrey;
        }

        .icon-button {
          width: auto;
          height: 20px;
          vertical-align: middle;
          margin-top: -5px;
          margin-right: 5px;
        }

        .svg {
          vertical-align: middle;
          margin-top: -2px;
          fill: $white;
          margin-right: 5px;
        }
      }
    }
  }
</style>
