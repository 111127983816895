import { createRouter, createWebHistory } from 'vue-router'

import Page from '@/views/Page';
import Terms from '@/views/Terms';
import List from '@/views/Booking';
import Search from '@/views/Search';
import Trucks from '@/views/Trucks';
import Drivers from '@/views/Drivers';
import Planners from '@/views/Planners';
import Favorites from '@/views/Favorites';
import Reservations from '@/views/Reservations';

import TW from '@/views/tw/Index';
import TWStepDetail from '@/views/tw/StepDetail';
import TWStepReview from '@/views/tw/StepReview';
import TWStepDriverTruck from '@/views/tw/StepDriverTruck';

import TC from '@/views/tc/Index';
import TCStepDetail from '@/views/tc/StepDetail';
import TCStepReview from '@/views/tc/StepReview';
import TCStepDriverTruck from '@/views/tc/StepDriverTruck';

import Authentication from '@/views/Authentication.vue';
import EditProfile from '@/views/profile/Edit';
import LandingDetail from '@/views/MobilePage';
import NapXmlList from '@/views/NapXmlList';
import SnapBooking from '@/views/snap/SnapBooking.vue';
import SnapListBooking from '@/views/snap/SnapListBooking.vue';
import SnapErrorPage from '@/views/snap/SnapErrorPage.vue';
import SampleRedirect from '@/views/SampleRedirect';
const routes = [
  // {
  //   path: '/',
  //   name: 'Search',
  //   component: Search, 
  //   meta: {
  //     titleTemplate: 'pages.search.page_title',
  //     menuName: 'pages.search.sidebar_title',
  //     icon: 'search'
  //   },
  //   children: [
  //     {
  //       path: '/favorites',
  //       name: 'Favorites',
  //       component: Favorites, 
  //       meta: {
  //         titleTemplate: 'pages.favorites.page_title',
  //         menuName: 'pages.favorites.sidebar_title',
  //         icon: 'favorite',
  //         components: {
  //           QuickSearch: true
  //         }
  //       },
  //     }
  //   ]
  // },
  // {
  //   path: '/tw/:id',
  //   name: 'TW',
  //   component: TW, 
  //   meta: {
  //     titleTemplate: 'pages.tw.page_title',
  //     hide: true
  //   },
  //   children: [
  //     {
  //       path: '/tw/:id/reservation/detail',
  //       name: 'TWStepDetail',
  //       component: TWStepDetail, 
  //       meta: {
  //         titleTemplate: 'pages.tw.steps.detail.page_title',
  //         icon: 'favorite'
  //       },
  //     },
  //     {
  //       path: '/tw/:id/reservation/driver-truck',
  //       name: 'TWStepDriverTruck',
  //       component: TWStepDriverTruck, 
  //       meta: {
  //         titleTemplate: 'pages.tw.steps.drivertruck.page_title',
  //         icon: 'favorite'
  //       },
  //     },
  //     {
  //       path: '/tw/:id/reservation/review',
  //       name: 'TWStepReview',
  //       component: TWStepReview, 
  //       meta: {
  //         titleTemplate: 'pages.tw.steps.recap.page_title',
  //         icon: 'favorite'
  //       },
  //     }
  //   ]
  // },
  // {
  //   path: '/tc/:id',
  //   name: 'TC',
  //   component: TC, 
  //   meta: {
  //     titleTemplate: 'pages.tc.page_title',
  //     hide: true
  //   },
  //   children: [
  //     {
  //       path: '/tc/:id/reservation/detail',
  //       name: 'TCStepDetail',
  //       component: TCStepDetail, 
  //       meta: {
  //         titleTemplate: 'pages.tc.steps.detail.page_title',
  //         icon: 'favorite'
  //       },
  //     },
  //     {
  //       path: '/tc/:id/reservation/driver-truck',
  //       name: 'TCStepDriverTruck',
  //       component: TCStepDriverTruck, 
  //       meta: {
  //         titleTemplate: 'pages.tc.steps.drivertruck.page_title',
  //         icon: 'favorite'
  //       },
  //     },
  //     {
  //       path: '/tc/:id/reservation/review',
  //       name: 'TCStepReview',
  //       component: TCStepReview, 
  //       meta: {
  //         titleTemplate: 'pages.tc.steps.recap.page_title',
  //         icon: 'favorite'
  //       },
  //     }
  //   ]
  // },
  // {
  //   path: '/drivers',
  //   name: 'Drivers',
  //   component: Drivers, 
  //   meta: {
  //     titleTemplate: 'pages.drivers.page_title',
  //     subtitle: 'pages.drivers.page_subtitle',
  //     icon: 'drivers',
  //     components: {
  //       // Pagination: true,
  //       QuickSearch: true
  //     }
  //   },
  // },
  // {
  //   path: '/trucks',
  //   name: 'Trucks',
  //   component: Trucks, 
  //   meta: {
  //     titleTemplate: 'pages.trucks.page_title',
  //     subtitle:'pages.trucks.page_subtitle',
  //     icon: 'car',
  //     components: {
  //       // Pagination: true,
  //       QuickSearch: true
  //     }
  //   },
  // },
  // {
  //   path: '/bookings/',
  //   name: 'Booking',
  //   component: List, 
  //   meta: {
  //     titleTemplate: 'pages.bookings.page_title',
  //     menuName: 'pages.bookings.sidebar_title',
  //     icon: 'accounting',
  //     components: {
  //       Calendar: true,
  //       // Pagination: true,
  //       QuickSearch: true,
  //       Approvement: true,
  //       PlannersSelect: true
  //     }
  //   },
  //   children: [
  //     {
  //       path: '/bookings',
  //       name: 'List',
  //       component: List, 
  //       meta: {
  //         titleTemplate: 'pages.bookings.awaiting.page_title',
  //       },
  //       params: {
  //         paymentStatus: 'pending'
  //       }
  //     },
  //     {
  //       path: '/bookings',
  //       name: 'List',
  //       component: List, 
  //       meta: {
  //         titleTemplate: 'pages.bookings.approved.page_title',
  //       },
  //       params: {
  //         paymentStatus: 'accepted'
  //       }
  //     },
  //     // {
  //     //   path: '/bookings',
  //     //   name: 'List',
  //     //   component: List, 
  //     //   meta: {
  //     //     titleTemplate: 'Decline',
  //     //   },
  //     //   params: {
  //     //     paymentStatus: 'rejected'
  //     //   }
  //     // },
  //   ]
  // },
  // {
  //   path: '/reservations/',
  //   name: 'Reservations',
  //   component: Reservations, 
  //   meta: {
  //     titleTemplate: 'pages.reservations.page_title',
  //     menuName: 'pages.reservations.sidebar_title',
  //     icon: 'accounting',
  //     components: {
  //       Calendar: true,
  //       QuickSearch: true,
  //       Approvement: true,
  //       PlannersSelect: true
  //     }
  //   },
  //   children: [
  //     {
  //       path: '/reservations',
  //       name: 'Reservations',
  //       component: Reservations, 
  //       meta: {
  //         titleTemplate: 'pages.reservations.tw.page_title',
  //       },
  //       params: {
  //         service: 'tw'
  //       }
  //     },
  //     {
  //       path: '/reservations',
  //       name: 'Reservations',
  //       component: Reservations, 
  //       meta: {
  //         titleTemplate: 'pages.reservations.tc.page_title',
  //       },
  //       params: {
  //         service: 'tc'
  //       }
  //     },
  //   ]
  // },
  // {
  //   path: '/planners',
  //   name: 'Planners',
  //   component: Planners, 
  //   meta: {
  //     titleTemplate: 'pages.planners.page_title',
  //     icon: 'settings',
  //     admin: true
  //   },
  // },
  // {
  //   path: '/lost-password',
  //   name: 'LostPassword',
  //   component: Authentication, 
  //   hidden: true,
  //   meta: {
  //     hide: true,
  //   }
  // },
  // {
  //   path: '/reset-password',
  //   name: 'ResetPassword',
  //   component: Authentication, 
  //   hidden: true,
  //   meta: {
  //     hide: true,
  //   }
  // },

  // // Static page - To change, now it's just to remove warning in console
  // // {
  // //   path: '/privacy-policy',
  // //   name: 'PrvacyPolicy',
  // //   component: Page,
  // //   meta: {
  // //     titleTemplate: 'pages.privacy_policy.page_title',
  // //     bodyClass: 'body-page',
  // //     layout: 'page',
  // //   }
  // // },
  // {
  //   path: '/profile/edit',
  //   name: 'EditProfile',
  //   component: EditProfile,
  //   meta: {
  //     titleTemplate: 'pages.profile.page_title',
  //     // layout: 'page',
  //     hide: true
  //   }
  // },
  // {
  //   path: '/terms',
  //   name: 'Terms',
  //   component: Terms,
  //   hidden: true,
  //   meta: {
  //     titleTemplate: 'pages.terms.page_title',
  //     bodyClass: 'body-page',
  //     layout: 'page',
  //   }
  // },
  // {
  //   path: '/landing-detail/:id',
  //   name: 'LandingDetail',
  //   component: LandingDetail, 
  //   hidden: true,
  //   meta: {
  //     hide: true,
  //     layout: 'no-layout',
  //   }
  // },
  // {
  //   path: '/sample-page',
  //   name: 'SampleRedirect',
  //   component: SampleRedirect, 
  //   hidden: true,
  //   meta: {
  //     hide: true,
  //     layout: 'no-layout',
  //   }
  // },
  {
    path: '/napApi',
    name: 'Nap XML List',
    component: NapXmlList, 
    hidden: true,
    meta: {
      hide: true,
      layout: 'no-layout',
    }
  },
  {
    path: '/snap_booking',
    name: 'Snap Booking',
    component: SnapBooking,
    hidden: true,
    meta: {
      hide: true,
      layout: 'none',
    }
  },
  {
    path: '/snap_listbooking',
    name: 'SnapListBooking',
    component: SnapListBooking, 
    meta: {
      hide: true,
      //titleTemplate: 'pages.bookings.page_title',
      components: {
        Calendar: true,
        Pagination: false,
        QuickSearch: true,
        // Approvement: true,
        // PlannersSelect: true
      },
      layout: 'snap-layout',
    },
    // children: [
    //   {
    //     path: '/snap_listbooking',
    //     name: 'SnapListBooking',
    //     component: SnapListBooking, 
    //     meta: {
    //       titleTemplate: 'pages.bookings.awaiting.page_title',
    //       layout: 'snap-layout',
    //     },
    //     params: {
    //       paymentStatus: 'pending'
    //     }
    //   },
    //   {
    //     path: '/snap_listbooking',
    //     name: 'SnapListBooking',
    //     component: SnapListBooking, 
    //     meta: {
    //       titleTemplate: 'pages.bookings.approved.page_title',
    //       layout: 'snap-layout',
    //     },
    //     params: {
    //       paymentStatus: 'accepted'
    //     }
    //   }
    // ]
  },
  {
    path: '/snaperror',
    name: 'SnapErrorPage',
    component: SnapErrorPage,
    props: (route) => ({ errorMessage: route.query.error }),
    meta: {
      layout: 'none',
      
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
