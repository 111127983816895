import { getFleet } from '@/calls/fleet';

const fleet = {
  namespaced: true,
  state: {
    userProfile: null,
    current: {},
    requests: 0
  },
  mutations: {
    updateFleet(state, fleet) {
      state.current = fleet;
    },
    updateFleetProperty(state, { property, value }) {
      state.current[property] = value;
    },
    updateUser(state, user) {
      state.userProfile = user;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    // Currently not use (route is blocked when fleet-planner)
    set({ commit, dispatch }, user) {
      commit('increaseRequests');
      const fleet = getFleet();

      if (!fleet.hasError) {
        commit('updateFleet', fleet);
      } else {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting fleet : ${fleet.error}`,
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async getFlex({ state, commit, dispatch }) {
      commit('increaseRequests');
      const fleet = await getFleet();

      if (!fleet.hasError) {
        commit('updateFleetProperty', {
          property: 'isFlex',
          value: fleet.isFlex
        });
      } else {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting fleet : ${fleet.error}`,
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    }
    // user({ commit }) {
    //   const user = getUser();
    //   return user.then((promise) => {
    //     commit('updateUser', promise);
    //     return promise
    //   })
    // }
  }
};


export default fleet;
