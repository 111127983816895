import axios from 'axios';

// const isProduction = location.origin.match('local') ? process.env.VUE_APP_API_URL_LOCAL : process.env.VUE_APP_API_URL;
// axios.defaults.baseURL = isProduction;

let baseURL = location.origin.match('dev') ? process.env.VUE_APP_API_URL_LOCAL : process.env.VUE_APP_API_URL;
if (location.origin.match('weareotra')) baseURL = process.env.VUE_APP_API_URL_PROD;
if (location.origin.match('paint')) baseURL = process.env.VUE_APP_API_URL_PAINT;

// let baseURL = process.env.VUE_APP_API_URL;
// if (process.env.VUE_APP_ENVNAME === 'dev') baseURL = process.env.VUE_APP_API_URL_LOCAL;
// if (process.env.VUE_APP_ENVNAME === 'production') baseURL = process.env.VUE_APP_API_URL_PROD;

axios.defaults.baseURL = baseURL;
axios.defaults.headers.common = {
  "X-Api-Key": process.env.VUE_APP_API_KEY,
  // Authorization: `Bearer ${process.env.VUE_APP_API_KEY}`
};

// const getLanguages = async () => {
//   return await axios.get('/settings/langs')
//   .then((promise) => {
//     if(promise.status === 200) return promise.data
//   }).catch(error => {
//     console.log(error);
//   });
// }
const getCountries = async () => {
  return await axios.get('/settings/countries/select')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
}
const getCurrencies = async () => {
  return await axios.get('/settings/currencies')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const getSettings = async () => {
  return await axios.get('/settings')
  .then(promise => { 
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
}

const getContext = async () => {
  return await axios.get('/users/context')
    .then((promise) => {
      if(promise.status === 200) return promise.data
    }).catch(error => {
      console.log('error', error);
      return {
        hasError: true,
        error
      }
    });
}

const getTranslations = async (lang = 'en', key) => {
  return await axios.get(`/settings/translations/${lang}`)
    .then((promise) => {
      if(promise.status === 200) return promise.data
    }).catch(error => {
      console.log('error', error);
      return {
        hasError: true,
        error
      }
    });
};

const getTranslation = async (lang = 'en', key) => {
  return await axios.get(`/settings/translations/${lang}/${key}`)
    .then((promise) => {
      if(promise.status === 200) return promise.data
    }).catch(error => {
      console.log('error', error);
      return {
        hasError: true,
        error
      }
    });
};

export { 
  // getLanguages,
  getCountries,
  getCurrencies,
  getSettings,
  getContext,
  getTranslations,
  getTranslation
};
