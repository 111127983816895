import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://router.hereapi.com/v8/',
  params: {
    apiKey: process.env.VUE_APP_HERE_API_KEY
  }
});

// BX
// Lat : 50.85045
// Long : 4.34878
// Arlon
// Lat : 49.68333
// Long : 5.81667

// Home
// 50.669205, 5.087718
// Thierry
// 50.597795, 5.354793

// BX
// 50.845250, 4.370138
// Liege
// 50.656601,5.529341


const searchRoute = async (params) => {
  try {
    const request = await instance.get(`routes?transportMode=truck&return=polyline,summary&${params}`, {
      // params: {
      //   transportMode: 'truck',
      //   return: 'polyline',
      //   // ...params
      //   // origin: '50.845250,4.370138',
      //   // destination: '50.656601,5.529341',
      // }
    });

    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    }
  }
}

export {
  searchRoute
};