<template>
  <table class="table" cellspacing="0">
    <thead>
      <tr>
        <th class="small head">Filename</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="file in files" 
        :key="file"
      > 
        <td>
          <a
            class="small"
            target="_blank"
            :href="`/napxmls/${file}`"
            download
          >
            {{ file }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: 'NapXMLList',
  computed: {
    files() {
      return this.$store.state.napapifiles.filenames;
    },
  },
  beforeMount() {
    this.$store.dispatch('napapifiles/getNapXMLFiles');
  },
}

</script>