import i18n from '@/i18n';

export function setLanguage(lang, store) {

    let locale = 'en'
    if (lang) {
        locale = lang.toLowerCase();
    }
    i18n.global.locale = locale;
    if (store) {
        store.commit('user/updateFormValue', { value : locale, property: 'locale' });
    }
    window.localStorage.setItem('otra-locale', locale);
}