<template>
  <div :class="`collapsing-step${open ? ' is-open' : ''}${checked ? ' is-checked' : ''}${isDisabled ? ' is-disabled' : ''}`">
    <div class="cs-header">
      <div class="cs-index">
        <span class="cs-index-text">{{ index }}</span>
        <Sprite
          :type="'check'"
          :width="22"
          :height="17"
        />
      </div>
      <h3 class="cs-title">{{ title }} {{ value ? '-' : null }}</h3>
      <span v-if="value" class="cs-value">{{ value }}</span>

      <div class="cs-toggle">
        <Sprite
          :type="open ? 'chevron-up-round' : 'chevron-down-round'"
          :width="22"
          :height="17"
        />
      </div>

      <button
        @click.prevent.stop="onToggle"
        class="cs-button"
      />
    </div>
    <div v-if="open && !isDisabled" class="cs-content">
      <div v-show="loading" class="cs-content-loader">
        <Loader/>
      </div>
      <div v-show="!loading">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Loader,
    Sprite
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    title: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    open(next, prev) {
      if (next && next !== prev) {
        this.$emit('onOpen');
      }
    }
  },
  methods: {
    onToggle() {
      this.$emit('onToggle');
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsing-step {
  border: 1px solid rgba($grey, .5);
  max-width: 1132px;
  overflow: hidden;

  &.is-open,
  &.is-checked {
    border-color: rgba($mediumgreen, .5);
  }

  &.is-checked {
    .cs-index {
      background-color: $mediumgreen;

      .cs-index-text { display: none; }
      .svg { display: block; }
    }
  }

  &.is-disabled {
    opacity: .4;
    pointer-events: none;
  }

  .cs-header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px;
  }

  .cs-index {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: $darkblue;

    &-text {
      margin-top: -2px;
      color: $white;
      font-family: $primary;
      font-weight: bold;
      font-size: 22px;
      line-height: 1;
    }

    .svg {
      display: none;
      fill: $white;
      margin-left: 2px;
      margin-top: 1px;
    }
  }

  .cs-title {
    margin-left: 25px;
    color: $darkblue;
    font-family: $primary;
    font-style: italic;
    font-weight: $medium;
    font-size: rem(20px);
    line-height: 1;
    text-transform: uppercase;
  }

  .cs-value {
    position: relative;
    margin-left: 5px;
    color: $mediumgrey;
    font-family: $primary;
    font-style: italic;
    font-weight: $medium;
    font-size: rem(18px);
    line-height: 1;
    text-transform: uppercase;
  }

  .cs-toggle {
    padding: 12px 10px;
    margin-left: auto;
    cursor: pointer;

    .svg {
      fill: $darkblue;
    }
  }

  .cs-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .cs-content {
    padding: 7px 17px 17px;
    /* overflow: auto; */
    overflow: hidden;
    max-height: calc(100% - 78px);

    &-loader {
      display: flex;
      justify-content: center;
    }
  }
}
</style>