import { createApp } from 'vue'

import App from './App.vue'
import './registerServiceWorker'

import router from './router';
import store from './store';
import i18n from '@/i18n';
import Pagination from 'v-pagination-3';

import { 
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});
const app = createApp(App)
      app.component('v-pagination', Pagination)
      app.use(store)
      app.use(router)
      app.use(i18n);
      app.mount('#app')


