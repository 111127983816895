import i18n from '../i18n';

import {
  getService,
  createReservation
} from '@/calls/services';

const tw = {
  namespaced: true,
  state: {
    id: null,
    service: null,
    services: null,
    reservation: {
      type: null,
      package: null,
      date: null,
      hour:  null,
      startAt: null,
      options: [],
      driver: null,
      truck: null
    },
    requests: 0
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateService(state, service) {
      state.service = service;
    },
    updateServices(state, services) {
      state.services = services;
    },
    updateReservation(state, { property, value }) {
      state.reservation[property] = value;
    },
    resetReservation(state) {
      state.reservation = {
        type: null,
        package: null,
        date: null,
        hour:  null,
        startAt: null,
        options: [],
        driver: null,
        truck: null
      };
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    async getService({ state, commit, dispatch }) {
      commit('increaseRequests');

      const request = await getService(state.id);

      if (!request.hasError) {
        commit('updateService', request);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.services.tw.getting'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    },
    async createReservation({ state, commit, dispatch, rootState }) {
      commit('increaseRequests');
      const { reservation } = state;
      const description = {
        driver: {
          id: reservation.driver.value,
          name: reservation.driver.label,
          phoneNumber: reservation.driver.phoneNumber
        },
        truck: {
          id: reservation.truck.value,
          name: reservation.truck.label,
          licenseCountry: rootState.trucks.item.licenseCountry,
          licensePlate: rootState.trucks.item.licensePlate
        }
      };

      const request = await createReservation({
        type: 2,
        serviceId: state.service.id,
        packageId: reservation.package.id,
        options: [...reservation.options.map(o => o.id)],
        startAt: reservation.startAt,
        description: JSON.stringify(description)
      });

      const { t } = i18n.global;

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.services.tc.creation'),
          type: 'success'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.services.tc.creation'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
      return request;
    }
  }
};

export default tw;
