<template>
  <div>
    <button :class="(readOnly ? 'read-only' : false)">
      <time @click="onClick" class="h4" :datetime="today">
        {{ today }}
        <Sprite type="arrow" :width="15" :height="10" v-if="!readOnly"/>
      </time>
    </button>

    <!-- Calendar -->
    <Calendar 
      @change="handleDates"
      @close="onClick"
      :isVisible="calendarVisibility"
      :fromToday="fromToday"
      :forceDuration="forceDuration"
      :readOnly="readOnly"
    />
  </div>
</template>

<script>
import moment from 'moment';
import _debounce from 'lodash/debounce';

import Sprite from '@/components/elements/Sprite.vue';
import Calendar from '@/components/modules/Calendar.vue';

export default {
  name: 'DateCalendar',
  components: {
    Sprite,
    Calendar,
  },
  props: {
    fromToday: {
      type: Boolean,
      default: false
    },
    forceDuration: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filtersStored() {
      return this.$store.state.siBookings.filters;
    }
  },
  data() {
    if(this.forceDuration > 0) {
      return {
        today: moment().format("DD[.]MM[.]YYYY") + ' - ' + moment().add(this.forceDuration, 'days').format("DD[.]MM[.]YYYY"),
        calendarVisibility: false,
        filters: {}
      }
    }
    return {
      today: moment().subtract(7, 'days').format("DD[.]MM[.]YYYY") + ' - ' + moment().format("DD[.]MM[.]YYYY"),
      calendarVisibility: false,
      filters: {}
    }
    
  },
  watch: {
    $route() {
      if(this.forceDuration > 0) {
        this.today = moment().format("DD[.]MM[.]YYYY") + ' - ' + moment().add(this.forceDuration, 'days').format("DD[.]MM[.]YYYY");
      } else {
        this.today = moment().subtract(7, 'days').format("DD[.]MM[.]YYYY") + ' - ' + moment().format("DD[.]MM[.]YYYY");
      }
      
    },
  },
  mounted() {
    this.filters = this.filtersStored; 
  },
  methods: {
    // ON
    onClick() {
      if(!this.readOnly) {
        this.calendarVisibility = !this.calendarVisibility
      }
    },
    handleDates(dates, end = '') {
        this.$emit('change', dates);
        this.filters.dates = dates;
        const start = moment(dates.start).format("DD[.]MM[.]YYYY");
        if(dates.end && dates.start != dates.end) end = `${moment(dates.end).format("DD[.]MM[.]YYYY")}`;
        this.today = start + `${end ? ' - ' + end : ''}`;
        this.filters.page = 1;

        this.$store.commit('siBookings/updateDynamicFilters', this.filters);

        this.debouncedDispatch();
      // }
    },
    debouncedDispatch: _debounce(function(value) {
        this.$store.dispatch('siBookings/setBookings');
    }, 300),
    // HANDLE
  }
}
</script>


<style lang="scss" scoped>
time {
  margin-bottom: 0;
}
</style>
