const drivers = {
  namespaced: true,
  state: {
    search: null,
    // search: {
    //   placeholder: null,
    //   onInput: null
    // },
    buttons: [],
    download: null
  },
  mutations: {
    updateButtons(state, buttons) {
      state.buttons = buttons;
    },
    updateSearch(state, search) {
      state.search = search;
    },
    updateDownload(state, download) {
      state.download = download;
    },
    resetSearch(state) {
      state.search = null;
      state.search = {
        placeholder: null,
        onInput: null
      };
    }
  },
  actions: {
  }
};

export default drivers;
