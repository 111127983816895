import axios from 'axios';

const getTrucks = async (search) => {
  return await axios.get('/fleet/trucks', {
    params: { search }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const getTruck = async (id) => {
  return await axios.get(`/fleet/trucks/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const addTruck = async (params) => {
  return await axios.post('/fleet/trucks', params)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const removeTruck = async (truck) => {
  return await axios.delete(`/fleet/trucks/${truck.id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const updateTruck = async (truck) => {
  return await axios.patch(`/fleet/trucks/${truck.id}`, {
    name: truck.name,
    licensePlate: truck.licensePlate,
    licenseCountry: truck.licenseCountry,
    brand: truck.brand,
    model: truck.model,
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const getSelectTrucks = async () => {
  return await axios.get('/fleet/trucks/select')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const checkTruckAccount = async (id, params) => {
  return await axios.get(`/fleet/trucks/${id}/account`, { params })
  .then((promise) => {
    return promise;
  }).catch(error => {
    return {
      hasError: true,
      error: error.response && error.response.data ? error.response.data : error
    }
  });
};

// This call will also create the account if it doesn't exist
const linkTruckAccount = async (id, params) => {
  return await axios.post(`/fleet/trucks/${id}/account`, params)
  .then((promise) => {
    return promise;
  }).catch(error => {
    return {
      hasError: true,
      error: error.response && error.response.data ? error.response.data : error
    }
  });
};

const unlinkTruckAccount = async (id, positionId) => {
  return await axios.post(`/fleet/trucks/${id}/account/${positionId}/detach`)
  .then((promise) => {
    return promise;
  }).catch(error => {
    return {
      hasError: true,
      error: error.response && error.response.data ? error.response.data : error
    }
  });
};

export {
  getTrucks,
  getSelectTrucks,
  addTruck,
  removeTruck,
  updateTruck,
  getTruck,
  checkTruckAccount,
  linkTruckAccount,
  unlinkTruckAccount
};
