<template>
  <section
    class="main-container"
    ref="container"
    :class="{'no-result': !trucks || !trucks.length, 'main-container--loading': loading }"
  >
    <table
      v-if="true"
      class="table"
      cellspacing="0"
    >
      <!-- TABLE HEAD -->
      <Thead :head="theadTrucks" />
      
      <!-- TABLE BODY -->
      <tbody>
        <tr 
          v-for="truck in trucks"
          :key="truck.id"
          :id="'book-'+ trucks.id"
        > 
          <TBody 
            v-for="(b, index) in tbodyTrucks"
            :key="index"
            :body="b" 
            :el="truck" 
          />
        </tr>
      </tbody>
    </table>
    <!-- NO RESULTS -->
    <NoResult v-else >
      <slot>{{ message }}</slot>
    </NoResult>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>

    <!-- Add popup -->
    <popup
      v-if="popupNew.visible"
      :classes="'popup-new'"
    >
      <template v-slot:content>
        <template v-if="popupNew.success">
          <h2 class="popup-title">{{ $t('globals.success') }}</h2>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('trucks.popups.add.success') }}</p>
            <button
              class="button button-big"
              @click="closePopup('new')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <!-- Form -->
        <template v-else>
          <h2 class="popup-title">{{ $t('trucks.popups.add.title') }}</h2>
          <form
            class="popup-form"
            novalidate
            @submit.prevent="addNew"
          >
            <inputs
              :type="'text'"
              :placeholder="`${$t('trucks.popups.labels.name')}*`"
              :label="`${$t('trucks.popups.labels.name')}*`"
              :value="truck.name"
              :error="popupNew.errors.name"
              @input="onInput($event, 'name')"
            />

            <Select
              :label="`${$t('trucks.popups.labels.license_plate_country')}*`"
              :placeholder="$t('trucks.popups.labels.select_country')"
              :value="truck.licenseCountry"
              :options="countries"
              :error="popupNew.errors.licenseCountry"
              :class="'select-input'"
              @onChange="onInput($event, 'licenseCountry')"
            />

            <inputs
              :type="'text'"
              :label="`${$t('trucks.popups.labels.license_plate')}*`"
              :placeholder="$t('trucks.popups.labels.license_plate')"
              :value="truck.licensePlate"
              :error="popupNew.errors.licensePlate"
              @input="onInput($event, 'licensePlate')"
            />

            <inputs
              :type="'text'"
              :placeholder="$t('trucks.popups.labels.brand_truck')"
              :label="$t('trucks.popups.labels.brand')"
              :value="truck.brand"
              :counting="20"
              :error="popupNew.errors.brand"
              @input="onInput($event, 'brand')"
            />

            <inputs
              :type="'text'"
              :placeholder="$t('trucks.popups.labels.model_truck')"
              :label="$t('trucks.popups.labels.model')"
              :value="truck.model"
              :counting="10"
              :error="popupNew.errors.model"
              @input="onInput($event, 'model')"
            />

            <div class="popup-actions">
              <button
                type="button"
                class="button button-border"
                @click.prevent="closePopup('new')"
              >
                {{ $t('globals.close') }}
              </button>
              <button
                type="submit"
                class="button"
                :disabled="disableAdd"
              >
                {{ $t('globals.add') }}
              </button>
            </div>
          </form>
        </template>

        <div
          v-show="popupNew.requests !== 0"
          class="popup-loader"
        >
          <loader />
        </div>
      </template>
    </popup>

    <!-- Add account popup -->
    <popup
      v-if="popupAccount.visible"
      :classes="'popup-new'"
    >
      <template v-slot:content>
        <template v-if="popupAccount.success">
          <h2 class="popup-title">{{ $t('globals.success') }}</h2>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('trucks.popups.account.success') }}</p>
            <button
              class="button button-big"
              @click="closePopup('account')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <template v-else-if="popupAccount.accountFound">
          <h2 class="popup-title">{{ $t('trucks.popups.account.title') }}</h2>
          <div class="popup-main">
            <p class="popup-main-text">{{ $t('trucks.popups.account.found', { name: popupAccount.accountFound.user.fullName }) }}</p>
          </div>
          <div class="popup-actions">
            <button
              type="button"
              class="button button-border"
              @click.prevent="closePopup('account')"
            >
              {{ $t('globals.close') }}
            </button>
            <button
              class="button"
              @click="confirmAccount"
            >
              {{ $t('globals.link-account') }}
            </button>
          </div>
        </template>
        <!-- Form -->
        <template v-else>
          <h2 class="popup-title">{{ $t('trucks.popups.account.title') }}</h2>
          <form
            class="popup-form"
            novalidate
            @submit.prevent="addAccount"
          >
            <Inputs
              :type="'text-prefix'"
              :placeholder="`${$t('labels.mobile_phone_ex')}`"
              :label="`${$t('labels.mobile_phone')}`"
              :value="unformattedPhone"
              :error="popupAccount.errors.phone"
              :prefix="'+'"
              :disabled="popupAccount.confirm"
              @input="onAccountInput($event, 'phone')"
            />

            <Inputs
              v-if="popupAccount.confirm"
              :type="'text'"
              :placeholder="`${$t('labels.fullname')}*`"
              :label="`${$t('labels.fullname')}*`"
              :value="popupAccount.form.fullname"
              :error="popupAccount.errors.fullname"
              @input="onAccountInput($event, 'fullname')"
            />

            <div class="popup-actions">
              <button
                type="button"
                class="button button-border"
                @click.prevent="closePopup('account')"
              >
                {{ $t('globals.close') }}
              </button>
              <button
                type="submit"
                class="button"
                :disabled="disableLinkDriver"
              >
                {{ $t('globals.link-account') }}
              </button>
            </div>
          </form>
        </template>

        <div
          v-show="popupAccount.requests !== 0"
          class="popup-loader"
        >
          <loader />
        </div>
      </template>
    </popup>

    <!-- Remove account popup -->
    <popup
      v-if="popupRemoveAccount.visible"
      :classes="'popup-remove'"
    >
      <template v-slot:content>
        <template v-if="popupRemoveAccount.success">
          <!-- <h2 class="popup-title">Success!</h2> -->
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('trucks.popups.remove-account.success') }}</p>
            <button
              class="button button-big"
              @click="closePopup('removeAccount')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <!-- Warning if has bookings -->
        <template v-else-if="this.truck.hasBooking">
          <div class="popup-main">
            <img class="popup-main-img" src="/img/warning.png"/>
            <p class="popup-main-text">{{ $t('trucks.popups.remove-account.warning') }}</p>
            <button
              type="button"
              class="button button-big"
              @click.prevent="closePopup('removeAccount')"
            >
              {{ $t('globals.close') }}
            </button>
          </div>
        </template>
        <template v-else>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/warning.png"/>
            <p class="popup-main-text" v-html="$t('trucks.popups.remove-account.text')"/>
          </div>

          <div class="popup-actions">
            <button
              type="button"
              class="button"
              @click.prevent="closePopup('removeAccount')"
            >
              {{ $t('globals.cancel') }}
            </button>
            <button
              type="submit"
              class="button button--error"
              @click="removeAccount"
            >
              {{ $t('globals.unlink') }}
            </button>
          </div>
        </template>

        <div
          v-show="popupRemoveAccount.requests !== 0"
          class="popup-loader"
        >
          <loader />
        </div>
      </template>
    </popup>

    <!-- Update Popup -->
    <popup
      v-if="popupUpdate.visible"
      :classes="'popup-update'"
    >
      <template v-slot:content>
          <template v-if="popupUpdate.success">
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('trucks.popups.update.success') }}</p>
            <button
              class="button button-big"
              @click="closePopup('update')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="popup-title">{{ $t('trucks.popups.update.title') }}</h2>
          <form
            class="popup-form"
            novalidate
            @submit.prevent="update"
          >
            <inputs
              :type="'text'"
              :placeholder="`${$t('trucks.popups.labels.name')}*`"
              :label="`${$t('trucks.popups.labels.name')}*`"
              :value="truck.name"
              :error="popupNew.errors.name"
              @input="onInput($event, 'name')"
            />

            <Select
              :isInput="true"
              :label="`${$t('trucks.popups.labels.license_plate_country')}*`"
              :placeholder="$t('trucks.popups.labels.select_country')"
              :value="truck.licenseCountry.toLowerCase()"
              :options="countries"
              :error="popupUpdate.errors.licenseCountry"
              :class="'select-input'"
              @onChange="onInput($event, 'licenseCountry')"
            />

            <inputs
              :type="'text'"
              :label="`${$t('trucks.popups.labels.license_plate')}*`"
              :placeholder="$t('trucks.popups.labels.license_plate')"
              :value="truck.licensePlate"
              :error="popupUpdate.errors.licensePlate"
              @input="onInput($event, 'licensePlate')"
            />

            <inputs
              :type="'text'"
              :placeholder="$t('trucks.popups.labels.brand_truck')"
              :label="$t('trucks.popups.labels.brand')"
              :value="truck.brand"
              :counting="20"
              :error="popupUpdate.errors.brand"
              @input="onInput($event, 'brand')"
            />

            <inputs
              :type="'text'"
              :placeholder="$t('trucks.popups.labels.model_truck')"
              :label="$t('trucks.popups.labels.model')"
              :value="truck.model"
              :counting="10"
              :error="popupUpdate.errors.model"
              @input="onInput($event, 'model')"
            />

            <div class="popup-actions">
              <button
                type="button"
                class="button button-border"
                @click.prevent="closePopup('update')"
              >
                {{ $t('globals.close') }}
              </button>
              <button
                type="submit"
                class="button"
              >
                <!-- :disabled="disableUpdate" -->
                {{ $t('globals.update') }}
              </button>
            </div>
          </form>
        </template>
        <!-- </template> -->

        <div
          v-show="popupUpdate.requests !== 0"
          class="popup-loader"
        >
          <loader />
        </div>
      </template>
    </popup>

    <!-- Remove Popup -->
    <popup
      v-if="popupRemove.visible"
      :classes="'popup-remove'"
    >
      <template v-slot:content>
        <template v-if="popupRemove.success">
          <!-- <h2 class="popup-title">Success!</h2> -->
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('trucks.popups.remove.success') }}</p>
            <button
              class="button button-big"
              @click="closePopup('remove')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <!-- Warning if has bookings -->
        <template v-else-if="this.truck.hasBooking">
          <div class="popup-main">
            <img class="popup-main-img" src="/img/warning.png"/>
            <p class="popup-main-text">{{ $t('trucks.popups.remove.warning') }}</p>
            <button
              type="button"
              class="button button-big"
              @click.prevent="closePopup('remove')"
            >
              {{ $t('globals.close') }}
            </button>
          </div>
        </template>
        <!-- Warning if account linked -->
        <template v-else-if="this.truck.account">
          <div class="popup-main">
            <img class="popup-main-img" src="/img/warning.png"/>
            <p class="popup-main-text" v-html="$t('trucks.popups.remove.warning_account', { name: truck.account.fullname })"/>
            <button
              type="button"
              class="button button-big"
              @click.prevent="closePopup('remove')"
            >
              {{ $t('globals.close') }}
            </button>
          </div>
        </template>
        <template v-else>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/warning.png"/>
            <p class="popup-main-text" v-html="$t('trucks.popups.remove.text', { name: truck.name })"/>
          </div>

          <div class="popup-actions">
            <button
              type="button"
              class="button"
              @click.prevent="closePopup('remove')"
            >
              {{ $t('globals.cancel') }}
            </button>
            <button
              type="submit"
              class="button button--error"
              @click="removeTruck"
            >
              {{ $t('globals.revoke') }}
            </button>
          </div>
        </template>

        <div
          v-show="popupRemove.requests !== 0"
          class="popup-loader"
        >
          <loader />
        </div>
      </template>
    </popup>
  </section>
</template>


<script>
/* eslint-disable */
import moment from 'moment';

import NoResult from '@/components/partials/Bookings/NoResult';
import Thead from '@/components/elements/THead';
import TBody from '@/components/elements/TBody';
import Select from '@/components/elements/Select';
import Inputs from '@/components/elements/Inputs';
import Loader from '@/components/elements/Loader';
import Popup from '@/components/partials/Popup';
import Sprite from '@/components/elements/Sprite';

import i18n from '../i18n';

export default {
  name: 'Trucks',
  components: {
    NoResult, Thead, TBody, Inputs, Popup, Loader, Inputs, Select, Sprite
  },
  beforeMount() {
   
    this.$store.commit('trucks/resetItem');
    this.$store.commit('trucks/resetAccount');
   
    this.addTools();
  },
  beforeUnmount() {
    this.$store.commit('layout/updateButtons', []);
    this.$store.commit('layout/updateSearch', null);
  },
  computed: {
    truck() {
      return this.$store.state.trucks.item;
    },
    trucks() {
      const { items } = this.$store.state.trucks;
      return items;
    },
    countries() {
      const { countries } = this.$store.state.settings;
      return countries
        .map(country => {
          return {
            ...country,
            value: country.value.toLowerCase(),
            label: country.label
          }
        }).sort((a, b) => {
          if(a.label < b.label) return -1;
          if(a.label > b.label) return 1;
          return 0;
        });
    },
    loading() {
      return  this.$store.state.trucks.requests !== 0;
    },
    popupNew() {
      return this.$store.state.trucks.popups.new;
    },
    popupUpdate() {
      return this.$store.state.trucks.popups.update;
    },
    popupRemove() {
      return this.$store.state.trucks.popups.remove;
    },
    popupAccount() {
      return this.$store.state.trucks.popups.account;
    },
    popupRemoveAccount() {
      return this.$store.state.trucks.popups.removeAccount;
    },
    disableAdd() {
      const { name, licensePlate, licenseCountry } = this.$store.state.trucks.item;
      return name.length === 0 || licensePlate.length === 0 || !licenseCountry;
    },
    disableUpdate() {
      const { name, licensePlate, licenseCountry } = this.$store.state.trucks.item;
      return name.length === 0 || licensePlate.length === 0 || !licenseCountry;
    },
    theadTrucks() {
      return [
        { title: this.$t('trucks.thead.name'), class: 'small head' },
        // { title: this.$t('trucks.thead.plate_country'), class: 'small head medium-col' },
        // { title: this.$t('trucks.thead.license_plate'), class: 'small head' },
        { title: this.$t('trucks.thead.plates'), class: 'small head' },
        { title: this.$t('trucks.thead.brand'), class: 'small head' },
        { title: this.$t('trucks.thead.model'), class: 'small head' },
        { title: this.$t('trucks.thead.account'), class: 'small head' },
        { title: this.$t('trucks.thead.actions'), class: 'head-hidden' },
      ]
    },
    unformattedPhone() {
      const { phone } = this.$store.state.trucks.popups.account.form;

      if (phone && phone.length) {
        return phone.replace('+', '');
      } else {
        return phone;
      }
    },
    disableLinkDriver() {
      const { phone } = this.$store.state.trucks.popups.account.form;
      var disableFlag = !phone || !phone.length;
      
      if (!disableFlag && (phone && phone.length)) {
        const phoneError = this.$store.state.trucks.popups.account.errors['phone'];
        
        disableFlag = disableFlag || phoneError.length
      }

      return disableFlag;
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  data() {
    return {
      message: 'No result',
      tbodyTrucks: [
        { title: (truck) => truck.name },
        // {
        //   title: (truck) => truck.licenseCountry,
        //   class: 'medium-col'
        // },
        { title: (truck) => `${truck.licenseCountry} ${truck.licensePlate}` },
        { title: (truck) => truck.brand },
        { title: (truck) => truck.model },
        {
          title: (truck) => {
            if (truck.hasDriverAccount) {
              if (!truck.fleetPositions.length) return 'Yes'
              else {
                const position = truck.fleetPositions[0];
                const { driver } = position;
                return position.fullName || driver.fulName || driver.user.fullName;
              }
            };
            // if (truck.account) return this.$t('globals.yes');
            return null;
          },
          inlineButton: (truck) => {
            if (!truck.hasDriverAccount) return null;
            return {
              label: 'Remove',
              id: truck.id,
              classes: 'button-icon-disabled',
              icon: {
                type: 'crossgray',
                width: 16,
                height: 16
              },
              // action: (truck) => {
              //   this.onRemoveAccount(truck);
              // },
              // isDisabled: true
            };
          },
          buttons: (truck) => {
            if (truck.hasDriverAccount) return null;
            return [
              {
                 label: this.$t('trucks.ctas.create_account'),
                id: truck.id,
                classes: '',
                action: (truck) => {
                  this.onAccount(truck);
                },
                isDisabled: true
                
              }
            ];
          }
        },
        {
          title: () => '',
          class: 'buttons td--buttons',
          buttons: (truck) => {
            const buttons = [
              {
                label: this.$t('globals.update'),
                id: truck.id,
                classes: '',
                action: (truck) => {
                  this.onUpdate(truck);
                },
                isDisabled: true
              }
            ];

            if (!truck.hasDriverAccount) {
              buttons.unshift({
                label: this.$t('globals.revoke'),
                id: truck.id,
                classes: '',
                action: (truck) => {
                  this.onRemove(truck);
                },
                isDisabled: true
              });
            }

            return buttons;
          }
        }
      ]
    }
  },
  methods: {
    addTools() {
      this.$store.commit('layout/updateButtons', [{
        action: () => {
           this.$store.commit('trucks/updatePopupVisibility', {
            popup: 'new',
            visible: true
          });
        },
        label: this.$t('globals.layout.buttons.add.truck'),
        isDisabled: true
      }]);

      this.$store.commit('layout/updateSearch', {
        placeholder: this.$t('components.quick_search.placeholders.trucks'),
        onInput: (value) => {
          this.$store.commit('trucks/updateSearch', value);
          this.$store.dispatch('trucks/getTrucks');
        }
      });
    },
    closePopup(popup) {
      this.$store.commit('trucks/updatePopupVisibility', { popup, visible: false });
      this.$store.commit('trucks/updatePopupProperty', { popup, property: 'success', value: false });
      this.$store.commit('trucks/updatePopupProperty', { popup: 'account', property: 'accountFound', value: false });
      this.$store.commit('trucks/updatePopupProperty', { popup: 'account', property: 'confirm', value: false });

      let fields = [];
      if (popup === 'new' || popup === 'update') fields = ['name', 'licensePlate', 'licenseCountry', 'brand', 'model'];
      if (popup === 'account') fields = ['username', 'password', 'confirm', 'phone', 'moreThan', 'uppercase', 'num'];

      // Reset errors
      for (let i = 0; i < fields.length; i++) {
        this.$store.commit('trucks/updatePopupError', { popup, field: fields[i], value: '' });
      }

      this.$store.commit('trucks/resetItem');
      this.$store.commit('trucks/resetAccount');
    },
    onInput(event, field) {
      if (!event) return;
      if (field === 'licenseCountry') {
        this.$store.commit('trucks/updateItemProperty', {
          property: field,
          value: event
        });
        return;
      }

      const { value } = event?.target;
      if (value === undefined) return;

      this.$store.commit('trucks/updateItemProperty', {
        property: field,
        value
      });
    },
    onAccountInput(event, field) {
      if (!event) return;
      const { target } = event;
      if (!target) return;
      let { value } = target;
      if (value === undefined) return;

      if (field === 'phone') {
        value = value.replace(/[^\d]/g,'');
        
        target.value = value;
        
        if (value.length > 15) {
          const { t } = i18n.global;

          this.$store.commit('trucks/updatePopupError', { popup: 'account', field, value: t('drivers.errors.phone') });
        } else {
          this.$store.commit('trucks/updatePopupError', { popup: 'account', field, value: '' });
        }   
        
        let prefix = target.getAttribute('data-prefix');

        if (prefix && prefix.length && value.length) {
          value = `${prefix}${value}`;
        }
      }

      this.$store.commit('trucks/updatePopupForm', { popup: 'account', field, value });
    },
    addNew() {
      let hasError = this.validate('new');
      if(hasError) return;

      this.$store.dispatch('trucks/addNew');
    },
    onUpdate(truck) {
      this.$store.commit('trucks/updateItem', { ...truck });
      this.$store.commit('trucks/updatePopupVisibility', {
        popup: 'update',
        visible: true
      });
    },
    onAccount(truck) {
      this.$store.commit('trucks/updateItem', { ...truck });
      this.$store.commit('trucks/updatePopupVisibility', {
        popup: 'account',
        visible: true
      });
    },
    onRemoveAccount(truck) {
      this.$store.commit('trucks/updateItem', { ...truck });
      this.$store.commit('trucks/updatePopupVisibility', {
        popup: 'removeAccount',
        visible: true
      });
    },
    addAccount() {
      if (this.popupAccount.confirm) {
        const hasError = this.validateAccount(['fullname', 'phone']);
        if(hasError) return;
        this.$store.dispatch('trucks/addAccount');
        return;
      }

      const hasError = this.validateAccount();
      if(hasError) return;
      this.$store.dispatch('trucks/checkAccount');
    },
    confirmAccount() {
      this.$store.dispatch('trucks/confirmAccount');
    },
    removeAccount() {
      this.$store.dispatch('trucks/removeAccount');
    },
    validate(popup) {
      const fields = ['name', 'licensePlate', 'licenseCountry'];
      let hasError = false;

      for (let i = 0; i < fields.length; i++) {
        // Reset error
        this.$store.commit('trucks/updatePopupError', { popup, field, value: '' });

        const field = fields[i];
        const value = this.$store.state.trucks.item[field];

        if (!value.length) {
          hasError = true;

          this.$store.commit('trucks/updatePopupError', { popup, field, value: this.$t('errors.required') });
        }
      }

      const maxLengths = {
        brand: 20,
        model: 10
      };

      for (const key in maxLengths) {
        this.$store.commit('trucks/updatePopupError', { popup, field: key, value: '' });

        const max = maxLengths[key];

        if (this.$store.state.trucks.item[key] && this.$store.state.trucks.item[key].length > max) {
          hasError = true;
          this.$store.commit('trucks/updatePopupError', { popup, field: key, value: this.$t('errors.max_char', { max }) });
        }
      }

      return hasError;
    },
    validateAccount(fields = ['phone']) {
      // const fields = ['phone'];
      // const fields = ['fullname', 'phone'];
      let hasError = false;

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const value = this.$store.state.trucks.popups.account.form[field];

        // Reset error
        this.$store.commit('trucks/updatePopupError', { popup: 'account', field, value: '' });

        if (!value.length) {
          hasError = true;
          this.$store.commit('trucks/updatePopupError', { popup: 'account', field, value: this.$t('notifications.errors.required') });
        }
      }

      return hasError;
    },
    update() {
      let hasError = this.validate('update');
      if(hasError) return;

      this.$store.dispatch('trucks/update');
    },
    onRemove(truck) {
      this.$store.commit('trucks/updateItem', { ...truck });
      this.$store.commit('trucks/updatePopupVisibility', {
        popup: 'remove',
        visible: true
      });
    },
    removeTruck() {
      this.$store.dispatch('trucks/remove');
    }
  }
}
</script>
