

const spontaneous = {
  namespaced: true,
  state: {
    visible: false
  },
  mutations: {
    toggle() {
      state.visible = !state.visible;
    }
  },
  actions: {
  }
};

export default spontaneous;
