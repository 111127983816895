let baseURL = location.origin.match('dev') ? process.env.VUE_APP_SNAP_BASE_URL_LOCAL : process.env.VUE_APP_SNAP_BASE_URL;
if (location.origin.match('weareotra')) baseURL = process.env.VUE_APP_SNAP_BASE_URL_PROD;
if (location.origin.match('paint')) baseURL = process.env.VUE_APP_SNAP_BASE_URL_PAINT;

export function redirectSuccess() {
    const targetUrl = `${baseURL}/fleet/manage?success=True`;
    console.log("targetUrl", targetUrl);
    window.location.href = targetUrl;
  }

  export function redirectFailed(message) {
    const encodedMessage = encodeURIComponent(message);
    const targetUrl = `${baseURL}/fleet/manage?success=False&errorCodes=${encodedMessage}`;
    console.log("targetUrl", targetUrl);
    window.location.href = targetUrl;
  }

  export function redirectToSnap() {
    const targetUrl = `${baseURL}`;
    console.log("targetUrl", targetUrl);
    window.location.href = targetUrl;
  }

  export function redirectToSnapFleetManage() {
    const targetUrl = `${baseURL}/fleet/manage`;
    console.log("targetUrl", targetUrl);
    window.location.href = targetUrl;
  }