import axios from 'axios';

let baseURL = location.origin.match('dev') ? process.env.VUE_APP_SNAP_INTEGRATION_BASE_URL_LOCAL : process.env.VUE_APP_SNAP_INTEGRATION_BASE_URL;
if (location.origin.match('weareotra')) baseURL = process.env.VUE_APP_SNAP_INTEGRATION_BASE_URL_PROD;
if (location.origin.match('paint')) baseURL = process.env.VUE_APP_SNAP_INTEGRATION_BASE_URL_PAINT;

let entranceKey = location.origin.match('dev') ? process.env.VUE_APP_SNAP_INTEGRATION_API_KEY_LOCAL : process.env.VUE_APP_SNAP_INTEGRATION_API_KEY;
if (location.origin.match('weareotra')) entranceKey = process.env.VUE_APP_SNAP_INTEGRATION_API_KEY_PROD;
if (location.origin.match('paint')) entranceKey = process.env.VUE_APP_SNAP_INTEGRATION_API_KEY_PAINT;

const apiSnapIntegration = axios.create({
    baseURL,
    headers: {
        "EntranceKey": entranceKey
    }
});

const fetchToken = async (clientId, clientSecret) => {
    try {
        const response = await apiSnapIntegration.get('Identity/access-token',{
            params: {
                clientId,
                clientSecret,
            },
        });
        return response.data.access_token;
    } catch (error) {
        console.error('Error fetching data from API Snap Integration:', error);
        throw error;
    }
};

const bookingValidation = async (item) => {
    try {
        const response = await apiSnapIntegration.post('/Booking/validation-initial-parameters',item);
        return response.data;
    } catch (error) {
        if (error.response) {
            const errorDetail = error.response.data.detail;
            console.error('Error detail:', errorDetail);
            throw new Error(errorDetail);
        } else {
            console.error('Error fetching data from API Snap Integration:', error.message);
            throw error;
        }
    }
};

const fetchParkingById = async (parkingId) => {
    try {
        const response = await apiSnapIntegration.get(`/legacy/fleet/parkings/${parkingId}`);
        return response.data;
    } catch (error) {
        if (error.response) {
            const errorDetail = error.response.data.detail;
            console.error('Error detail:', errorDetail);
            throw new Error(errorDetail);
        } else {
            console.error('Error fetching data from API Snap Integration:', error.message);
            throw error;
        }
    }
};

const fetchBookablePeriods = async (id, options) => {
    try {
        const response = await apiSnapIntegration.get(`/legacy/fleet/parkings/${id}/bookable-periods-per-date`,{
            params: {
                slot: options.Slot,
                duration: options.Duration,
                fromDate: options.Date,
                toDate: options.Date
            },
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            const errorDetail = error.response.data.detail;
            console.error('Error detail:', errorDetail);
            throw new Error(errorDetail);
        } else {
            console.error('Error fetching data from API Snap Integration:', error.message);
            throw error;
        }
    }
};

export {
    apiSnapIntegration,
    fetchToken,
    bookingValidation,
    fetchParkingById,
    fetchBookablePeriods
  }
  