import axios from 'axios';

const getPlanners = async () => {
  return await axios.get('/fleet/fleet-planners')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

const getPlanner = async (id) => {
  return await axios.get(`/fleet/fleet-planners/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const createPlanner = async (item) => {
  return await axios.post('/fleet/fleet-planners', item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const updatePlanner = async (id, params) => {
  return await axios.patch(`/fleet/fleet-planners/${id}`, params)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const revokePlanner = async (id) => {
  return await axios.delete(`/fleet/fleet-planners/${id}/revoke`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const suspendPlanner = async (id) => {
  return await axios.patch(`/fleet/fleet-planners/${id}/suspend`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    // console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const unsuspendPlanner = async (id) => {
  return await axios.patch(`/fleet/fleet-planners/${id}/unsuspend`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

export {
  getPlanner,
  getPlanners,
  createPlanner,
  updatePlanner,
  revokePlanner,
  suspendPlanner,
  unsuspendPlanner
};