import axios from 'axios';

const getServices = async (params) => {
  return await axios.get('/fleet/cleaning-services', { params })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const getService = async (id) => {
  return await axios.get(`/fleet/cleaning-services/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const getServicePeriods = async (id, params) => {
  return await axios.get(`/fleet/cleaning-services/${id}/periods`, { params })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const createReservation = async (params) => {
  return await axios.post(`/fleet/cleaning-service-bookings`, params)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const getServiceBookings = async (params) => {
  return await axios.get(`/fleet/cleaning-service-bookings`, { params })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const getServiceBooking = async (id) => {
  return await axios.get(`/fleet/cleaning-service-bookings/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

export {
  getService,
  getServices,
  getServicePeriods,
  createReservation,
  getServiceBooking,
  getServiceBookings
};