<template>
  <thead>
    <tr>
      <th 
        v-for="h in head" 
        :class="h.class" 
        :key="h.title"
        v-html="h.title"
      />
        <!-- {{ h.title }}
      </th> -->
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'THead',
  props:{
    head: {
      type: Array
    }
  }
}
</script>
