
const securityLevels = {
  namespaced: true,
  state: {
    visible: false,
    index: 2,
  },
  mutations: {
    toggle(state) {
      state.visible = !state.visible;
    },
    updateIndex(state, index) {
      state.index = index;
    }
  },
  actions: {
  }
};

export default securityLevels;
