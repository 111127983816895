import i18n from '../i18n';
import { getFavorites, getFavorite } from '@/calls/favorites';

const drivers = {
  namespaced: true,
  state: {
    selected: null,
    items: [],
    requests: 0,
  },
  mutations: {
    updateItems(state, items) {
      state.items = items;
    },
    updateSelected(state, selected) {
      state.selected = selected;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    async getFavorites({ commit, dispatch, state }) {
      commit('increaseRequests');

      const request = await getFavorites();

      if (!request.hasError) {
        commit('updateItems', request.data.favorites);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.favorites.get', { error: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }
      commit('decreaseRequests');
    },
    async getFavorite({ commit, dispatch, state }, id) {
      commit('increaseRequests');

      const request = await getFavorite(id);

      if (!request.hasError) {
        // commit('updateItems', request.data.favorites);
        dispatch('setFavorite', request.data);
      } else {
        console.log('error favorite :', request);
      }

      commit('decreaseRequests');
      return true;
    },
    setFavorite({ state, rootState, dispatch, commit }, favorite) {
      const settings = JSON.parse(favorite.jsonSettings);
      dispatch('map/resetFilters', null, { root: true });

      // Route
      if (favorite.status === 'Route') {
        commit('routePlanner/updateWaypoints', settings.route.waypoints, { root: true });
        commit('routePlanner/updateParams', { property: 'routingMode', value: settings.route.params.routingMode }, { root: true });
        commit('routePlanner/updateParams', { property: 'vehicle', value: settings.route.params.vehicle }, { root: true });
        commit('map/update', { property: 'centerMarker', value: false }, { root: true });
        dispatch('routePlanner/search', null, { root: true });
      } else {
        if (settings.place) {
          // commit('parkings/updateForm', { value: settings.place,  property: 'place' }, { root: true });
          commit('map/update', { property: 'search', value: settings.place }, { root: true });
          commit('map/update', { property: 'centerMarker', value: true }, { root: true });
          
        } else {
          commit('map/update', { property: 'search', value: null }, { root: true });
          commit('map/update', { property: 'centerMarker', value: false }, { root: true });
          // commit('parkings/updateForm', { value: null,  property: 'place' }, { root: true });
        }
      }

      // Filters
      for (let i = 0; i < settings.filters.length; i++) {
        const filter = settings.filters[i];
        if (filter.id === 'range') {
          if (favorite.status !== 'Route') {
            commit('map/updateFilterValue', {
              filter: { id: 'range' },
              value: filter.value
            }, { root: true });
          }
        } else {
          filter.options.map(option => {
            if (option.isActive) {
              commit('map/updateFilterValue', {
                filter: { id: filter.id},
                value: option.value
              }, { root: true });
            }
          });
        }
      }
      
      // commit('parkings/updateFilters', settings.filters);
      // commit('parkings/updateMap', settings.map, { root: true });
      const center = { ...settings.map.center, zoom: settings.map.zoom }
      commit('map/update', { property: 'type', value: settings.serviceType ?? 'parkings' }, { root: true });
      commit('map/update', { property: 'center', value: { ...rootState.map.center, ...center } }, { root: true });
      // commit('routePlanner/updateMode', favorite.status === 'Search' ? 'search' : 'planner', { root: true });
      commit('map/update', { property: 'mode', value: favorite.status === 'Search' ? 'search' : 'planner' }, { root: true });
      commit('updateSelected', favorite.id);
      return true;
    }
  }
};

export default drivers;
