import i18n from '../i18n';
import {
  getParking,
  getParkings,
  getFilters,
  getAvailableParkings
} from '@/calls/parkings';
import { getServices } from '@/calls/services';

// Currently not in use
// This state will be used (hope so) for the refactor of the map
const map = {
  namespaced: true,
  state: {
    mode: 'search', // planner
    type: 'parkings', // tw, tc, electric
    prevType: null,
    // Results / Pins
    results: [],
    hovered: null,
    selected: null,
    // Bounds
    bounds: null,
    // Search params
    search: null,
    searched: null,
    filters: [],
    options: {
      range: {
        min: 50,
        max: 700
      }
    },
    // Map
    centerMarker: false,
    center: {
      // lng: 4.7009,
      // lat: 50.4953,
      lng: null,
      lat: null,
      zoom: 12
    },
    // Parking detail
    detail: {
      visible: false,
      data: null
    },
    // requests: 0,
    requests: {
      search: 0,
      detail: 0,
      filters: 0
    },
    parkingTypes: {
      isParkingsAvailable: false,
      isElectricChargeAvailable: false,
      isTruckWashingAvailable: false,
      isTankCleaningAvailable: false
    }
  },
  mutations: {
    update(state, { property, value }) {
      state[property] = value;
    },
    updateDetail(state, { property, value }) {
      state.detail[property] = value;
    },
    updateFilters(state, filters) {
      state.filters = filters;
    },
    updateFilterValue(state, { filter, value }) {
      state.filters =  state.filters.map(filt => {
         if (filt.id === filter.id) {
           if (filt.id === 'range') {
             filt.value = value;
           } else {
             filt.options = filt.options.map(option => {
               if (option.value === value) {
                option.isActive = !option.isActive;
               }
               return option;
             });
           }
         }
         return filt;
       });
     },
    // updateSearch(state, { property, value }) {
    //   state.search[property] = value;
    // },
    increaseRequests(state, type = 'search') {
      state.requests[type]++;
    },
    decreaseRequests(state, type = 'search') {
      state.requests[type]--;
    }
  },
  actions: {
    // Zoom
    setZoomLevel({ state, commit }, { distToDisplay, l }) {
      // l is the length (width or height) of the displayed map
      let dist = distToDisplay;

      if (!dist) {
        const range = state.filters.filter(f => f.id === 'range')[0];
        dist = range && range.value ? range.value : state.options.range.max;
      }

      const perPixel = (dist / l) * 1000;
      const pixelByZoom = [59000, 29900, 14900, 7400, 3700, 1800, 930, 468, 234, 117, 58, 29, 14];
      let level = 0;
      for (let i = 0; i < pixelByZoom.length; i++) {
        const el = pixelByZoom[i];
        if(el < perPixel) break;
        level = i;
      }
      
      commit('update', { property: 'center', value: { ...state.center, zoom: level } });
      // return level;
    },
    // Filters
    resetFilters({ state, commit }) {
      state.filters.map(filter => {
        if (filter.id === 'range') {
          commit('updateFilterValue', {
            filter: { id: 'range' },
            value: state.options.range.max
          });
          return;
        }

        if (state.type !== 'electric' || filter.id !== 'so') {
          filter.options.map(option => {
            if (option.isActive) {
              commit('updateFilterValue', {
                filter: { id: filter.id},
                value: option.value
              });
            }
          });
        }
      });
    },
    getFilters({ commit, state }) {
      commit('increaseRequests', 'filters');
      const filters = getFilters();
      return filters.then((promise) => {
        const filters = promise.filters.map(filter => {
          filter.label = filter.label.replace(/_/g, ' ');
          filter.options = filter.options
            .filter(option => filter.id !== 'so' || option.value !== '5')
            .map(option => {
              option.label = option.label.charAt(0).toUpperCase() + option.label.slice(1);
              option.isActive = false;
              return option;
            })

          return filter;
        });

        filters.unshift({
          id: 'range',
          label: 'Distance',
          icon: 'distance',
          value: state.options.range.max,
          min: state.options.range.min,
          max: state.options.range.max
        });

        commit('updateFilters', filters);
        commit('decreaseRequests', 'filters');
        return promise;
      });
    },
    // Services
    updateResults({ commit, state }, results) {
      if (!state.bounds) return commit('update', { property: 'results', value: results });
      const { _ne, _sw } = state.bounds;

      const filtereds = results.filter(p => {
        const { latitude, longitude } = p;
        return latitude <= _ne.lat && latitude >= _sw.lat && longitude >= _sw.lng && longitude <= _ne.lng;
      });

      commit('update', { property: 'results', value: filtereds });
    },
    getServices({ state, commit, dispatch }, range) {
      if (!state.center) return;
      switch (state.type) {
        case 'tw':
        case 'tc':
          dispatch('getTWTC', range);
          break;
        // case 'electric':
        //   dispatch('getParkings', range);
        //   break;
        default:
          dispatch('getParkings', range);
          break;
      }
      commit('update', { property: 'searched', value: state.search });
    },
    async getTWTC({ state, commit, dispatch }, range) {
      commit('increaseRequests');
      let paramsRange = state.options.range.max;

      state.filters.map(filter => {
        if (filter.id === 'range') {
          paramsRange = range ? range : filter.value;
          return;
        }
      });

      const request = await getServices({
        type: state.type === 'tw' ? 1 : 2,
        longitude: state.center.lng,
        latitude: state.center.lat,
        range: paramsRange
      });

      if (!request.hasError) {
        const services = request.map(s => {
          s.type = state.type;
          return s;
        });

        // commit('update', { property: 'results', value: services});
        // this.$store.commit('tw/updateServices', services);
        dispatch('updateResults', services);
      }

      commit('update', { property: 'bounds', value: null});
      commit('decreaseRequests');
    },
    async getParkings({ state, commit, dispatch }, range) {
      commit('increaseRequests');
      const filters = { range: state.options.range.max };

      if (state.type === 'electric') {
        // Electric parkings
        state.filters.map(filter => {
          if (filter.id === 'range') {
            filters.range = range ? range : filter.value;
            return;
          }
        });
        filters.so = [5];
      } else {
        state.filters.map(filter => {
          if (filter.id === 'range') {
            filters.range = range ? range : filter.value;
            return;
          }
  
          if (!filters[filter.id]) filters[filter.id] = [];
  
          filter.options.map(option => {
            if (option.isActive) filters[filter.id].push(option.value);
          })
        });
      }
     

      for (var prop in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, prop)) {
          if (filters[prop].length === 0) {
            delete filters[prop];
          }
        }
      }

      const request = await getParkings({
        longitude: state.center.lng,
        latitude: state.center.lat,
        ...filters
      });

      const parkings = request.map(p => {
        p.type = state.type;
        return p;
      });

      dispatch('updateResults', parkings);
      commit('update', { property: 'bounds', value: null});
      // commit('update', { property: 'results', value: parkings});
      commit('decreaseRequests');
    },
    // Detail
    async getDetail({ commit, dispatch }, id) {
      commit('increaseRequests', 'detail');

      try {
        const parking = await getParking(id);
        commit('updateDetail', { property: 'item', value: parking });
      } catch (error) {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting parking infos : ${error}`,
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests', 'detail');
    },
    async getParkingsType({ state, commit, dispatch }) {
      commit('increaseRequests');
      const filters = { 
        range: state.options.range.max,
        // search: state.search
      };

      console.log(filters)
      
      const availableParkingsData = await getAvailableParkings({
        longitude: state.center.lng,
        latitude: state.center.lat,
        ...filters
      });

      commit('update', { property: 'parkingTypes', value: availableParkingsData});
      commit('decreaseRequests');
    },
  }
};

export default map;
