// import {
//   getParking,
//   getParkings,
//   getFilters,
//   getSlots,
// } from '@/calls/parkings';

const search = {
  namespaced: true,
  state: {
    filters: {
      visible: false,
      items: [
        {
          id: 'hello',
          icon: 'booking',
          translation: {
            name: 'Booking'
          },
          items: [
            {
              isActive: true,
              translation: {
                name: 'Bookable'
              }
            }
          ]
        },
        {
          id: 'world',
          icon: 'parking',
          score: 4,
          translation: {
            name: 'Parking Level'
          },
          items: [
            {
              isActive: true,
              translation: {
                name: 'Bronze'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Silver'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Gold'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Platinum'
              }
            }
          ]
        },
        {
          id: 'parking-type',
          icon: 'truck',
          score: 4,
          translation: {
            name: 'Parking type'
          },
          items: [
            {
              isActive: true,
              translation: {
                name: 'Classic'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'ADR'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Regrirated Truck'
              }
            },
            {
              isActive: true,
              translation: {
                name: '+25m'
              }
            }
          ]
        },
        {
          id: 'security',
          icon: 'security',
          score: 4,
          translation: {
            name: 'Security equipment'
          },
          items: [
            {
              isActive: true,
              translation: {
                name: 'ADNR'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Emergency contact'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Police station nearby'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Security traffic signage'
              }
            }
          ]
        },

        {
          id: 'services',
          icon: 'services',
          score: 4,
          translation: {
            name: 'Truck Services'
          },
          items: [
            {
              isActive: true,
              translation: {
                name: 'Fuel station'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Electricity installation'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Live animal accepted'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Separate dangerous'
              }
            },
            {
              isActive: true,
              translation: {
                name: 'Shelter against rain & sun for persons'
              }
            }
          ]
        }
      ]
    }
  },
  mutations: {
    toggleFilters(state) {
      state.filters.visible = !state.filters.visible;
    }
  },
  actions: {
  }
};

export default search;
