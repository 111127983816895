import { getXMLFiles } from "@/calls/napapifiles";

const napapifiles = {
    namespaced: true,
    state: {
      filenames: []
    },
    mutations: {
      updateFilenames(state, files) {
        state.filenames = files;
      }
    },
    actions: {
      async getNapXMLFiles({ commit, state, dispatch }) {
        const files = await getXMLFiles();
  
        commit('updateFilenames', files); 
      }
    }
  };
  
  export default napapifiles;
  