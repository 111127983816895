import axios from 'axios';

const getCompanies = () => {
  return axios.get(`/fleet/admin/companies`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

export {
  getCompanies
}