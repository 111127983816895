<template>
  <div id="detail-map"></div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl';
import mapStyles from '@/data/mapbox-style.json';

import Pin from '@/assets/svg/pin.svg';

export default {
  name: "DetailsMap",
  props: {
    lat: {
      type: [Number, String],
      default: 0
    },
    lng: {
      type: [Number, String],
      default: 0
    },
  },
  data() {
    return {
      // token: 'pk.eyJ1IjoiYXVkcmV5LXJvYmljIiwiYSI6ImNrY2tidW90ZjB3eXgyc3A1OWtvenZoZ2QifQ.Qrw6kiSLbmlUdVmXR05tCA',
      token: 'pk.eyJ1Ijoib3RyYW1hcGJveGFjY291bnQiLCJhIjoiY2tucHZtbjA1MTI0MzJubGF2MTA1OHA1ZiJ9.NP7_3MVerJ6Skc9fCSJ3fg',
      data: null,
      map: null,
      marker: null,
      center:[4.7009, 50.4953],
    };
  },

  mounted() {
    mapboxgl.accessToken = this.token;
    this.map = new mapboxgl.Map({
      container: 'detail-map',
      style: mapStyles, // stylesheet location
      // style: 'mapbox://styles/otramapboxaccount/cko1iwojf0wmr18le6ezvsnbw', // stylesheet location
      center: [this.lng, this.lat], // starting position [lng, lat]
      zoom: 12 // starting zoom
    });

    const el = this.createPin();
    this.marker = new mapboxgl.Marker(el, {
      // draggable: true,
      offset: [0, -50/2]
    }).setLngLat([this.lng, this.lat]).addTo(this.map);
  },
  methods: {
    createPin(el) {
      el = document.createElement('div');
      el.style.backgroundImage = `url("${Pin}")`
      el.style.backgroundSize = 'contain'
      el.style.backgroundPosition = 'center'
      el.style.width = '64px';
      el.style.height = '68px';
      return el
    },
    setMap(coordinates) {
      this.map.jumpTo({
        center: [coordinates.lng, coordinates.lat], 
        zoom: 12,
      });
    },
    setPin(coordinates) {
      this.marker
        .setLngLat([coordinates.lng, coordinates.lat])
    },
  }
}
</script>
