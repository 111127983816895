import { createStore } from 'vuex'

import tw from './tw';
import tc from './tc';
import map from './map';
import user from './user';
import fleet from './fleet';
import trucks from './trucks';
import search from './search';
import popups from './popups';
import layout from './layout';
import drivers from './drivers';
import settings from './settings';
import parkings from './parkings';
import bookings from './bookings';
import planners from './planners';
import favorites from './favorites';
import companies from './companies';
import spontaneous from './spontaneous';
import reservations from './reservations';
import translations from './translations';
import notifications from './notifications';
import routePlanner from './route-planner';
import authentication from './authentication';
import securityLevels from './securityLevels';
import napapifiles from './napapifiles';
import snapIntegration from './snapintegration';
import siParkings from './snap_parkings';
import siBookings from './snap_bookings';

export default createStore({
  modules: {
    tw,
    tc,
    map,
    user,
    fleet,
    layout,
    trucks,
    search,
    popups,
    drivers,
    parkings,
    settings,
    bookings,
    planners,
    favorites,
    companies,
    spontaneous,
    reservations,
    translations,
    notifications,
    routePlanner,
    authentication,
    securityLevels,
    napapifiles,
    snapIntegration,
    siParkings,
    siBookings
  },
});
