<template>
  <div ref="map" id="step-map" class="map"></div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import mapStyles from '@/data/mapbox-style.json';
import mapLanguages from '@/data/mapbox-languages.json';

import { measureDistance } from '@/helpers/helpers';

import PinBig from '@/assets/img/pins/pin-big.png';
import PinSmall from '@/assets/img/pins/pin-small.png';
import PinGreen from '@/assets/img/pins/pin-green.png';
import PinPlace from '@/assets/img/pins/place.png';
import ParkingIcon from '@/assets/svg/parking-icon.svg';
import PinTW from '@/assets/img/pins/tw.png';
import PinTC from '@/assets/img/pins/tc.png';
import PinElectric from '@/assets/img/pins/electric.png';

export default {
  props: {
    // List of pins
    // items: {
    //   type: Array,
    //   default: () => []
    // },
    // List of pins
    // routeItems: {
    //   type: Array,
    //   default: () => []
    // },

    // Pin hovered
    // Route
    // polylines: {
    //   type: Array,
    //   default: () => []
    // },
    // Settings
    place: {
      type: Object,
      default: null
    },
    hasBounds: {
      type: Boolean,
      default: true
    },
    lang: {
      type: String,
      default: 'en'
    },
  },
  // Datas
  data() {
    return {
      map: null,
      token: 'pk.eyJ1Ijoib3RyYW1hcGJveGFjY291bnQiLCJhIjoiY2tucHZtbjA1MTI0MzJubGF2MTA1OHA1ZiJ9.NP7_3MVerJ6Skc9fCSJ3fg',
      mouseDown: false,
      // center: {
      //   lng: 4.7009,
      //   lat: 50.4953
      // },
      pins: {
        items: [],
        route: [],
        center: null
      },
      waypoints: [],
      displayedPolylines: [],
    }
  },
  computed: {
    items() {
      return this.$store.state.map.results;
    },
    routeItems() {
      if (this.$store.state.map.mode === 'search') return [];
      return this.$store.state.routePlanner.pins;
    },
    polylines() {
      if (this.$store.state.map.mode === 'search') return [];
      return this.$store.state.routePlanner.polylines;
    },
    translations() {
      return this.$store.state.translations.keys;
    },
    center() {
      return this.$store.state.map.center;
    },
    centerMarker() {
      return this.$store.state.map.centerMarker;
    },
    selected() {
      return this.$store.state.map.selected;
    },
    hovered() {
      return this.$store.state.map.hovered;
    },
    route() {
      return [
        ...this.routeItems,
        ...this.waypoints
      ]
    }
  },

  watch: {
    items(val) {
      this.updatePins();
    },
    translations() {
      this.updatePins();
    },
    hovered(val) {
      this.interactPin(val ? val : { id: -1 }, 'hover');
    },
    selected(val) {
      this.interactPin(val ? val : { id: -1 }, 'select');
    },
    polylines(newVal, oldVal) {
      this.updatePolylines();
    },
    routeItems(val) {
      this.updatePins('route');
    },
    lang(val) {
      this.updateLang();
    },
    center(val) {
      const center = this.map.getBounds();
      const zoom = this.map.getZoom();

      if (val.lng !== center[0] || val.lat !== center[1]) {
        this.setCenter();

        if (this.centerMarker) {
          this.moveCenterMarker();
        }
      }

      if (val.zoom !== zoom) {
        this.setZoom();
      }
    },
    centerMarker(val) {
      if (val) this.moveCenterMarker();
      //else this.removeCenterMarker();
    }
  },
  // Cycle
  mounted() {
    mapboxgl.accessToken = this.token;

    const center = this.center.lng && this.center.lat ? this.center : { lng: 4.7009, lat: 50.4953 };
    this.map = new mapboxgl.Map({
      container: this.$refs.map,
      // container: 'map',
      style: mapStyles, // stylesheet location
      // style: 'mapbox://styles/otramapboxaccount/cko1iwojf0wmr18le6ezvsnbw', // stylesheet location
      center: [center.lng, center.lat], // starting position [lng, lat]
      zoom: this.center.zoom, // starting zoom
      padding: { left: 500 }
    });

     // Init map events
    this.map.on('zoomend', (e) => { this.onZoomEnd(); });
    this.map.on('dragend', (e) => { this.onMapEvents(); });
    this.map.on('mousedown', (e) => { this.onMouseDown(); });
    this.map.on('mouseup', (e) => { this.onMouseUp(); });

    if (this.centerMarker) {
      this.moveCenterMarker();
    }

    setTimeout(() => {
      this.updateLang();
      this.updatePolylines();
      this.clearPins('route');
      this.setPins('route');
    }, 50);
  },
  methods: {
    setZoom(zoom) {
      const { width, height } = this.$refs.map.getBoundingClientRect();
      const l = Math.min(width, height);
      this.$store.dispatch('map/setZoomLevel', { l });
      // this.map.jumpTo({ zoom });
    },
    setCenter() {
      // if (coordinates) this.center = coordinates;
      // this.moveCenterMarker(this.center);

      this.map.jumpTo({
        center: [this.center.lng, this.center.lat], 
        zoom: this.center.zoom
      });
    },
    // Center Marker
    moveCenterMarker() {
      if (this.pins.center) this.pins.center.setLngLat([this.center.lng, this.center.lat])
      else this.addCenterMarker();
    },
    addCenterMarker() {
      this.pins.center = new mapboxgl.Marker(this.createMarker(null, 'center'))
        .setLngLat([this.center.lng, this.center.lat])
        .addTo(this.map);
    },
    removeCenterMarker() {
      if (this.pins.center) {
        this.pins.center.remove();
        this.pins.center = null;
      }
    },
    // Items - Pins
    updatePins(type = 'items') {
      this.clearPins(type);
      this.setPins(type);
    },
  
    setPins(type = 'items') {
      for (let i = 0; i < this[type].length; i++) {
        const item = this[type][i];
        const pin = {
          lat: item.latitude || item[1],
          lng: item.longitude || item[0],
          ...item,
          item
        };

        pin.marker = new mapboxgl.Marker(this.createMarker(pin, type === 'route' ? 'route' : pin.type))
          .setLngLat([pin.lng, pin.lat])
          .addTo(this.map);

        pin.marker._element.addEventListener('click', () => {
          this.$emit('onClick', item);
        });

        this.pins[type].push(pin);
      }

      // if (this.hasBounds) {
        // this.bounds();
      // }
    },

    clearPins(type = 'items') {
      for (let i = 0; i < this.pins[type].length; i++) {
        const pin = this.pins[type][i];
        pin.marker.remove();
      }

      this.pins[type] = [];
    },

    interactPin(item, type = 'hover') {
      const types = {
        hover: 'hovered',
        select: 'selected'
      };

      for (let i = 0; i < this.pins.items.length; i++) {
        const pin = this.pins.items[i];
        if (pin.id === item.id) pin.marker._element.classList.add(types[type]);
        else pin.marker._element.classList.remove(types[type]);
      }
    },

    // bounds() {
    //   if (this.pins.items.length === 0) return;
    //   const bounds = new mapboxgl.LngLatBounds();

    //   if (this.place) bounds.extend([this.place.lng, this.place.lat]);

    //   this.pins.items.forEach(pin => {
    //     bounds.extend([pin.lng, pin.lat]);
    //   });

    //   this.map.fitBounds(bounds, {
    //     padding: { top: 40, right: 40, bottom: 40, left: 525 },
    //     duration: 0
    //   });
    // },

    // Polylines
    clearPolylines() {
      for (let i = 0; i < this.displayedPolylines.length; i++) {
        const polyline = this.displayedPolylines[i];
        this.map.removeLayer(polyline);
        this.map.removeSource(polyline);
      }

      this.waypoints = [];
      this.displayedPolylines = [];
    },

    updatePolylines() {
      this.clearPins('route');
      this.clearPolylines();

      if (!this.polylines.length) return;

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        this.polylines[0].coordinates[0],
        this.polylines[0].coordinates[0]
      );

      for (let i = 0; i < this.polylines.length; i++) {
        const geoObj = this.polylines[i];
        const { id, coordinates, start, end } = geoObj;
        if (this.displayedPolylines.indexOf(id) < 0) {
          this.map.addSource(id, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: geoObj
            }
          });

          this.map.addLayer({
            id,
            type: 'line',
            source: id,
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': '#1F2B60',
              'line-width': 7
            }
          });

          this.displayedPolylines.push(id);
          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of coordinates) {
            bounds.extend(coord);
          }
        }

        if (start) {
          this.waypoints.push({
            lat: coordinates[0][1],
            lng: coordinates[0][0],
          });
          // this.createRoutePin(coordinates[0]);
        }

        if (end) {
          this.waypoints.push({
            lat: coordinates[coordinates.length - 1][1],
            lng: coordinates[coordinates.length - 1][0],
          });
          // this.createRoutePin(coordinates[coordinates.length - 1]);
        }
        this.setPins('route');
      }

      this.map.fitBounds(bounds, {
        padding: { top: 40, right: 40, bottom: 40, left: 525 }
      });
    },

    // Helpers
    createMarker(pin, type = 'center') {
      const el = document.createElement('div');
      // el.className = 'marker';

      switch (type) {
        case 'tw':
          el.classList.add('marker', 'marker-tw');
          el.style.backgroundImage = `url("${PinTW}")`;
          break;
        case 'tc':
          el.classList.add('marker', 'marker-tc');
          el.style.backgroundImage = `url("${PinTC}")`;
          // el.innerHTML = `<img class="marker-icon" src="${ParkingIcon}"/>`;
          break;
        case 'electric':
          el.classList.add('marker', 'marker-electric');
          el.style.backgroundImage = `url("${PinElectric}")`;
          // el.innerHTML = `<img class="marker-icon" src="${ParkingIcon}"/>`;
          break;
        case 'route':
          el.className = 'pin-green';
          el.style.backgroundImage = `url("${PinGreen}")`;
          break;
        case 'center':
          el.className = 'pin';
          el.style.backgroundImage = `url("${PinPlace}")`;
          break;
        default:
          if (pin.isBookable === false) {
            el.classList.add('marker', 'marker-small');
            el.style.backgroundImage = `url("${PinSmall}")`;
            el.innerHTML = `<img class="marker-icon" src="${ParkingIcon}"/>`;
            return el;
          }

          el.classList.add('marker', 'marker-big');
          el.style.backgroundImage = `url("${PinBig}")`;
          el.innerHTML = `
              <img class="marker-icon" src="${ParkingIcon}"/>
              <span class="marker-content">
                <span class="marker-from">${this.$t('globals.from')}</span>
                <span class="marker-price">${pin.fromPrice || pin.bestPrice || pin.minimalPrice || 0}€</span>
                ${pin.securityLevel && pin.securityLevel.id !== 6 ? '<span class="marker-security">' + this.translations[`${pin.securityLevel.translationKey}.name`] + '</span>': ''}
              </span>
          `;
          break;
      }

      return el;
    },

    // Map events
    onMouseDown() {
      this.mouseDown = true;
    },
    onMouseUp() {
      setTimeout(() => {
        this.mouseDown = false;
      }, 400);
    },
    onZoomEnd() {
      this.mouseDown = true;
      this.onMapEvents();
      this.mouseDown = false;
    },
    onMapEvents() {
      if (!this.mouseDown) return;
      const center = this.map.getCenter();
      const bounds = this.map.getBounds();
      const zoom = this.map.getZoom();

      const { _ne, _sw } = bounds;
      // Measure dist between center and a corner
      const maxDist = Math.round(measureDistance(_ne,_sw,) / 2);

      this.$store.commit('map/update', { property: 'centerMarker', value: false });
      this.$store.commit('map/update', { property: 'center', value: { lat: center[1], lng: center[0], zoom } });
      //this.removeCenterMarker();

      this.$emit('onDragend', { bounds, ...center, dist: maxDist, zoom });
    },
    // Settings
    updateLang() {
      if (!this.map) return;
      const lang = mapLanguages[this.lang] || 'name_en';

      const props = [
        'country-label',
        'state-label',
        'settlement-major-label',
        'settlement-minor-label',
        'settlement-subdivision-label',
        'airport-label',
        'poi-label',
        'water-point-label',
        'water-line-label',
        'road-label-simple'
      ];

      for (let i = 0; i < props.length; i++) {
        this.map.setLayoutProperty(props[i], 'text-field', ['get', lang]);
      }

      this.clearPins();
      this.setPins();
    },
  }
}
</script>

<style lang="scss" scoped>
#step-map {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  flex-shrink: 0;
  position: relative;
}
</style>