<template>
  <div class="range">
    <!-- Min / Max -->
    <span class="range-label min">{{ min }} {{ $t('components.filters.km') }}</span>
    <span class="range-label max">{{ max }} {{ $t('components.filters.km') }}</span>
    <input
      class="range-input"
      type="range"
      :value="value"
      :min="min"
      :max="max"
      @change="onChange"
      @input="onInput"
    >
    <!-- Current Label -->
    <div
      ref="currentContainer"
      class="range-curent"
    >
      <span
        ref="current"
        class="range-label"
        :style="{ 'left': currentPosLeft }"
      >
      {{ value }} {{ $t('components.filters.km') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    currentPosLeft() {
      if(!this.isMounted) return 0;
      const { current, currentContainer } = this.$refs;
      if (!current || !currentContainer) return '0';
      const filter = this.$store.state.parkings.filters.filter(f => f.id === 'range');
      if (!filter.length) return 0;

      const rectContainer = currentContainer.getBoundingClientRect();
      const rect = current.getBoundingClientRect();
      const range = filter[0];
      const diff = range.max - range.min;
      const leftPercentage = (range.value - range.min) / diff * 100;
      const leftPx = leftPercentage / 100 * rectContainer.width;
      const limit = (rect.width / 2) - 13;
      const leftLimited = Math.max(Math.min(leftPx, (rectContainer.width - limit)), limit);
      return `${leftLimited}px`;
    }
  },
  methods: {
    onInput(e) {
      const { currentTarget } = e;
      const { value } = currentTarget;
      this.$emit('onInput', value);
    },
    onChange(e) {
      const { currentTarget } = e;
      const { value } = currentTarget;
      this.$emit('onChange', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.range {
  padding-top: 14px;
  display: block;
  width: 100%;
  position: relative;

  &-label {
    position: absolute;
    top: 0;
    color: $black;
    font-family: $secondary;
    font-size: rem(12px);
    line-height: 1;
    white-space: nowrap;

    &.min { left: 0; }
    &.max { right: 0; }
  }

  &-curent {
    position: relative;
    height: 16px;
    width: calc(100% - 26px);
    margin: 0 auto;

    .range-label {
      top: auto;
      bottom: 0;
      transform: translateX(-50%);
    }
  }

  &-input {
    display: block;
    width: 100%;
    height: 26px;
    appearance: none;
    overflow: hidden;
    cursor: pointer;

    // Thumb
    &::-moz-range-thumb {
      width: 26px;
      height: 26px;
      margin-top: -11px;
      border: 0;
      border-radius: 50%;
      background: radial-gradient($mediumgreen 0, $mediumgreen 10px, $white 10px, $white 100%);
      background: $mediumgreen;
      cursor: pointer;
      appearance: none;
    }

    &::-webkit-slider-thumb {
      width: 26px;
      height: 26px;
      margin-top: -12px;
      border: 0;
      border-radius: 50%;
      background: radial-gradient($mediumgreen 0, $mediumgreen 10px, $white 10px, $white 100%);
      box-shadow: makelongshadow($grey, -12px);
      cursor: pointer;
      appearance: none;
    }

    // Track
    // &::-moz-range-track,
    &::-webkit-slider-runnable-track {
      width: 100%;
      margin-top: 2px;
      height: 2px;
      cursor: pointer;
      background: $mediumgreen;
    }
  }
}
</style>