<template>
  <!-- <aside
    class="notifications"
  > -->
  <aside 
    v-if="notifications"
    class="modal"
    :class="{
      'modal--visible': isVisible,
      'has-link': notifications.link
    }"
  >
    <div class="modal-container">
      <h1 class="modal__title">{{ $t(`notifications.modal.title.${notifications.type}`) }}!</h1>
      <img
        v-if="notifications.img"
        :src="notifications.img"
        class="modal-img"
      />
      <Sprite
        v-else
        :type="'notification-'+notifications.type"
        :width="141"
        :height="140"
      />
      <span
        v-if="notifications.text"
        class="small modal__text"
        v-html="notifications.text"
      />
      <span
        v-if="notifications.translationKey"
        class="small modal__text"
        v-html="$t(notifications.translationKey)"
      />
      <div
        v-if="!notifications.signout"
        class="modal-actions"
      >
        <button
          type="button"
          class="button modal__button modal__close"
          :class="{
            'button-border': notifications.link
          }"
          @click="close(notifications)"
        >
          {{ $t('globals.ok_maj') }}
        </button>
        <a
          v-if="notifications.link"
          :href="notifications.link.href"
          :download="notifications.link.download"
          type="button"
          class="button modal__button"
        >
          {{ notifications.link.label }}
          <Sprite
            v-if="notifications.link.icon"
            :type="notifications.link.icon.type"
            :width="notifications.link.icon.width"
            :height="notifications.link.icon.height"
          />
        </a>
      </div>
      <button
        v-else
        type="button"
        class="button modal__button"
        @click="signOut"
      >
        {{ $t('globals.signout') }}
      </button>
    </div>
  </aside>
</template>

<script>
import { Auth } from 'aws-amplify';
import Sprite from '@/components/elements/Sprite.vue';

export default {
  components: {
    Sprite
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.items[0];
    },
    isVisible() {
      return this.$store.state.notifications.isVisible;
    },
  },
  methods: {
    close(item) {
      if (this.notifications.redirect) {
        this.$router.push(this.notifications.redirect);
      }

      this.$store.commit('notifications/remove', item);
    },
    async signOut() {
      const requestAuthentification = Auth.signOut()
      requestAuthentification.then(promise => {
        this.$store.commit('updateTokens', {});
        this.$store.commit('updateAuthState', null);
        this.$store.dispatch('user/setForm', {
          firstname: null,
          lastname: null,
          email: null,
          locale: null,
        });

        this.$router.push('/');
        this.$store.commit('notifications/reset');
        this.$store.commit('settings/updateContext', null);
        setTimeout(() => {
          window.location.reload();
        }, 50);
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.modal__title {
  font-family: $primary;
  font-size:rem(34px);
  font-weight:700;
  text-transform: capitalize;
  letter-spacing: 0;
  color: $darkblue;
  margin-bottom: 30px;
}
.modal__text {
  margin: 30px 0;
  color: $grey;
}
.modal__button {
  padding: 20px !important;
  width: 100% !important;
}
</style>
