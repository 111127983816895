import {
  getDrivers,
  addDriver,
  revokeDriver,
  getTrucks,
  updateActiveDriver,
  updatePendingDriver,
  checkDriverAccount,
  linkDriverAccount
} from '@/calls/drivers';

import i18n from '../i18n';

const drivers = {
  namespaced: true,
  state: {
    requests: 0,
    item: null,
    items: [],
    search: null,
    popups: {
      new: {
        // nickname: '',
        // again: false,
        visible: false,
        requests: 0,
        success: false,
        confirm: false,
        accountFound: false,
        form: {
          phone: '',
          locale: '',
          fullname: ''
        },
        errors: {
          phone: '',
          locale: '',
          fullname: ''
        }
      },
      update: {
        visible: false,
        requests: 0,
        success: false,
        errors: {
          lastName: '',
          firstName: ''
        }
      },
      truck: {
        visible: false,
        requests: 0,
        success: false,
        selected: null,
        options: []
      },
      revoke: {
        visible: false,
        requests: 0,
        success: false,
        warning: false
      }
    },
  },
  mutations: {
    updatePopupVisibility(state, { popup, visible }) {
      state.popups[popup].visible = visible;
    },
    updatePopupProperty(state, { popup, property, value }) {
      state.popups[popup][property] = value;
    },
    updatePopupForm(state, { popup, field, value }) {
      state.popups[popup].form[field] = value;
    },
    updatePopupError(state, { popup, field, value }) {
      state.popups[popup].errors[field] = value;
    },
    updateItemProperty(state, { property, value }) {
      state.item[property] = value;
    },
    updateItem(state, item) {
      state.item = item;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    updateOneItem(state, { index, item }) {
      state.items[index] = item;
    },
    updateItems(state, items) {
      state.items = items;
    },
    removeOneItem(state, index) {
      state.items.splice(index, 1);
    },
    resetItem(state) {
      state.item = {
        phone: ''
      }
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    }
  },
  actions: {
    async getDrivers({ commit, dispatch, state }) {
      commit('increaseRequests');
      const search = state.search && state.search.length ? state.search : null;
      let drivers = await getDrivers(search);

      if (drivers.error) {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting drivers : ${drivers.error}`,
          type: 'error'
        }], { root: true });

        commit('decreaseRequests');
        return;
      }

      drivers = drivers.map(d => {
        d.account_type = 'person';
        return d;
      });

      commit('updateItems', drivers);
      commit('decreaseRequests');
    },
    async checkDriver({ commit, dispatch, state, rootState }) {
      commit('updatePopupProperty', {
        popup: 'new', property: 'requests', value: state.popups.new.requests + 1
      });

      const { t } = i18n.global;
      const { phone } = state.popups.new.form;
      const request = await checkDriverAccount({ phoneNumber: phone });

      if (request.hasError) {
        const { error } = request;
        const { message, detail } = error;
        const err = detail ? detail : message;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.drivers.checking', { error: err ? err : error}),
          type: 'error'
        }], { root: true });
      } else {
        const { data } = request;
        if (data.linkable) {
          if (!data.driver) {
            // Show full form for creation
            commit('updatePopupProperty', { popup: 'new', property: 'confirm', value: true });
          } else {
            // Show confirmation to link the driver
            commit('updatePopupProperty', { popup: 'new', property: 'accountFound', value: data.driver });
          }
        } else {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.drivers.checking', { error: data.message }),
            type: 'error'
          }], { root: true });
        }
      }

      commit('updatePopupProperty', { popup: 'new', property: 'requests', value: state.popups.new.requests - 1 });
    },
    async addNew({ commit, state, dispatch }) {
      commit('updatePopupProperty', { popup: 'new', property: 'requests', value: state.popups.new.requests + 1 });

      // const fleetId = rootState.fleet.current.id;
      // let driver = await addDriver({ fleetId, phoneNumber: phone, fullName: phone });
      const { phone, fullname, locale } = state.popups.new.form;
      let driver = await addDriver({ phoneNumber: phone, fullName: fullname, locale });
      const { t } = i18n.global;

      if (driver.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.drivers.add', { error: driver.error.message ? driver.error.message : driver.error }),
          type: 'error'
        }], { root: true });
      } else {
        dispatch('getDrivers');
        commit('updatePopupProperty', { popup: 'new', property: 'success', value: true });
      }

      commit('updatePopupProperty', { popup: 'new', property: 'requests', value: state.popups.new.requests - 1 });
    },
    async linkDriver({ commit, state, dispatch }) {
      commit('updatePopupProperty', { popup: 'new', property: 'requests', value: state.popups.new.requests + 1 });
      
      const account = state.popups.new.accountFound;
      const driver = await linkDriverAccount({ driverId: account.id, updateFleet: false });
      const { t } = i18n.global;

      if (driver.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.drivers.add', { error: driver.error.message ? driver.error.message : driver.error }),
          type: 'error'
        }], { root: true });
      } else {
        dispatch('getDrivers');
        commit('updatePopupProperty', { popup: 'new', property: 'success', value: true });
      }

      commit('updatePopupProperty', { popup: 'new', property: 'requests', value: state.popups.new.requests - 1 });
    },
    async updatePending({ commit, state, dispatch }) {
      commit('updatePopupProperty', {
        popup: 'update',
        property: 'requests',
        value: state.popups.update.requests + 1
      });

      const { item } = state;
      const driver = await updatePendingDriver(item);

      if (driver.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while updating driver : ${driver.error}`,
          type: 'error'
        }], { root: true });
      } else {
        commit('updatePopupProperty', {
          popup: 'update',
          property: 'success',
          value: true
        });

        if (state.items && state.items.length) {
          for (let i = 0; i < state.items.length; i++) {
            if (state.items[i].id === item.id) {
              commit('updateOneItem', {
                index: i,
                item: { ...item }
              });
              break;
            }
          }
        }
      }

      commit('updatePopupProperty', {
        popup: 'update',
        property: 'requests',
        value: state.popups.update.requests - 1
      });
    },
    async updateActive({ commit, state, dispatch }) {
      commit('updatePopupProperty', {
        popup: 'truck', property: 'requests', value: state.popups.truck.requests + 1
      });

      const { item } = state;
      const truck = await updateActiveDriver(item.id, item.truckId);

      if (truck.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while updating driver : ${truck.error}`,
          type: 'error'
        }], { root: true });
      } else {
        dispatch('getDrivers');
        commit('updatePopupProperty', { popup: 'truck', property: 'success', value: true });
      }

      commit('updatePopupProperty', {
        popup: 'truck', property: 'requests', value: state.popups.truck.requests - 1
      });
    },
    async revokeDriver({ commit, state, dispatch }) {
      commit('updatePopupProperty', { popup: 'revoke', property: 'requests', value: state.popups.revoke.requests + 1 });

      const { item } = state;
      const driver = await revokeDriver(item);

      if (driver.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.drivers.revoke', { error: driver.error.message ? driver.error.message : driver.error }),
          // text: `Error while removing driver : ${driver.error}`,
          type: 'error'
        }], { root: true });
      } else {
        if (driver) {
          commit('updatePopupProperty', { popup: 'revoke', property: 'success', value: true });
          dispatch('getDrivers');
        }
      }

      commit('updatePopupProperty', { popup: 'revoke', property: 'requests', value: state.popups.revoke.requests - 1 });
    },
    async getTrucks({ commit, state, dispatch }) {
      commit('updatePopupProperty', {
        popup: 'truck', property: 'requests', value: state.popups.truck.requests + 1
      });

      const trucks = await getTrucks();

      if (trucks.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting trucks : ${trucks.error}`,
          type: 'error'
        }], { root: true });
      } else {
        const filteredTrucks = trucks.filter(truck => truck.label && truck.label.trim().length);

        commit('updatePopupProperty', {
          popup: 'truck',
          property: 'options',
          value: filteredTrucks
        });
      }

      commit('updatePopupProperty', {
        popup: 'truck',
        property: 'requests',
        value: state.popups.truck.requests - 1
      });
    }
  }
};

export default drivers;
