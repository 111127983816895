<template>
    <!-- <Layout /> -->    
    <SnapLayout v-if="$route.meta.layout === 'snap-layout'" />
    <router-view v-else />

    <!-- <router-view v-else-if="$route.meta.layout === 'none'" /> -->
    <!-- <Layout v-else-if="authState === 'signedin' && user && $route.meta.layout !== 'no-layout' "/>
    <LayoutPageNotLogged v-else-if="$route.meta.layout === 'page' || $route.meta.layout === 'no-layout'"/>
    
    
    <Authentication v-else /> -->
</template>

<script>
import axios from 'axios';
// import { Auth } from 'aws-amplify';
// import Layout from '@/views/Layout.vue';
// import LayoutPageNotLogged from '@/views/Layout-page-not-logged.vue';
// import Authentication from '@/views/Authentication.vue';
import SnapLayout from '@/views/SnapLayout.vue';
import { redirectToSnap } from '@/helpers/redirectHelper';

export default {
  name: 'AuthStateApp',
  components: {
    // Layout,
    // LayoutPageNotLogged,
    // Authentication,
    SnapLayout
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const lowerCaseParams = new URLSearchParams();

    urlParams.forEach((value, key) => {
      lowerCaseParams.append(key.toLowerCase(), value.toLowerCase());
    });
    const hasClientId = lowerCaseParams.get('clientid');
    const hasClientSecret = lowerCaseParams.get('clientsecret');
    if(window.location.pathname === '/snaperror' || (hasClientId && hasClientSecret)){
      let locale = "en";
      const lang = urlParams.get('language');

      if (lang) {
        locale = lang.toLowerCase();
      }
      this.$i18n.locale = locale;
    } else{
      let locale = window.localStorage.getItem('otra-locale');    

      if (locale && locale !== 'undefined' && locale !== this.$i18n.locale) {
        this.$i18n.locale = locale;
        axios.defaults.headers['Accept-Language'] = locale;
      }
    }
  },
  computed: {
    authState() {
      return this.$store.state.authentication.authState;
    },
    user() {
      return this.$store.state.authentication.tokens;
    }
  },
  data() {
    return {
      authorization: "otra-fleet"
    } 
  },
  beforeMount() {
    if (window.location.pathname !== '/snaperror' && window.location.pathname !== '/snap_booking'
    && window.location.pathname !== '/snap_listbooking'){      
      redirectToSnap();  
      return;
    }

    // this.$store.dispatch('authCheck');
  }
}
</script>

<style lang="scss">
@import '@/scss/app.scss';
</style>
