<template>
  <thead>
    <tr>
      <th 
        v-for="h in head" 
        :class="h.class" 
        :key="h.title"
        @click="h.sortable ? emitSort(h) : null" 
        style="cursor: pointer;"
      >
        <!-- Column title -->
        <span v-html="h.title"></span>

        <!-- Sorting arrows if the column is sortable -->
        <span v-if="h.sortable">
          <!-- Show up arrow if sorting by this column in ascending order -->
          <span v-if="filters.sortColumn === h.key && filters.sortDirection === 'asc'">↑</span>

          <!-- Show down arrow if sorting by this column in descending order -->
          <span v-if="filters.sortColumn === h.key && filters.sortDirection === 'desc'">↓</span>
        </span>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  props: {
    head: Array,
    filters: Object // Pass filters from parent component to manage sorting state
  },
  methods: {
    emitSort(header) {
      // Toggle sort direction between 'asc' and 'desc'
      const newDirection = this.filters.sortColumn === header.key && this.filters.sortDirection === 'asc'
        ? 'desc'
        : 'asc';
      
      // Emit sort event to parent with key and direction
      this.$emit('sort', { key: header.key, direction: newDirection });
    }
  }
}
</script>
