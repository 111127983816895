<template>
  <div class="step-stay">
    <div ref="steps" class="steps">
      <div
        v-for="(step, index) in steps"
        :key="`step-${index}`"
        :class="`step-stay-step${currentStep === index ? ' is-open' : ''}`"
        :ref="`step-${index}`"
      >
        <CollapsingStep
          :index="index + 1"
          :value="step.value"
          :title="step.title"
          :open="currentStep === index"
          :checked="index < currentStep"
          :isDisabled="index > currentStep || index === 2"
          :loading="step.loading"
          @onOpen="onOpenStep(index)"
          @onToggle="onToggleStep(index)"
        >
          <!-- Slot type -->
          <div v-if="index === 0" class="step-stay-slottypes">
            <button
              v-for="slotType in slotTypes"
              :class="`button-slot ${
                slotType.id === Number(form.slot) ? 'selected' : null
              }`"
              :key="slotType.id"
              @click.prevent.stop="onTypeChange(slotType)"
            >
              {{ $t(`components.slot_type.${slotType.icon}`) }}
            </button>
          </div>

          <!-- Packages -->
          <div v-else-if="index === 1" class="step-stay-packages">
            <div class="step-stay-packages-list">
              <SlotThumb
                v-for="(slot, duration) in packages"
                :key="duration"
                :price="slot"
                :time="duration"
                :selected="form.duration === duration"
                :selectItem="true"
                @click="onPackagesChange(duration)"
              />
            </div>
          </div>

          <!-- Date -->
          <div v-else-if="index === 2" class="step-stay-date">
            <InputCalendar
              @change="onDateChange"
              :value="currentDate"
              :allowedDates="this.allowedDates"
            />
          </div>

          <!-- Time -->
          <div
            v-else-if="index === 3"
            class="step-stay-slots"
          >
            <table
              v-for="(booking, index) in this.daysPeriods"
              :key="index"
              cellspacing="0"
              class="table table-booknow"
            >
              <tbody>
                <tr :key="index" v-for="(el, index) in booking.list">
                  <td class="nickname">{{ setTime(el) }}</td>
                  <td
                    class="availability"
                    :class="{full: el.availability === 0}"
                  >
                    {{ el.availability > 0 ? el.availability + ` ${$t('steps.slot.availability.slots', { count: el.availability })}` : $t('steps.slot.availability.full') }}
                  </td>
                  <td class="action">
                    <button
                      type="button"
                      class="button"
                      :disabled="el.availability === 0"
                      @click="onTimeChange(el, booking)"
                    >
                      {{ $t('steps.slot.availability.select') }}
                    </button>
                      <!-- @click="() => onBooking(el.startAt, booking.price, booking.extension, booking.id)"> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CollapsingStep>
        <span
          v-if="index < steps.length - 1"
          :class="`dashed-line${index < currentStep ? ' green' : ''}`"
        />
      </div>
    </div>
    <div class="step-stay-buttons">
      <button
        class="button button-big button-ghost"  
        @click.prevent.stop="onBack"      
      >
        {{ $t("globals.back") }}
      </button>
      <button
        class="button button-big"
        :disabled="disableNext"
        @click.prevent.stop="onBooking"
      >
        {{ $t("globals.next") }}
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Loader from "@/components/elements/Loader";
import SlotThumb from "@/components/modules/Search/SlotThumb";
import CollapsingStep from "@/components/modules/CollapsingStep";
import DateCalendar from "@/components/partials/Header/DateCalendar";
import InputCalendar from "@/components/elements/InputCalendar";
import { formatDate } from "@/helpers/helpers";
import moment from "moment";
import { fetchBookablePeriods } from "@/calls/apiSnapIntegration";
import { redirectToSnapFleetManage } from "@/helpers/redirectHelper";

export default {
  name: "StepStay",
  components: {
    SlotThumb,
    Loader,
    InputCalendar,
    CollapsingStep,
    DateCalendar,
  },
  props: {
    datas: {
      type: Object,
    },
  },
  data() {
    return {
      days: [],
      currentStep: 0,
      allowedDates: [],
      periods: [],
      daysPeriods: {},      
    };
  },
  beforeMount() {
    this.parking = this.datas;
      
    const goToStep = window.localStorage.getItem("stepstay-begining");
    if (goToStep) {      
      const step = Number(goToStep);
      this.currentStep = Number(step);
      window.localStorage.removeItem("stepstay-begining");
      
      if (step === 2) {
        this.days = [];
                 
        setTimeout(() => {
          this.fillAllowedDates(); 
        }, 1000);      
          
        //this.getBookings();
      }
    }
    else{
      if (this.form?.slot){
        this.$store.commit("siParkings/updateForm", {
        value: null,
        property: "slot",
      });
      }
    }
  },
  computed: {
    form() {
      const { form } = this.$store.state.siParkings;
      return form;
    },
    currentDate() {
      return this.form.date ? this.form.date.replace(/-/g, "") : null;
    },
    parkingId() {
      return this.form.parking;
      // return this.$store.state.parkings.details.parking.id;
    },
    slotTypes() {
      const p = this.$store.state.siParkings.details.parking;
      if (!p?.parkingSlots)
        return [];
      return p.parkingSlots
        .filter((s) => s.otraSlotNumber !== 0)
        .map((s) => s.slot)
        .sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
    },
    packages() {
      if (!this.form.slot) return null;
      const packages = {};
      const p = this.$store.state.siParkings.details.parking;
      p.packages.map((pack) => {
        if (packages[pack.duration]) packages[pack.duration].push(pack);
        else packages[pack.duration] = [pack];
      });

      for (const packs in packages) {
        if (Object.hasOwnProperty.call(packages, packs)) {
          packages[packs] = packages[packs]
            .filter((p) => p.slotId === Number(this.form.slot))
            .map((p) => p.price)[0];
        }
       
        if (Object.hasOwnProperty.call(packages, packs) && !packages[packs] && packages[packs] !== 0) {
          delete packages[packs];
        }
      }

      return packages;
    },
    disableNext() {
      return (
        (!this.form.slot && this.form.slot !== 0) ||
        !this.form.duration ||
        !this.form.date ||
        !this.form.startAt
      );
    },
    displayedDay() {
      const { date } = this.$store.state.siParkings.form;
      if (!date) return [];
      const formatedDate = moment(date).format("YYYYMMDD");
      return this.days.filter((d) => d.date === formatedDate);
    },
    displayPeriod() {
      return this.periods;
    },
    steps() {
      let selectSlotType = this.slotTypes.filter(
        (s) => s.id === Number(this.form.slot)
      );
      selectSlotType =
        selectSlotType.length > 0
          ? this.$t(`components.slot_type.${selectSlotType[0].icon}`)
          : null;

      const steps = [
        {
          title: this.$t("steps.slot.labels.freight_type"),
          value: selectSlotType,
          loading: this.$store.state.siParkings.requests.details !== 0,
        },
        {
          title: this.$t("steps.slot.labels.duration"),
          value: this.form.duration ? `${this.form.duration}h stay` : null,
          loading: false,
        },
        {
          title: this.$t("steps.slot.labels.arrival_date"),
          value: this.form.date
            ? moment(this.form.date).format("ddd D MMM YYYY")
            : null,
          loading: this.$store.state.siParkings.requests.calendar !== 0,
        },
        {
          title: this.$t("steps.slot.labels.arrival_time"),
          value: this.form.startAt
            ? this.formatTime(this.form.startAt)
            : null,
          loading: !this.$store.state.siParkings.form.loadPeriods,
        },
      ];

      return steps;
    },
  },
  methods: {
  //   processSlots(parkingSlots) {
  //   this.slotTypes = parkingSlots
  //     .filter((s) => s.otraSlotNumber !== 0)
  //     .map((s) => s.slot)
  //     .sort((a, b) => a.id - b.id);
  // },
    formatTime(time) {       
      const d = formatDate(
        time,
        this.$store.state.siParkings.details.parking.timeZoneId,
        null,
        "HH a"
      );
      return d.hoursDigits + " " + this.$t(`globals.${d.hoursAmPm}`);
    },
    setTime(booking) {
       
      const d = formatDate(
        booking.startAt,
        this.$store.state.siParkings.details.parking.timeZoneId,
        null,
        "HH a"
      );
      return d.hoursDigits + " " + this.$t(`globals.${d.hoursAmPm}`);
    },
    onTypeChange(slotType) {
      this.$store.commit("siParkings/updateForm", {
        value: slotType.id,
        property: "slot",
      });
      this.$store.commit("siParkings/updateForm", {
        value: null,
        property: "duration",
      });
      this.days = [];
      this.currentStep = 1;
      // this.$refs.steps.scrollTop = 88;
    },
    onPackagesChange(duration) {
      this.$store.commit("siParkings/updateForm", {
        value: duration,
        property: "duration",
      });
      this.days = [];
      this.currentStep = 2;
      let formattedDate = this.$store.state.siParkings?.requests?.date?.replace(/-/g, '');
      this.onDateChange(formattedDate)
      // this.$refs.steps.scrollTop = 88 * 2;
    },
    onDateChange(date) {
      this.$store.commit("siParkings/updateForm", {
        value: `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`,
        property: "date",
      });
      this.currentStep = 3;
      // this.$refs.steps.scrollTop = 88 * 3;
    },
    onTimeChange(el, booking) {
      this.$store.commit("siParkings/updateForm", {
        value: el.startAt,
        property: "startAt",
      });
      this.$store.commit("siParkings/updateForm", {
        value: booking.price,
        property: "price",
      });
      this.$store.commit("siParkings/updateForm", {
        value: booking.extension,
        property: "extension",
      });
      this.$store.commit("siParkings/updateForm", {
        value: booking.id,
        property: "package",
      });

      this.currentStep = 4;
    },
    onOpenStep(index) {
      if (index === 2) {
        this.fillAllowedDates();
      } else if (index === 3) {
        this.getBookingPerDate();
      }

      return;
    },
    onBooking() {
      this.$emit("newStep", {
        // step: "StepDetails",
        step: "StepReview",
        datas: this.datas,
      });
    },
    onToggleStep(index) {
      if (index <= 0)
        this.$store.commit("siParkings/updateForm", {
          value: null,
          property: "slot",
        });
      if (index <= 1)
        this.$store.commit("siParkings/updateForm", {
          value: null,
          property: "duration",
        });
      if (index <= 2)
        this.$store.commit("siParkings/updateForm", {
          value: null,
          property: "date",
        });
      if (index <= 3)
        this.$store.commit("siParkings/updateForm", {
          value: null,
          property: "startAt",
        });

      this.$store.commit("siParkings/updateForm", {
          value: false,
          property: "loadPeriods",
        });
      if(index ===3 && this.currentStep === 3){
        this.currentStep = 4;
      }else{
        this.currentStep = index;
      }
      
    },
    async getBookings() {
      this.$store.commit("siParkings/increaseRequests", "calendar");

      const request = await parkingsAvailableBookings(this.parkingId, {
        Slot: Number(this.form.slot),
        Duration: Number(this.form.duration),
      });

      if (!request) {
        this.$store.commit("siParkings/decreaseRequests", "calendar");
        return this.$t("steps.slot.no_result");
      }

      const days = {};
      request.periods.forEach((period) => {
        const day = moment(period.startAt).format("YYYYMMDD");
        if (day in days) days[day].list.push(period);
        else {
          days[day] = {
            id: request.package?.id,
            date: day,
            list: [period],
            price: request.package?.price,
            extension: request.package?.extensionPrice,
          };
        }
      });

      this.days = Object.values(days);
      this.$store.commit("siParkings/decreaseRequests", "calendar");
    },
    async getBookingPerDate() {
      const p = this.$store.state.siParkings.details.parking;
      const request = await fetchBookablePeriods(p.id, {
        Slot: Number(this.form.slot),
        Duration: Number(this.form.duration),
        Date: moment(this.form.date).format("YYYYMMDD"),
      });
   
      if (request.hasError){
       
        this.$store.dispatch('notifications/addNotifs', [{
          text: request?.error?.response?.data?.detail ? request?.error?.response?.data?.detail : request.error,
          type: 'error'
        }], { root: true });

        this.onToggleStep(2)
        return;
      }
    


    this.daysPeriods = {};

      request.periods.forEach((period) => {
        const day = moment(this.form.date).format("YYYYMMDD");
        if (day in this.daysPeriods) {
          this.daysPeriods[day].list.push(period);
        } else {
          this.daysPeriods[day] = {
            id: request.package?.id,
            date: day,
            list: [period],
            price: request.package?.price,
            extension: request.package?.extensionPrice,
          };
        }
      });

      if (this.daysPeriods != null) {
        this.$store.commit("siParkings/updateForm", {
          value: true,
          property: "loadPeriods",
        });
      }

    },
    fillAllowedDates() {      
      // list of today + 10 days to be enabled date (otherwhise will be disabled)
      let numberOfAllowed =
        this.$store.state.siParkings?.details
          ?.numberOfDaysBookingIsPossibleStartingToday ?? 10;
      
      this.allowedDates = [];
      if (this.allowedDates.length < 1) {
        for (let i = 0; i <= numberOfAllowed; i++) {
          //generate date and adding days
          const date = new Date();
          date.setDate(date.getDate() + i);

          const formatedDate = moment(date).format("YYYYMMDD");

          this.allowedDates.push(formatedDate);
        }
      }
     
    },
    onBack() {
      redirectToSnapFleetManage();
    },
  },
};
</script>

<style lang="scss" scoped>
.step-stay {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;

  .step-stay-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      min-width: 150px;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
    overflow: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .step-stay-step {
    /* flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: hidden; */
  }

  .collapsing-step {
    flex-shrink: 0;
    width: 100%;

    &.is-open {
      flex-shrink: 1;
    }
  }

  .dashed-line {
    width: 0;
    height: 18px;
    margin-left: 38px;
    border-left: 1px dashed rgba($grey, 0.8);

    &.green {
      border-left-color: rgba($mediumgreen, 0.8);
    }
  }
}

.step-stay-slottypes {
  display: flex;

  .button-slot {
    margin-right: 16px;
    padding: 12px 28px;
    border-radius: 30px;
    background-color: $lightestgrey;
    color: $darkblue;
    font-size: 16px;
    font-family: $secondary;
    font-weight: bold;
    line-height: 1;
    cursor: pointer;

    &.selected {
      background-color: $mediumgreen;
    }
  }
}

.step-stay-packages {
  &-list {
    display: flex;
    flex-wrap: wrap;

    .slot {
      margin-right: 0;
      margin-left: 24px;
      box-shadow: none;

      &:nth-child(5n + 1) {
        margin-left: 0;
      }
    }
  }
}

.step-stay-slots {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
  }

  .table-booknow {
    tr {
      &:first-child {
        td {
          padding-top: 0;
          border-top: 0;
        }
      }

      &:last-child {
        td {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }

    td {
      padding-top: 12px;
      padding-bottom: 12px;

      &:first-child {
        padding-left: 50px;
      }

      &:last-child {
        padding-right: 50px;
      }

      &.availability {
        @extend %p;
        color: $success;
        text-transform: uppercase;
        &.full {
          color: $error;
        }
      }

      &.action {
        text-align: right;
      }
    }
  }
}

.step-stay-loader {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
}
</style>