// import i18n from '../i18n';
// import { getTranslation } from '../calls/settings';
import { searchRoute } from '@/calls/here';
import { decode } from "@/helpers/polyline";
import { urlEncode } from "@/helpers/helpers";


const routePlanner = {
  namespaced: true,
  state: {
    mode: 'search', // planner
    waypoints: [
      { label: null, data: null, id: 1 },
      { label: null, data: null, id: 2 }
    ],
    pins: [],
    params: {
      routingMode: 'fast', // short
      vehicle: {
        height: null,
        length: null,
        width: null,
        tunnelCategory: null, // B, C, D, E
        axlesCount: null,
        trailerCount: null,
        shippedHazardousGoods: null,
        grossWeight: null,
        weightPerAxle: null,
        weightPerAxleGroup: null
      }
    },
    polylines: []
  },
  mutations: {
    updateMode(state, mode) {
      state.mode = mode;
    },
    updatePins(state, pins) {
      state.pins = pins;
    },
    updateWaypoints(state, waypoints) {
      state.waypoints = waypoints;
    },
    updateWaypointLabel(state, { index, value }) {
      state.waypoints[index].label = value;
    },
    updateWaypointData(state, { index, value }) {
      state.waypoints[index].data = value;
    },
    replaceWaypoint(state, { index, value }) {
      state.waypoints[index] = value;
    },
    addWaypoint(state, params) {
      if (!params || (!params.index && params.index !== 0)) {
        state.waypoints.push({ label: null, data: null, id: state.waypoints.length });
        return;
      }

      state.waypoints.splice(params.index, 0, {...params.value});
    },
    removeWaypoint(state, index) {
      if (state.waypoints.length === 2) {
        state.waypoints.splice(index, 1, {
          label: null, data: null, id: Math.random()
        });
        return;
      }

      state.waypoints.splice(index, 1);
    },
    updateWaypointsOrder(state, { replaceTo, replaceBy }) {
      const ids = state.waypoints.map(w => w.id);
      const indexTo = ids.indexOf(replaceTo);
      const indexBy = ids.indexOf(replaceBy);
      const el = state.waypoints.splice(indexBy, 1)[0];
      state.waypoints.splice(indexTo, 0, el);
    },
    updateParams(state, { property, value }) {
      if (!property) {
        state.params = value;
        return;
      }

      state.params[property] = value;
    },
    cleanPolylines(state) {
      state.polylines = [];
    },
    addPolyline(state, value) {
      state.polylines = [
        ...state.polylines,
        value
      ];
    }
  },
  actions: {
    async search({ state, commit }) {
      const { waypoints, params } = state;
      const points = waypoints.filter(w => w.data);

      if (points.length < 2) return;

      let parameters = '';

      for (let i = 0; i < points.length; i++) {
        const point = points[i];
        switch (i) {
          case 0:
            parameters += `origin=${point.data.center[1]},${point.data.center[0]}`
            break;
          case points.length - 1:
            parameters += `&destination=${point.data.center[1]},${point.data.center[0]}`
            break;
          default:
            parameters += `&via=${point.data.center[1]},${point.data.center[0]}`;
            break;
        }
      }

      const routeParams = urlEncode(params);
      if (routeParams && routeParams.length) parameters += `&${routeParams}`;

      const request = await searchRoute(parameters);

      if (!request.hasError) {
        commit('cleanPolylines');
        const { data } = request;
        const { routes } = data;

        if (routes.length) {
          const route = routes[0];
          const { sections } = route;

          for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            const decoded = decode(section.polyline);
            const line = decoded.polyline.map(coords => [coords[1], coords[0]]);
            const geoJson = {
              type: 'LineString',
              id: section.id,
              start: i === 1,
              end: i === sections.length - 1,
              coordinates: line
            };

            commit('addPolyline', geoJson);
          }
        }

        commit('updatePins', [
          points[0].data.center,
          points[points.length - 1].data.center,
        ]);
      }
    }
  }
};

export default routePlanner;
